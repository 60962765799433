import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './route'
import SignIn from 'pages/authentication/sign-in'
import SignUp from 'pages/authentication/sign-up'
import PrivacyPolicies from 'pages/authentication/privacy-policies'
import TermsOfUse from 'pages/authentication/terms-of-use'
import Plans from 'pages/authentication/plans'
import Home from 'pages/authentication/home'
import Profile from 'pages/authentication/profile'
import ForgotPassword from 'pages/authentication/forgot-password'
import ResetPassword from 'pages/authentication/reset-password'
import BlockReasonList from 'pages/security/block-reason/list'
import BlockReasonForm from 'pages/security/block-reason/form'
import UserGroupList from 'pages/security/user-group/list'
import UserGroupForm from 'pages/security/user-group/form'
import UserList from 'pages/security/user/list'
import UserForm from 'pages/security/user/form'
import ModuleList from 'pages/security/module/list'
import ModuleForm from 'pages/security/module/form'
import MenuOptionList from 'pages/security/menu-option/list'
import MenuOptionForm from 'pages/security/menu-option/form'
import ProfileList from 'pages/security/profile/list'
import ProfileForm from 'pages/security/profile/form'
import ProfileOptionList from 'pages/security/profile-option/list'
import ProfileOptionForm from 'pages/security/profile-option/form'
import UserProfileList from 'pages/security/user-profile/list'
import UserProfileForm from 'pages/security/user-profile/form'
import NavigationList from 'pages/security/navigation/list'
import NavigationForm from 'pages/security/navigation/form'
import StoreAutomationSoftwareList from 'pages/common/store-automation-software/list'
import StoreAutomationSoftwareForm from 'pages/common/store-automation-software/form'
import ProductSearchList from 'pages/automation/product-search/list'
import ProductSearchForm from 'pages/automation/product-search/form'
import FoundProductList from 'pages/automation/found-product/list'
import FoundProductForm from 'pages/automation/found-product/form'
import StoreList from 'pages/store/store/list'
import StoreForm from 'pages/store/store/form'
import CollectionList from 'pages/store/collection/list'
import CollectionForm from 'pages/store/collection/form'
import NewProductList from 'pages/store/new-product/list'
import NewProductForm from 'pages/store/new-product/form'
import CountryList from 'pages/payment/country/list'
import CountryForm from 'pages/payment/country/form'
import StateList from 'pages/payment/state/list'
import StateForm from 'pages/payment/state/form'
import ProductList from 'pages/payment/product/list'
import ProductForm from 'pages/payment/product/form'
import CommercialPlanList from 'pages/payment/commercial-plan/list'
import CommercialPlanForm from 'pages/payment/commercial-plan/form'
import SubscriptionList from 'pages/payment/subscription/list'
import SubscriptionForm from 'pages/payment/subscription/form'
import ShoppingCartList from 'pages/payment/shopping-cart/list'
import ShoppingCartForm from 'pages/payment/shopping-cart/form'
import BuyerEventList from 'pages/payment/buyer-event/list'
import BuyerEventForm from 'pages/payment/buyer-event/form'
import PlanChoicesList from 'pages/payment/plan-choices/list'
import PlanTransactionList from 'pages/payment/plan-transaction/list'
import PlanTransactionForm from 'pages/payment/plan-transaction/form'
import SupportTicketList from 'pages/support/support-ticket/list'
import SupportTicketForm from 'pages/support/support-ticket/form'
import NotificationList from 'pages/support/notification/list'
import NotificationForm from 'pages/support/notification/form'
import NotFound from 'pages/common/not-found'

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/privacy-policies" component={PrivacyPolicies} />
    <Route path="/terms-of-use" component={TermsOfUse} />
    <Route path="/plans" component={Plans} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password/:token" component={ResetPassword} />

    <Route exact path="/home" component={Home} isPrivate useDefaultLayout />
    <Route exact path="/profile" component={Profile} isPrivate useDefaultLayout />

    <Route exact path="/block-reasons" component={BlockReasonList} isPrivate useDefaultLayout />
    <Route exact path="/block-reasons/new" component={BlockReasonForm} isPrivate useDefaultLayout />
    <Route path="/block-reasons/edit/:id" component={BlockReasonForm} isPrivate useDefaultLayout />

    <Route exact path="/user-groups" component={UserGroupList} isPrivate useDefaultLayout />
    <Route exact path="/user-groups/new" component={UserGroupForm} isPrivate useDefaultLayout />
    <Route path="/user-groups/edit/:id" component={UserGroupForm} isPrivate useDefaultLayout />

    <Route exact path="/users" component={UserList} isPrivate useDefaultLayout />
    <Route exact path="/users/new" component={UserForm} isPrivate useDefaultLayout />
    <Route path="/users/edit/:id" component={UserForm} isPrivate useDefaultLayout />

    <Route exact path="/modules" component={ModuleList} isPrivate useDefaultLayout />
    <Route exact path="/modules/new" component={ModuleForm} isPrivate useDefaultLayout />
    <Route path="/modules/edit/:id" component={ModuleForm} isPrivate useDefaultLayout />

    <Route exact path="/menu-options" component={MenuOptionList} isPrivate useDefaultLayout />
    <Route exact path="/menu-options/new" component={MenuOptionForm} isPrivate useDefaultLayout />
    <Route path="/menu-options/edit/:id" component={MenuOptionForm} isPrivate useDefaultLayout />

    <Route exact path="/profiles" component={ProfileList} isPrivate useDefaultLayout />
    <Route exact path="/profiles/new" component={ProfileForm} isPrivate useDefaultLayout />
    <Route path="/profiles/edit/:id" component={ProfileForm} isPrivate useDefaultLayout />

    <Route exact path="/profile-options" component={ProfileOptionList} isPrivate useDefaultLayout />
    <Route exact path="/profile-options/new" component={ProfileOptionForm} isPrivate useDefaultLayout />
    <Route path="/profile-options/edit/:id" component={ProfileOptionForm} isPrivate useDefaultLayout />

    <Route exact path="/users-profiles" component={UserProfileList} isPrivate useDefaultLayout />
    <Route exact path="/users-profiles/new" component={UserProfileForm} isPrivate useDefaultLayout />
    <Route path="/users-profiles/edit/:id" component={UserProfileForm} isPrivate useDefaultLayout />

    <Route exact path="/navigations" component={NavigationList} isPrivate useDefaultLayout />
    <Route exact path="/navigations/new" component={NavigationForm} isPrivate useDefaultLayout />
    <Route path="/navigations/edit/:id" component={NavigationForm} isPrivate useDefaultLayout />

    <Route exact path="/store-automation-softwares" component={StoreAutomationSoftwareList} isPrivate useDefaultLayout />
    <Route exact path="/store-automation-softwares/new" component={StoreAutomationSoftwareForm} isPrivate useDefaultLayout />
    <Route path="/store-automation-softwares/edit/:id" component={StoreAutomationSoftwareForm} isPrivate useDefaultLayout />

    <Route exact path="/product-searchs" component={ProductSearchList} isPrivate useDefaultLayout />
    <Route exact path="/product-searchs/new" component={ProductSearchForm} isPrivate useDefaultLayout />
    <Route path="/product-searchs/edit/:id" component={ProductSearchForm} isPrivate useDefaultLayout />

    <Route exact path="/found-products" component={FoundProductList} isPrivate useDefaultLayout />
    <Route exact path="/found-products/new" component={FoundProductForm} isPrivate useDefaultLayout />
    <Route path="/found-products/edit/:id" component={FoundProductForm} isPrivate useDefaultLayout />

    <Route exact path="/stores" component={StoreList} isPrivate useDefaultLayout />
    <Route exact path="/stores/new" component={StoreForm} isPrivate useDefaultLayout />
    <Route path="/stores/edit/:id" component={StoreForm} isPrivate useDefaultLayout />

    <Route exact path="/collections" component={CollectionList} isPrivate useDefaultLayout />
    <Route exact path="/collections/new" component={CollectionForm} isPrivate useDefaultLayout />
    <Route path="/collections/edit/:id" component={CollectionForm} isPrivate useDefaultLayout />

    <Route exact path="/new-products" component={NewProductList} isPrivate useDefaultLayout />
    <Route exact path="/new-products/new" component={NewProductForm} isPrivate useDefaultLayout />
    <Route path="/new-products/edit/:id" component={NewProductForm} isPrivate useDefaultLayout />

    <Route exact path="/countries" component={CountryList} isPrivate useDefaultLayout />
    <Route exact path="/countries/new" component={CountryForm} isPrivate useDefaultLayout />
    <Route path="/countries/edit/:id" component={CountryForm} isPrivate useDefaultLayout />

    <Route exact path="/states" component={StateList} isPrivate useDefaultLayout />
    <Route exact path="/states/new" component={StateForm} isPrivate useDefaultLayout />
    <Route path="/states/edit/:id" component={StateForm} isPrivate useDefaultLayout />

    <Route exact path="/products" component={ProductList} isPrivate useDefaultLayout />
    <Route exact path="/products/new" component={ProductForm} isPrivate useDefaultLayout />
    <Route path="/products/edit/:id" component={ProductForm} isPrivate useDefaultLayout />

    <Route exact path="/plan-choices" component={PlanChoicesList} isPrivate useDefaultLayout />

    <Route exact path="/commercial-plans" component={CommercialPlanList} isPrivate useDefaultLayout />
    <Route exact path="/commercial-plans/new" component={CommercialPlanForm} isPrivate useDefaultLayout />
    <Route path="/commercial-plans/edit/:id" component={CommercialPlanForm} isPrivate useDefaultLayout />

    <Route exact path="/subscriptions" component={SubscriptionList} isPrivate useDefaultLayout />
    <Route exact path="/subscriptions/new" component={SubscriptionForm} isPrivate useDefaultLayout />
    <Route path="/subscriptions/edit/:id" component={SubscriptionForm} isPrivate useDefaultLayout />

    <Route exact path="/shopping-carts" component={ShoppingCartList} isPrivate useDefaultLayout />
    <Route exact path="/shopping-carts/new" component={ShoppingCartForm} isPrivate useDefaultLayout />
    <Route path="/shopping-carts/edit/:id" component={ShoppingCartForm} isPrivate useDefaultLayout />

    <Route exact path="/buyer-events" component={BuyerEventList} isPrivate useDefaultLayout />
    <Route exact path="/buyer-events/new" component={BuyerEventForm} isPrivate useDefaultLayout />
    <Route path="/buyer-events/edit/:id" component={BuyerEventForm} isPrivate useDefaultLayout />

    <Route exact path="/plan-transactions" component={PlanTransactionList} isPrivate useDefaultLayout />
    <Route exact path="/plan-transactions/new" component={PlanTransactionForm} isPrivate useDefaultLayout />
    <Route path="/plan-transactions/edit/:id" component={PlanTransactionForm} isPrivate useDefaultLayout />

    <Route exact path="/support-tickets" component={SupportTicketList} isPrivate useDefaultLayout />
    <Route exact path="/support-tickets/new" component={SupportTicketForm} isPrivate useDefaultLayout />
    <Route path="/support-tickets/edit/:id" component={SupportTicketForm} isPrivate useDefaultLayout />

    <Route exact path="/notifications" component={NotificationList} isPrivate useDefaultLayout />
    <Route exact path="/notifications/new" component={NotificationForm} isPrivate useDefaultLayout />
    <Route path="/notifications/edit/:id" component={NotificationForm} isPrivate useDefaultLayout />
    
    <Route path="*" component={NotFound} isPrivate useDefaultLayout />
  </Switch>
)

export default Routes
