import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { useStyles } from './styles'
import logoImage from 'assets/logo-main.svg'
import LoginFooter from 'components/login-footer'

const PrivacyPolicies: React.FC = () => {
  const history = useHistory()
	const classes = useStyles()

	return (
		<>
		<Box  className={classes.header}>
			<Box className={classes.headerPaperWrapper}>
				<a href="/home"onClick={() => history.push('/home')}><img src={logoImage} alt="logo" className={classes.logoImage} /></a>
			</Box>
		</Box>

		<Box className={classes.container}>
			<Box className={classes.mainWrapper}>
				<Box className={classes.paperWrapper}>
					<h1>Termos de Uso</h1>
					<h4>Em vigor a partir de 01/03/2024. Última alteração em 01/03/2024.</h4>
					<p>Estes Termos de Uso (“Termo”) regulam a utilização da Plataforma OneManDrop (“Software” ou “Plataforma”), criada e desenvolvida pela <strong>CLICKMVP SUPORTE TÉCNICO LTDA</strong> pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 37.236.037/0001-23, sediada na Avenida Brigadeiro Luis Antonio, 2367, Sala 911, Jardim Paulista, São Paulo - SP, CEP: 01401-900, doravante denominada “Licenciante”.</p>
					<p>A aceitação deste Termo é absolutamente indispensável à utilização do Software da Licenciante. Antes de proceder à assinatura do Software, você, doravante denominado “Cliente”, deverá ler, certificar-se de haver entendido e concordar com todas as condições estabelecidas no presente Termo.</p>
					<h3>1. CADASTRO E ACESSO AO SOFTWARE</h3>
					<p><strong>1.1.</strong> O Software será disponibilizado somente para os Clientes que tenham capacidade legal para contratar nos termos da Lei brasileira. Não poderão utilizar o Software os Clientes que não tenham esta capacidade, os menores de idade ou Clientes cujo cadastro esteja suspenso ou cancelado.</p>
					<p><strong>1.2</strong>. Para ser um Cliente cadastrado no Software, é necessário que se complete o cadastro em todos os seus campos com dados válidos, de maneira exata, precisa e verdadeira. O Cliente deverá fornecer documentos e informações que comprovem a sua identidade e capacidade de contratar, diretamente ou pelo representante legal em nome da respectiva pessoa jurídica, conforme lhe for solicitado para a realização do cadastro. Outros documentos e informações complementares poderão ser solicitados pela Licenciante. O Cliente assume o compromisso de manter as informações incluídas no cadastro atualizadas.</p>
					<p><strong>1.3.</strong> A documentação fornecida pelo Cliente no cadastro deverá ser atual, legal e vigente. A Licenciante não assume qualquer responsabilidade de fiscalização ativa dos Clientes, de forma que prejuízos decorrentes de informações falsas ou imprecisas serão de exclusiva responsabilidade do Cliente.</p>
					<p><strong>1.4.</strong> O acesso ao Software será concedido ao Cliente após a identificação do pagamento relativo ao plano contratado, sendo que os dados de acesso serão enviados pela Licenciante para o e-mail do Cliente informado no ato do cadastro, contendo uma senha que poderá ser posteriormente alterada pelo Cliente. Se o e-mail não for enviado com êxito imediatamente após a identificação do pagamento pelo Cliente, haverá outras 4 (quatro) tentativas de envio, conforme abaixo:</p>
					<p>a) Envio em 30 (trinta) minutos após a identificação do pagamento;</p>
					<p>b) Envio em 2 (duas) horas depois da primeira tentativa de envio;</p>
					<p>c) Envio em 4 (quatro) horas depois da segunda tentativa de envio;</p>
					<p>d) Envio em 24 (vinte e quatro) horas após a identificação do pagamento.</p>
					<p><strong>1.4.1.</strong> A Licenciante não solicitará sua senha por quaisquer outros meios, ou para quaisquer outros motivos, que não seja para o acesso ao Software.</p>
					<h3>2. USO DO SOFTWARE</h3>
					<p><strong>2.1.</strong> Mediante a conclusão de seu cadastro e adesão a este Termo, o Cliente poderá usar o Software para diferentes serviços e funcionalidades de acordo com o plano contratado, conforme previsto neste Termo.</p>
					<p><strong>2.2.</strong> Ao utilizar o Software, os Clientes receberão o direito de uso oneroso e não exclusivo das funcionalidades disponibilizadas pelo Software, para utilização limitada à contratação, bem como demais finalidades previstas neste Termo.</p>
					<p><strong>2.3.</strong> Para que os Clientes tenham acesso a novos serviços ou funcionalidades, deverão solicitar o upgrade do plano contratado, mediante seleção através do próprio Software e fornecimento das informações necessárias ou solicitadas pela Licenciante. Os aspectos relacionados à “upgrade” e “downgrade” de plano estão disciplinados na Cláusula 4 abaixo.</p>
					<p><strong>2.4.</strong> O Cliente será responsável por todo e qualquer acesso ao Software realizado através de seus respectivos dados de acesso, inclusive e-mail de cadastro e senha, responsabilizando-se por todas as perdas e danos eventualmente ocasionadas em decorrência de tais acessos.</p>
					<p><strong>2.5.</strong> É absolutamente proibido ao Cliente qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de interferir nas atividades e operações da Plataforma. Qualquer intromissão, atividade ou tentativa de violação que seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade intelectual, e/ou as proibições estipuladas neste Termo, serão passíveis da adoção das medidas legais pertinentes, inclusive de natureza criminal.</p>
					<h3>3. DESENVOLVIMENTO, CONEXÃO E MANUTENÇÃO DO SOFTWARE</h3>
					<p><strong>3.1.</strong> Cabe à Licenciante desenvolver e manter atualizado o Software, para que os Clientes possam ter acesso e usufruir dos serviços e funcionalidades oferecidos em razão do plano contratado. A Licenciante poderá interromper a disponibilidade do Software, a qualquer tempo, em paradas programadas, sem a necessidade de aviso prévio aos Clientes, esforçando-se, contudo, para que tais paradas sejam realizadas fora do horário comercial. A Licenciante não é obrigada a manter o Software disponível indefinidamente, não havendo qualquer tipo de responsabilidade perante os Clientes em caso de uma eventual indisponibilidade ou necessidade de manutenção não programada.</p>
					<p><strong>3.2.</strong> A fim de desfrutar dos serviços e funcionalidades oferecidos através do Software, o Cliente deve garantir uma conexão contínua e rápida com a Internet. O Cliente é o único responsável pela qualidade de sua conexão à Internet.</p>
					<p><strong>3.3.</strong> O Cliente reconhece que a qualidade da conexão da Internet depende, entre outros, do provedor de acesso à Internet e do provedor de infraestrutura de Internet, do volume de outros usos que o Cliente faz na banda disponível em cada momento e da qualidade do equipamento conector em sua posse, não possuindo a Licenciante qualquer ingerência ou responsabilidade quanto à conexão da Internet do Cliente.</p>
					<p><strong>3.4.</strong> A Licenciante não será responsável por qualquer vírus que possa infectar quaisquer equipamentos do Cliente como consequência do acesso, uso ou verificação do Software ou de qualquer transferência de dados, arquivos, imagens, textos, ou áudio contidos nestes. O Cliente não poderá imputar responsabilidade alguma para a Licenciante, nem exigir pagamento por danos materiais diretos, danos morais, danos indiretos ou lucros cessantes, em virtude de prejuízos resultantes da inobservância às disposições aqui contidas, decorrentes de dificuldades técnicas ou falhas no Software ou na Internet. A Licenciante não garante o acesso e uso continuado ou ininterrupto do Software. O Software pode eventualmente não estar disponível devido a dificuldades técnicas ou falhas de Internet, ou por qualquer outra circunstância alheia à vontade da Licenciante e, em tais casos, a Licenciante procurará restabelecer a acessibilidade ao Software com a maior brevidade possível, sem que isso possa imputar algum tipo de responsabilidade.</p>
					<p><strong>3.5.</strong> É absolutamente proibido aos Clientes qualquer ação ou uso de dispositivo, ou outro meio com o propósito de interferir nas atividades e operações do Software. Qualquer intromissão, atividade ou tentativa de violação que seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade intelectual, e/ou às proibições estipuladas neste Termo, serão passíveis das ações legais pertinentes pela Licenciante.</p>
					<p><strong>3.6.</strong> O suporte técnico da Licenciante ao Cliente, quanto ao Software, se dará de segunda-feira à sexta-feira, em horário comercial, preferencialmente por meio do chat online disponível em seu website (<a href="https://www.leadlovers.com ">leadlovers.com</a>).
						Alternativamente, o suporte técnico poderá ocorrer por meio do teleatendimento ou pelo e-mail constante também do website da Licenciante.</p>
					<p><strong>3.7.</strong> Tendo em vista a natureza do serviço prestado pela Licenciante, e a fim de sempre prestar o melhor atendimento ao Cliente, o atendimento e suporte ao Cliente se dará apenas através da plataforma ZenDesk.</p>
					<h3>4. PLANOS, UPGRADE E DOWNGRADE</h3>
					<p><strong>4.1.</strong> Ao contratar a utilização do Software, o Cliente deverá optar por um dos planos disponíveis no website da Licenciante, ocasião em que poderá verificar as características e diferenças entre os respectivos planos.</p>
					<p><strong>4.2.</strong> A melhoria de plano ("Upgrade ") só poderá ser solicitada pelo Cliente caso os pagamentos do plano atual estejam sendo feitos de forma regular, caso contrário, o Cliente terá que regularizar a sua condição antes de adquirir um plano com maior capacidade.</p>
					<p><strong>4.3.</strong> O "downgrade " do plano contratado poderá ser solicitado pelo Cliente a qualquer tempo, sendo efetivado na próxima recorrência de pagamento mensal. Até que a efetivação ocorra, o Cliente poderá usufruir dos benefícios do plano contratado anteriormente.</p>
					<p><strong>4.4.</strong> Os valores dos planos poderão ser corrigidos monetariamente, anualmente, a cada 12 (doze) meses, a contar da data de adesão do Cliente a este Termo e contratação da Plataforma, com base no IGP-M/FGV.</p>
					<h3>5. RENOVAÇÃO AUTOMÁTICA DA ASSINATURA</h3>
					<p><strong>5.1.</strong> A assinatura do software será renovada automaticamente, ao término do prazo de vigência do respectivo plano assinado, salvo mediante comunicação pelo cliente no prazo de até 48 (quarenta e oito) horas previamente à renovação do respectivo plano, podendo ser alterado este item mediante a negociações contraturais.</p>
					<p><strong>5.2.</strong> O cliente declara, desde já, estar de acordo com o método de renovação da assinatura do plano optado. Caso o cliente não se manifeste contrariamente à renovação, no prazo mencionado na cláusula 5.1 acima, o cliente deverá arcar com os valores relativos ao plano renovado caso não tenha nenhuma outra observação contratual.</p>
					<h3>6. CANCELAMENTO E EXCLUSÃO</h3>
					<p><strong>6.1.</strong> As contratações do Software e dos respectivos planos poderão ser canceladas a qualquer tempo, por iniciativa de quaisquer das partes.</p>
					<p><strong>6.2.</strong> A cobrança dos valores devidos em decorrência do plano contratado será interrompida mediante a solicitação de cancelamento, a pedido do Cliente, da assinatura do respectivo plano junto à equipe da Licenciante.</p>
					<p><strong>6.2.1.</strong> Com o pedido de cancelamento da assinatura, o acesso do Cliente ao Software será imediatamente bloqueado, sendo cessada a cobrança automática por período pela Licenciante, e pela plataforma Hotmart, no período subsequente, não havendo qualquer reembolso dos valores já pagos pelo Cliente.</p>
					<p><strong>6.2.2.</strong> A requisição de rescisão antecipada por parte do Cliente implicará a incidência de multa correspondente a 50% (cinquenta por cento) do valor remanescente do período contratado, sendo que o respectivo Cliente terá seu acesso ao Software bloqueado imediatamente.</p>
					<p><strong>6.2.3.</strong> O Cliente apenas receberá o reembolso integral do valor pago caso o cancelamento de sua assinatura ocorra em até 30 (trinta) dias após a primeira compra.</p>
					<p><strong>6.2.4.</strong> Não serão reembolsados os planos adicionais contratados posteriormente, como, por exemplo, de IP dedicado, SMS, bem como o plano de “teste de entregabilidade”.</p>
					<p><strong>6.2.5.</strong> Em caso de compra à vista, via cartão de crédito, o valor da compra será estornado ao Cliente na próxima fatura do seu cartão de crédito, ou na fatura do mês subsequente, dentro do prazo de 30 (trinta) a 60 (sessenta) dias, dependendo da data de fechamento de sua fatura e dos procedimentos internos adotados pela empresa operadora do cartão de crédito.</p>
					<p><strong>6.2.6.</strong> O estorno do valor pago, via boleto bancário, será realizado mediante conta corrente. Assim que o reembolso for efetivado no sistema do Hotmart (plataforma independente utilizada pela Licenciante para a realização e gestão de pagamentos), o Cliente receberá um e-mail para cadastrar a conta bancária onde será efetuado o reembolso.</p>
					<p><strong>6.2.7.</strong> No caso de pagamento parcelado, via cartão de crédito, o valor pago continuará a ser debitado nas sucessivas faturas do respectivo cartão, não ocorrendo o estorno, sendo certo que o reembolso devido será realizado pela Licenciante mediante transferência bancária (TED) ou PIX para a conta bancária informada pelo Cliente via contato com a área de suporte da Licenciante, nos termos do Item 3.6. deste Termo. Os dados da conta bancária informada deverão ser os mesmos constantes da nota fiscal/fatura emitida pela Licenciante relativa à assinatura de determinado plano da Plataforma pelo Cliente, sob pena de retenção dos valores pela Licenciante até os devidos esclarecimentos.</p>
					<p><strong>6.2.8.</strong> Não serão reembolsados valores de planos pertencentes a contas banidas por infringirem este Termo. A garantia do período de teste de 15 (quinze) dias da plataforma não é válida para casos de banimento.</p>
					<p><strong>6.2.9.</strong> Não serão reembolsados os valores pagos de qualquer cobrança subsequente à primeira compra, mesmo que esteja no prazo de 7 (sete) dias da referida cobrança, tendo em vista que o período de teste e arrependimento se aplica somente aos 15 (quinze) primeiros dias, a contar da data da primeira assinatura do Cliente.</p>
					<p><strong>6.3.</strong> A Licenciante poderá excluir os Clientes que não atuem em consonância com este Termo ou a legislação brasileira aplicável, especialmente nos casos definidos na Cláusula 9 abaixo, independentemente de prévia notificação, oportunidade em que serão imediatamente suspensos os cadastros e acessos do Cliente ao Software, sem prejuízo da cobrança de todos os valores devidos pelo Cliente em decorrência deste Termo e do uso do Software e contratação do respectivo plano.</p>
					<h3>7. REMUNERAÇÃO E FORMA DE PAGAMENTO PELO USO DO SOFTWARE</h3>
					<p><strong>7.1.</strong> O Cliente, após contratação e assinatura do Software, deverá efetuar os pagamentos mediante cobrança mensal feita pela Licenciante, através de um dos meios de pagamento disponibilizados por ela, a seu exclusivo critério.</p>
					<p><strong>7.2.</strong><strong> </strong>O não acesso à Plataforma e/ou não utilização dos serviços e funcionalidades oferecidas através do Software não isentará o Cliente do pagamento da mensalidade, sendo o pagamento devido pela simples disponibilização de tais serviços.</p>
					<p><strong>7.3.</strong> A compra via cartão de crédito acarretará pagamento automático da assinatura, mediante recorrência mensal, a qual constará da fatura do respectivo cartão utilizado.</p>
					<p><strong>7.4.</strong> A compra via boleto bancário resultará no envio recorrente e mensal do boleto para o endereço de e-mail cadastrado pelo Cliente no ato da contratação.</p>
					<p><strong>7.5.</strong> A Nota Fiscal referente será gerada no prazo de 30 (trinta) dias após a confirmação do pagamento, e enviada por e-mail ao Cliente.</p>
					<p><strong>7.6.</strong> Eventual cupom de desconto entregue pela Licenciante e seus parceiros ao Cliente poderá ser utilizado apenas 1 (uma) vez, limitado para um único CPF/CNPJ, sendo intransferível e não cumulativo.</p>
					<p><strong>7.6.1.</strong> Um novo cupom de desconto só pode ser ativado mediante novas assinaturas, sendo que a validade dos cupons de desconto é de, no máximo, 2 (dois) meses.</p>
					<h3>8. INADIMPLÊNCIA PELO CLIENTE</h3>
					<p><strong>8.1.</strong> A falta do pagamento da mensalidade pelo Cliente por prazo igual ou superior a 7 (sete) dias resultará no bloqueio da sua conta e, consequentemente, no bloqueio de acesso ao Software. A Hotmart (plataforma independente utilizada pela Licenciante para a realização e gestão de pagamentos) irá avisar os Clientes via e-mail sobre os atrasos da mensalidade, antes de a Licenciante realizar o bloqueio da conta do respectivo Cliente.</p>
					<p><strong>8.2.</strong> A liberação do acesso pelo Cliente à sua conta só acontecerá mediante o pagamento de todas as mensalidades em atraso, incluída a correção monetária pelo IGPM/FGV, incidente desde o dia seguinte ao prazo de vencimento para realização do pagamento, e os juros moratórios legais aplicáveis, os quais serão de 1% (um por cento) ao mês.</p>
					<p><strong>8.3.</strong> Após ser informado sobre o bloqueio da conta em razão da falta de pagamento, o Cliente terá um prazo adicional de 3 (três) dias para regularização do pagamento em atraso. A não regularização do pagamento dentro do prazo adicional resultará no cancelamento e exclusão total da conta do Cliente, sem prejuízo da adoção, pela Licenciante, das medidas judiciais e extrajudiciais cabíveis.</p>
					<p><strong>8.4.</strong> Caso verificado que o Software foi utilizado pelo Cliente sem o devido pagamento, e não havendo pedido de cancelamento de assinatura, o valor referente ao período utilizado poderá ser cobrado pela Licenciante, pelas vias cabíveis, com acréscimo de correção monetária e juros moratórios, nos termos da Cláusula 8.2 acima, sem prejuízo da cobrança de eventuais honorários advocatícios e custas judiciais.
					</p>
					<h3>9. DESCUMPRIMENTO CONTRATUAL</h3>
					<p><strong>9.1.</strong> Sem prejuízo de outras medidas, a Licenciante poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Cliente e o acesso ao Software, a qualquer tempo e independentemente de prévio aviso ou notificação, e inclusive dentro do prazo legal de 7 (sete) dias previsto no artigo 49 da Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), podendo também iniciar as ações legais cabíveis, nas seguintes hipóteses:</p>
					<ul>
						<li>se o Cliente: (i) violar qualquer dispositivo deste Termo; (ii) atrasar o pagamento da mensalidade, conforme Cláusula 8 deste Termo; (iii) descumprir com seus deveres de Cliente junto à Licenciante; ou, (iv) praticar atos fraudulentos ou dolosos;</li>
						<li>se o Cliente efetuar, propositalmente, a prática do Chargeback, contratando e utilizando o Software, porém cancelando, de má-fé, a referida contratação dentro do prazo legal de cancelamento mencionado no Item 8.1 acima, apenas para obter o reembolso do valor pago, hipótese em que o referido reembolso não será devido;</li>
						<li>se qualquer informação fornecida pelo Cliente estiver incorreta; ou,</li>
						<li>se a Licenciante entender que qualquer atitude do Cliente tenha causado algum dano a terceiros ou à Licenciante, inclusive a sua imagem e reputação perante o mercado, ou tenham a potencialidade de assim o fazer</li>
					</ul>
					<p><strong>9.2.</strong> O Cliente indenizará a Licenciante por qualquer demanda promovida por outros Clientes ou quaisquer terceiros, decorrentes de suas atividades no Software, por seu descumprimento deste Termo, ou pela comprovada violação de qualquer lei ou direitos de terceiro.</p>
					<h3>10. USO ILEGAL, ANTIÉTICO OU PROIBIDO DO SOFTWARE PELO CLIENTE</h3>
					<p><strong>10.1.</strong> É proibida a utilização do Software para a publicação, criação, armazenamento e/ou divulgação de conteúdo ilegal ou abusivo, bem como textos, fotos e/ou vídeos que tenham caráter difamatório, xenófobo, discriminatório, obsceno, ofensivo, ameaçador ou incentivador de condutas impróprias, abusivo, fraude, que prejudique, interrompa ou interfira nos serviços, vexatório, prejudicial, que contenha expressões de ódio contra pessoas ou grupos, ou que contenha pornografia infantil, pornografia explícita ou violenta, conteúdo que possa ser danoso a menores, que contenha insultos ou ameaças religiosas ou raciais, ou que incentive danos morais (incluindo os corporais) e patrimoniais, ou que possa violar qualquer direito de terceiro incluindo a sua privacidade e direitos de propriedade intelectual, para atos relacionados à criação, à divulgação e à disseminação de informações falsas ("Fake News"). Portanto, a utilização do Software para quaisquer das atividades aqui mencionadas é considerada falta grave, passível de encerramento imediato dos serviços prestados pela Licenciante e da disponibilização do acesso à Plataforma.</p>
					<p><strong>10.2.</strong> Além disso, o Cliente concorda que, ao usar o Software, não irá:</p>
					<ul>
						<li>Violar qualquer regra contida nestes Termos de Uso;</li>
						<li>Praticar ou propagar falsidades/fake news, assim entendidas a falsidade de informações (i.e: divulgação proposital e voluntária de informações que o Licenciado saiba ser falsa ou que sejam notoriamente falsas) e a falsidade ideológica;</li>
						<li>Publicar ou transmitir qualquer conteúdo abusivo ou ofensivo nos comentários;</li>
						<li>Cometer fraude;</li>
						<li>Violar ou infringir direitos de propriedade intelectual, direitos fiduciários ou contratuais, direitos de privacidade ou publicidade de terceiros;</li>
						<li>Propagar, distribuir ou transmitir códigos destrutivos, quer tenham ou não causado danos reais;</li>
						<li>Reunir dados pessoais ou comerciais, incluindo (mas não se limitando a) endereços de e-mail e/ou nomes de qualquer recurso da Internet, seja gerenciado pela Licenciante ou por terceiros, para fins comerciais, políticos, de benemerência ou outros, sem o consentimento dos proprietários desses dados;</li>
						<li>Reproduzir, replicar, copiar, alterar, modificar, vender, revender ou criar obras derivativas a partir de qualquer um dos serviços da Licenciante ou qualquer parte deles, incluindo websites e páginas web, ou as informações ou dados contidos nos serviços do Software;</li>
						<li>Transmitir conteúdo que não pertence ao Licenciado ou que não tenha direito de publicar ou distribuir, seja sob lei ou contrato;</li>
						<li>Acessar o Software sem autorização, por meio de práticas de "hacking", "password mining" ou qualquer outro meio fraudulento ou que represente violação a direito de terceiros;</li>
						<li>Realizar ou incentivar quaisquer atividades ilegais;</li>
						<li>Promover jogos de azar, pirâmides e/ou esquemas financeiros fraudulentos;</li>
						<li>Deixar de cumprir com quaisquer leis, normas, regras, princípios e regulamentações aplicáveis</li>
					</ul>
					<p><strong>10.2.1.</strong> A violação de qualquer uma das regras aqui postas resultará no bloqueio do acesso ao Software, sem prejuízo do cancelamento da conta do Cliente e eventuais cobranças de perdas e danos.</p>
					<p><strong>10.3.</strong> A Licenciante não se responsabiliza pelo conteúdo publicado através do Software, podendo remover o conteúdo da Internet caso verificada a violação deste termo ou de direito de terceiros.</p>
					<h3>11. ACESSO COMPARTILHADO</h3>
					<p><strong>11.1.</strong> O compartilhamento dos dados de acesso não é permitido sob hipótese alguma, de modo que o Cliente poderá ter seu acesso bloqueado se o sistema identificar acesso por diferentes IPs. Para acessos bloqueados, o Cliente terá que esperar até 72 (setenta e duas) horas para que seu acesso seja liberado novamente, após análise da equipe de TI da Licenciante.</p>
					<p><strong>11.2.</strong> O compartilhamento de dados de acesso, assim como cópia e/ou distribuição de conteúdo, sem autorização escrita do respectivo autor, é considerado pirataria.</p>
					<h3>12. TERMOS E CONDIÇÕES GERAIS</h3>
					<p><strong>12.1.</strong> Contratando o Software da Licenciante, o Cliente tem pleno conhecimento de que o sistema sofre alterações diariamente, não sendo obrigatório comunicados prévios perante cada alteração, a menos que seja julgado necessário pela Licenciante, a seu exclusivo critério.</p>
					<p><strong>12.2.</strong> O Cliente também tem pleno conhecimento de que há possibilidade de se deparar com "bugs" do Software, ou seja, falhas nos processos, bem como enfrentar lentidão e a impossibilidade de uso do sistema por algumas horas.</p>
					<p><strong>12.3.</strong> Especificamente quanto aos planos que possuem envio de SMS para leads, existem determinadas palavras, relativas a temas sensíveis ou ilegais, que, caso venham a constar no texto do SMS, podem acarretar o bloqueio das respectivas mensagens pelas operadoras de telefonia, por iniciativa totalmente alheia à vontade da Licenciante, fazendo com que não ocorra a efetiva entrega aos destinatários. Referidas palavras são definidas a exclusivo critério das operadoras de telefonia e não são divulgadas publicamente, de modo que não há como a Licenciante ter conhecimento de quais palavras são essas. Por conta disso, o Cliente fica desde já ciente de que, nas hipóteses em que os SMS não forem entregues aos destinatários em virtude de bloqueio promovido pelas operadoras de telefonia, a cobrança dos valores devidos será realizada normalmente pela Licenciante, inexistindo ao Cliente, nestas situações, o direito a qualquer tipo de reembolso e/ou indenização.</p>
					<p><strong>12.4.</strong> A Licenciante não se responsabiliza por eventuais danos materiais, danos morais ou lucros cessantes ocorridos em virtude de bugs imprevistos, bloqueios de envios realizados a leads por plataformas ou empresas terceiras, e/ou ocorrências externas temporárias que toda empresa está sujeita.</p>
					<p><strong>12.5.</strong> Este Termo será revisto periodicamente pela Licenciante, que poderá alterá-lo, excluindo, modificando ou inserindo cláusulas ou condições, a seu exclusivo critério. Não obstante, o Cliente será comunicado, por meio do próprio Software, sempre que houver uma alteração nas disposições deste Termo, para que possa ter ciência das novas condições. Caso não concorde com as alterações, deverá cancelar seu cadastro e cessar toda e qualquer utilização do Software.</p>
					<p><strong>12.6.</strong> Sob nenhuma hipótese ou em qualquer situação, se presumirá a eventual existência, ou se estabelecerá a presunção de qualquer vínculo empregatício, ou obrigações de caráter trabalhista e previdenciário entre as Partes, por si ou com os prepostos ou empregados da outra Parte, nem qualquer das Partes será fiadora das obrigações e encargos trabalhistas e sociais da outra Parte, a qual assume, neste ato, integral responsabilidade por tais obrigações, inclusive civil e penalmente.</p>
					<p><strong>12.7.</strong> Os tributos de qualquer natureza que sejam devidos em decorrência direta da contratação do Software oferecido pela Licenciante são de exclusiva responsabilidade do contribuinte do respectivo tributo, conforme definido na legislação tributária.</p>
					<p><strong>12.8.</strong> As Partes cumprirão e farão com que todos os seus empregados, subcontratados, consultores, agentes ou representantes cumpram a Lei 12.846/13, e demais leis de anticorrupção aplicáveis. Nenhuma das Partes pagará, oferecerá, prometerá ou dará, direta ou indiretamente, qualquer valor ou coisa de valor, incluindo quaisquer valores a ela pagos em decorrência dos serviços contratados, a qualquer funcionário ou oficial de um governo, empresa ou sociedade controlada pelo governo ou de propriedade do mesmo, partido político, candidato para cargo político, ou a qualquer outra pessoa estando ciente de, ou acreditando que tal valor ou item de valor será transmitido a alguém, para influenciar qualquer ação ou decisão por tal pessoa ou por qualquer órgão governamental com a finalidade de obter, reter ou conduzir negócios para quaisquer das Partes.</p>
					<p><strong>12.9.</strong> Este Termo constitui o acordo integral entre as Partes, relativamente ao uso da Plataforma e prevalece sobre quaisquer acordos anteriores.</p>
					<p><strong>12.10.</strong> Fica eleito o foro de Ponta Grossa/PR para dirimir quaisquer controvérsias acerca da utilização de Software ou deste Termo, com exclusão de quaisquer outros, por mais privilegiado que sejam.</p>
					<h4>O CLIENTE, NESTE ATO, DECLARA E GARANTE À LICENCIANTE QUE LEU, COMPREENDEU E CONCORDA COM TODOS AS DISPOSIÇÕES DESTE TERMO.</h4>
					<h4>O CLIENTE, BEM COMO OS SEUS RESPECTIVOS REPRESENTANTES LEGAIS, DECLARAM QUE ESTÃO DEVIDAMENTE AUTORIZADOS A ACEITAR ESTE TERMO, NA FORMA DE SEUS RESPECTIVOS DOCUMENTOS CONSTITUTIVOS.</h4>
				</Box>
			</Box>

			<LoginFooter />
  	</Box>
		</>
	)
}

export default PrivacyPolicies
