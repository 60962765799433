import React, { useState, useEffect } from 'react'
import { Paper, Box, FormControl, InputLabel, Select, MenuItem, Grid, Checkbox, TextField } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { CustomTable, FormHeader } from 'components'
import ListIcon from '@mui/icons-material/List'
import { useStyles } from './styles'
import api from 'services/api'
import { ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto'
import { IFoundProductDTO } from 'data/dtos/automation/i-found-product-dto'
import { useAlreadyMounted } from 'utils/use-already-mounted';
import { SelectChangeEvent } from '@mui/material/Select'
import { useAuth } from 'hooks/auth'


const headCells: ITableHeadCellDTO[] = [
  {
    id: 'image',
    label: 'Imagem',
    width: 1,
    format: 'image'
  },
  {
    id: 'updatedAt',
    label: 'Data',
    width: 2,
    format: 'date'
  },
  {
    id: 'title',
    label: 'Descrição do produto',
    width: 8
  },
]


const FoundProductList: React.FC = () => {
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(1000)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [foundProductsList, setFoundProductsList] = useState<IFoundProductDTO[]>([])
  const [recordToDelete, setRecordToDelete] = useState<string | null>('')
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [okModalOpen, setOkModalOpen] = useState(false)
  const [storeErrorModalOpen, setStoreErrorModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = React.useState([])
  const [stores, setStores] = useState([])
  const [currentStore, setCurrentStore] = React.useState('');
  const [currentStoreName, setCurrentStoreName] = React.useState('');
  const [panelHeight, setPanelHeight] = useState('')
  const [zeroSelected, setZeroSelected] = useState(true)
  const [selectedCount, setSelectedCount] = useState(0)
  const [gridView, setGridView] = useState(false)
  const [balanceErrorModalOpen, setBalanceErrorModalOpen] = useState(false)
  const [currentBalance, setCurrentBalance] = useState(0)

  const { user } = useAuth()

  const classes = useStyles()
  const alreadyMounted = useAlreadyMounted();

  const loadFoundProducts = async () => {
    setLoading(1)

    await api
      .post('/found-products/list-new-products', {search, page, rowsPerPage, columnOrder})
      .then(async listResponse => {
        const { data } = listResponse.data

        setFoundProductsList(data)

        let initialArray = data.map(function(item: any){
          const tempItem = {
            product: item,
            checked: false
          }
    
          return tempItem
        })
    
        setSelectedRows(initialArray)

        await api
          .post('/found-products/count', {search})
          .then(countResponse => {
            const { count } = countResponse.data.data
            setRowsCount(count)
          })
          .then(() => setLoading(0))
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDelete = async () => {
    await api
      .delete(`/found-products/${recordToDelete}`)
      .then(async () => {
        await loadFoundProducts()
      })
      .catch(error => {
        console.log(error.response.data)
      })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  // initial load

  useEffect(() => {
    async function loadData() {
      const user = localStorage.getItem('@onemandrop:user')
      const tempCurrentStore = JSON.parse(user).currentStore
      
      setCurrentStore(tempCurrentStore)
    }

    loadData()
  }, [])


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!alreadyMounted) {
        loadFoundProducts()
        setPanelHeight('calc(100vh - 500px)')
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!alreadyMounted) {
        loadFoundProducts()
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [search])


  useEffect(() => {
    loadFoundProducts()
  }, [orderByDirection, rowsPerPage, page])


  const handleModalClose = () => {
    setModalOpen(false)
  }


  const handleOkModalClose = () => {
    setOkModalOpen(false)
  }


  const handleStoreErrorModalClose = () => {
    setStoreErrorModalOpen(false)
  }


  //
  // handleModalSendOld
  //
  const handleModalSendOld = async () => {
    const store = await api.get(`/stores/${currentStore}`)

    // balance definition

    let targetBalance = 0

    const subscriptionStatus = await api.get('/subscriptions/status-check')
    const { data: subscriptionStatusData } = subscriptionStatus.data

    let selectedCount = 0
    selectedRows.forEach(async function(row) {
      if (row.checked) {
        selectedCount++
      }
    })

    setCurrentBalance(subscriptionStatusData.balance)

    // if (subscriptionStatusData.status === 'STARTED') {
    //   targetBalance = subscriptionStatusData.commercialPlanQuantity - 20
    // } else {
    //   targetBalance = 0
    // }

    
    // delivery

    if ((subscriptionStatusData.balance - selectedCount) < targetBalance) {
      setBalanceErrorModalOpen(true)
    } else {
      // clear all statuses at product_searchs table for delivery to shopify
      await api.get('/product-searchs/start-new-delivery')
      
      if (store.data.data.shopifyDomain === null) {
        setCurrentStoreName(store.data.data.storeName)
        setStoreErrorModalOpen(true)
      } else {
        // creates a rabbitmq message for each product
        selectedRows.forEach(async function(row) {
          if (row.checked) {
            const payLoad = {
              userId: user.id,
              storeId: currentStore,
              productId: row.product.productId,
              productSearchId: row.product.productSearchId,
              foundProductId: row.product.id
            }
            
            await api
              .post('/stores/create-product-shopify', payLoad)
              .catch(error => {
                console.log(error.response.data)
              })
          }
        })

        setOkModalOpen(true)
      }
    }
  }


  //
  // handleModalSend
  //
  const handleModalSend = async () => {
    const store = await api.get(`/stores/${currentStore}`)

    // balance definition

    let targetBalance = 0

    const subscriptionStatus = await api.get('/subscriptions/status-check')
    const { data: subscriptionStatusData } = subscriptionStatus.data

    let selectedCount = 0
    selectedRows.forEach(async function(row) {
      if (row.checked) {
        selectedCount++
      }
    })

    setCurrentBalance(subscriptionStatusData.balance)

    // if (subscriptionStatusData.status === 'STARTED') {
    //   targetBalance = subscriptionStatusData.commercialPlanQuantity - 20
    // } else {
    //   targetBalance = 0
    // }

    
    // delivery

    if ((subscriptionStatusData.balance - selectedCount) < targetBalance) {
      setBalanceErrorModalOpen(true)
    } else {
      // clear all statuses at product_searchs table for delivery to shopify
      await api.get('/product-searchs/start-new-delivery')
      
      if (store.data.data.shopifyDomain === null) {
        setCurrentStoreName(store.data.data.storeName)
        setStoreErrorModalOpen(true)
      } else {
        // locks store for delivery to shopify

        const storeToLock = {
          id: currentStore,
          lockedForShopifyProductCreation: true
        }

        await api
        .patch('/stores', storeToLock)
        .catch(error => {
          console.log(error.response.data)
        })


        // creates a single rabbitmq message for all selected products

        let payLoad: any[] = []

        selectedRows.forEach(async function(row) {
          if (row.checked) {
            const selectedProduct = {
              userId: user.id,
              storeId: currentStore,
              productId: row.product.productId,
              productSearchId: row.product.productSearchId,
              foundProductId: row.product.id
            }
            
            await api
              .post('/shopify-products', selectedProduct)
              .catch(error => {
                console.log(error.response.data)
              })
            
            payLoad.push(selectedProduct)
          }
        })

        await api
          .post('/stores/create-multiple-products-shopify', payLoad)
          .catch(error => {
            console.log(error.response.data)
          })

        setOkModalOpen(true)
      }
    }
  }


  const handleModalOpen = async () => {
    const user = localStorage.getItem('@onemandrop:user')
    const tempCurrentStore = JSON.parse(user).currentStore
    setCurrentStore(tempCurrentStore)

    const subscriptionStatus = await api.get('/subscriptions/status-check')
    const { data: subscriptionStatusData } = subscriptionStatus.data

    setCurrentBalance(subscriptionStatusData.balance)
    
    setModalOpen(true)
  }


  const handleBalanceErrorModalClose = () => {
    setBalanceErrorModalOpen(false)
  }


  const handleRowCheckBoxChange = (index: number, checked: boolean) => {
    let items = [...selectedRows]
    items[index].checked = checked
    setSelectedRows(items)

    const allFalse = items.every(item => item.checked === false)
    const checkedCount = items.filter(item => item.checked === true).length

    setSelectedCount(checkedCount)
    setZeroSelected(allFalse)
  }


  const handleHeaderCheckBoxChange = (checked: boolean) => {
    const resetedArray = Array.from({ length: foundProductsList.length }, (_, index) => ({
      product: foundProductsList[index],
      checked: checked,
    }));
  
    const allFalse = resetedArray.every(item => item.checked === false);
    const checkedCount = resetedArray.filter(item => item.checked === true).length;
  
    setSelectedCount(checkedCount);
    setSelectedRows(resetedArray);
    setZeroSelected(allFalse);
  }


  return (
    <Paper elevation={3} className={classes.paper}>

      <FormHeader
        title="Novos Produtos"
        icon={ListIcon}
        showModalFormButton={true}
        modalFormButtonLabel="Enviar Produtos para a Loja"
        handleModal={handleModalOpen}
        helpText="Aqui são informados dados sobre resultados de buscas de novos produtos."
      />

      <CustomTable
        headCells={headCells}
        rows={foundProductsList}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        editIcon="visibility"
        editRoute="/new-products/edit"
        handleDelete={handleDelete}
        handleRecordToDelete={setRecordToDelete}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        gridViewOption={true}
        gridViewOptionDefault={true}
      />


      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">Produtos a Serem Enviados para a Loja</DialogTitle>
        
        <DialogActions style={{paddingRight: 25, paddingBottom: 0, paddingTop: 0}}>
          <Box className={classes.selectedText}>Selecionados: {selectedCount}/{foundProductsList.length}&nbsp;&nbsp;&nbsp;<strong>{currentStoreName}(Saldo: {currentBalance})</strong></Box>
          <Button onClick={handleModalClose} variant="outlined">Fechar</Button>
          <Button onClick={handleModalSend} variant="contained" disabled={zeroSelected}>Enviar</Button>
        </DialogActions>

        <DialogContent style={{ height: '100vh', overflow: 'hidden' }}>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                style={{ 
                  padding: 0, 
                  border: '1px solid #EAEAEA',
                  borderTopRightRadius: 4,
                  borderTopLeftRadius: 4
                }}
              >
                <Grid container columnSpacing={{ xs: 1 }} 
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    background: '#f3f6f9', 
                    padding: '5px 20px 5px 14px', 
                    position: 'sticky', 
                    top: 0, 
                    fontWeight: 'bold',
                    marginLeft: 1,
                    width: '100%'
                  }}
                >
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <div><Checkbox onChange={(e) => handleHeaderCheckBoxChange(e.target.checked)} /></div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <div className={classes.gridHeader}>Imagem</div>
                  </Grid>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div className={classes.gridHeader}>Título</div>
                  </Grid>
                </Grid>

                <div style={{ marginTop: '5px' }}>
                  <Grid container style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'scroll' }}>
                    {foundProductsList.map((item, index) => (
                      <Grid key={index} container columnSpacing={{ xs: 1 }} 
                        style={{ 
                          borderBottom: '1px solid #EAEAEA',
                          padding: '9px 7px 4px 23px',
                          backgroundColor: (item.property1Titles.split(';').length * item.property2Titles.split(';').length) > 100 ? '#FEE8EA' : 'white'
                        }}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} >
                          <div className={classes.gridRow}>
                            <Checkbox 
                              id={item.id}
                              checked={selectedRows[index]?.checked}
                              onChange={(e) => handleRowCheckBoxChange(index, e.target.checked)}
                              className={classes.gridRowCheck}
                              //disabled={(item.property1Titles.split(';').length * item.property2Titles.split(';').length) > 100 ? true : false}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <div className={classes.gridRow}><img src={item.image} className={classes.tableImage}/></div>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{ borderBottom: '1px solid black !important' }}>
                          <div className={classes.gridRow}>{item.title}</div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <Dialog
        open={okModalOpen}
        onClose={handleOkModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Produtos enviados para a loja</DialogTitle>

        <DialogContent style={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            <Box>
              Produtos foram enviados para a fila de criação em sua loja, com sucesso. Assim que eles forem sendo criados, aparecerão em sua lista de produtos na loja.
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handleOkModalClose} variant="outlined">Fechar</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={storeErrorModalOpen}
        onClose={handleStoreErrorModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Dados ausentes para a loja selecionada</DialogTitle>

        <DialogContent style={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            <Box>
              Há dados ausentes para a loja "{currentStoreName}". Provavelmente "dominio" e/ou "token" ausentes. Por favor, verifique e atualize os dados através da opção "Lojas" e tente novamente.
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handleStoreErrorModalClose} variant="outlined">Fechar</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={balanceErrorModalOpen}
        onClose={handleStoreErrorModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Produtos selecionados acima do saldo do plano</DialogTitle>

        <DialogContent style={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            <Box>
              A quantidade de produtos selecionados está acima do saldo do plano. Por favor, verifique e tente novamente.
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handleBalanceErrorModalClose} variant="outlined">Fechar</Button>
        </DialogActions>
      </Dialog>

    </Paper>
  )
}

export default FoundProductList
