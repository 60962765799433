import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { INotificationDTO } from 'data/dtos/support/i-notification-dto'

interface IRouteParams {
  id: string
}

const NotificationForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    description: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control
  } = useForm<INotificationDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      openedAt: null,
      readAt: null,
      title: '',
      status: '',
      visible: false,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/notifications/${id}`)
        .then(response => {
          const { data } = response.data

          const notificationResult = {
            openedAt: data.openedAt,
            readAt: data.readAt,
            title: data.title,
            description: data.description,
            status: data.status,
            visible: data.visible,
          }

          return notificationResult
        })
        .then((notificationResult: INotificationDTO) => {
          reset(notificationResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: INotificationDTO) => {
    const payLoad: INotificationDTO = {
      openedAt: data.openedAt,
      readAt: data.readAt,
      title: data.title,
      description: data.description,
      status: data.status,
      visible: data.visible,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/notifications`, payLoad)
        .then(history.push('/notifications'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/notifications', payLoad)
        .then(history.push('/notifications/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Notificações"
          icon={ListIcon}
          backRoute="/notifications"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre as notificações."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="openedAt"
              label="Abertura"
              error={!!errors.openedAt}
              helperText={errors?.openedAt?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              {...register("openedAt",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="readAt"
              label="Leitura"
              error={!!errors.readAt}
              helperText={errors?.readAt?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              InputLabelProps={{
                shrink: true
              }}
              {...register("readAt",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="title"
              label="Título"
              error={!!errors.title}
              helperText={errors?.title?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("title",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="description" style={{ marginTop: '10px' }}>Descrição</InputLabel>
                <TextareaAutosize
                  id="description"
                  minRows={10}
                  maxRows={10}
                  value={getValues("description")}
                  {...register("description", {
                    onChange: (e) => {
                      setValue("description", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="description"
            control={control}
          />

          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="status"
              label="Status"
              error={!!errors.status}
              helperText={errors?.status?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("status",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="visible"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Visível"
                  />
                )}
              />
            </FormGroup>
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default NotificationForm
