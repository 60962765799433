import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { ISubscriptionDTO } from 'data/dtos/payment/i-subscription-dto'

interface IRouteParams {
  id: string
}

const SubscriptionForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [countriesA, setCountriesA] = useState([])
  const [statesB, setStatesB] = useState([])
  const [productsC, setProductsC] = useState([])
  const [commercialPlansD, setCommercialPlansD] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    date: yup.string()
      .required('Campo obrigatório'),
    name: yup.string()
      .required('Campo obrigatório'),
    productId: yup.string()
      .required('Campo obrigatório'),
    commercialPlanId: yup.string()
      .required('Campo obrigatório'),
    subscriptionStatus: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<ISubscriptionDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: '',
      name: '',
      email: '',
      checkoutPhone: '',
      countryId: '',
      stateId: '',
      city: '',
      zipcode: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      productId: '',
      commercialPlanId: '',
      subscriberCode: '',
      couponCode: '',
      chargeDay: '',
      actualRecurrenceValue: 0,
      comissions: '',
      subscriptionStatus: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select País

      await api
        .post('/countries/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((countriesResult) => {
          setCountriesA(countriesResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })

      // select Estado

      await api
        .post('/states/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((statesResult) => {
          setStatesB(statesResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })

      // select Produto

      await api
        .post('/products/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((productsResult) => {
          setProductsC(productsResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })

      // select Plano Comercial

      await api
        .post('/commercial-plans/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((commercialPlansResult) => {
          setCommercialPlansD(commercialPlansResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/subscriptions/${id}`)
        .then(response => {
          const { data } = response.data

          const subscriptionResult = {
            date: data.date,
            name: data.name,
            email: data.email,
            checkoutPhone: data.checkoutPhone,
            countryId: data.countryId.id,
            stateId: data.stateId.id,
            city: data.city,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            productId: data.productId.id,
            commercialPlanId: data.commercialPlanId.id,
            subscriberCode: data.subscriberCode,
            couponCode: data.couponCode,
            chargeDay: data.chargeDay,
            actualRecurrenceValue: data.actualRecurrenceValue,
            comissions: data.comissions,
            subscriptionStatus: data.subscriptionStatus,
          }

          return subscriptionResult
        })
        .then((subscriptionResult: ISubscriptionDTO) => {
          reset(subscriptionResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: ISubscriptionDTO) => {
    const payLoad: ISubscriptionDTO = {
      date: data.date,
      name: data.name,
      email: data.email,
      checkoutPhone: data.checkoutPhone,
      countryId: data.countryId,
      stateId: data.stateId,
      city: data.city,
      zipcode: data.zipcode,
      address: data.address,
      number: data.number,
      complement: data.complement,
      neighborhood: data.neighborhood,
      productId: data.productId,
      commercialPlanId: data.commercialPlanId,
      subscriberCode: data.subscriberCode,
      couponCode: data.couponCode,
      chargeDay: data.chargeDay,
      actualRecurrenceValue: data.actualRecurrenceValue,
      comissions: data.comissions,
      subscriptionStatus: data.subscriptionStatus,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/subscriptions`, payLoad)
        .then(history.push('/subscriptions'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/subscriptions', payLoad)
        .then(history.push('/subscriptions/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Assinaturas"
          icon={ListIcon}
          backRoute="/subscriptions"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre as assinaturas do produto."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <TextField
              id="date"
              label="Data e Hora"
              error={!!errors.date}
              helperText={errors?.date?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("date",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="name"
              label="Nome do Assinante"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="email"
              label="Email do Assinante"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("email",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="checkoutPhone"
              label="Telefone"
              error={!!errors.checkoutPhone}
              helperText={errors?.checkoutPhone?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("checkoutPhone",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="countryId"
              label="País"
              error={!!errors.countryId}
              helperText={errors?.countryId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().countryId}`}
              select
              {...register("countryId", { onChange: (e) => {
                setValue("countryId", e.target.value)
                handleChange(e)
              }})}
            >
            {countriesA.map((country) => (
              <MenuItem
                key={country.id}
                value={country.id}
              >
                {country.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="stateId"
              label="Estado"
              error={!!errors.stateId}
              helperText={errors?.stateId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().stateId}`}
              select
              {...register("stateId", { onChange: (e) => {
                setValue("stateId", e.target.value)
                handleChange(e)
              }})}
            >
            {statesB.map((state) => (
              <MenuItem
                key={state.id}
                value={state.id}
              >
                {state.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="city"
              label="Cidade"
              error={!!errors.city}
              helperText={errors?.city?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("city",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="zipcode"
              label="CEP"
              error={!!errors.zipcode}
              helperText={errors?.zipcode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 10
              }}
              {...register("zipcode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <TextField
              id="address"
              label="Endereço"
              error={!!errors.address}
              helperText={errors?.address?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("address",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <TextField
              id="number"
              label="Número"
              error={!!errors.number}
              helperText={errors?.number?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 10
              }}
              {...register("number",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="complement"
              label="Complemento"
              error={!!errors.complement}
              helperText={errors?.complement?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("complement",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="neighborhood"
              label="Bairro"
              error={!!errors.neighborhood}
              helperText={errors?.neighborhood?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("neighborhood",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="productId"
              label="Produto"
              error={!!errors.productId}
              helperText={errors?.productId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().productId}`}
              select
              {...register("productId", { onChange: (e) => {
                setValue("productId", e.target.value)
                handleChange(e)
              }})}
            >
            {productsC.map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
              >
                {product.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="commercialPlanId"
              label="Plano Comercial"
              error={!!errors.commercialPlanId}
              helperText={errors?.commercialPlanId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().commercialPlanId}`}
              select
              {...register("commercialPlanId", { onChange: (e) => {
                setValue("commercialPlanId", e.target.value)
                handleChange(e)
              }})}
            >
            {commercialPlansD.map((commercialPlan) => (
              <MenuItem
                key={commercialPlan.id}
                value={commercialPlan.id}
              >
                {commercialPlan.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="subscriberCode"
              label="Id no Gateway de Pagamento"
              error={!!errors.subscriberCode}
              helperText={errors?.subscriberCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("subscriberCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="couponCode"
              label="Cupom de Desconto"
              error={!!errors.couponCode}
              helperText={errors?.couponCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("couponCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="chargeDay"
              label="Dia da Cobrança"
              error={!!errors.chargeDay}
              helperText={errors?.chargeDay?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2
              }}
              {...register("chargeDay",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <NumericInput
              label="Valor Recorrente Atual"
              precision={2}
              decimalChar=','
              thousandChar='.'
              variant='outlined'
              value={getValues("actualRecurrenceValue")}
              {...register("actualRecurrenceValue", { onChange: (e) => {
                setValue("actualRecurrenceValue", e.target.value)
                handleChange(e)
              }})}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="comissions"
              label="Comissões"
              error={!!errors.comissions}
              helperText={errors?.comissions?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("comissions",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="subscriptionStatus"
              label="Status"
              error={!!errors.subscriptionStatus}
              helperText={errors?.subscriptionStatus?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("subscriptionStatus",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default SubscriptionForm
