import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, Typography, Tooltip } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useAuth } from 'hooks/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import { useApplication } from 'hooks/use-application'
import ListIcon from '@mui/icons-material/List'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import AppsIcon from '@mui/icons-material/Apps';
import ViewListIcon from '@mui/icons-material/ViewList';
import LinkIcon from '@mui/icons-material/Link'
import RefreshIcon from '@mui/icons-material/Refresh';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import NoPhotographyIconImg from '../../../assets/no-photography-icon.png';
import InfoIcon from '@mui/icons-material/Info'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IProductSearchDTO } from 'data/dtos/automation/i-product-search-dto'
import HelpHomeStep1 from 'assets/help/pt/home-step-01.png'
import HelpHomeStep2 from 'assets/help/pt/home-step-02.png'
import HelpHomeStep3 from 'assets/help/pt/home-step-03.png'
import HelpHomeStep4 from 'assets/help/pt/home-step-04.png'
import HelpHomeStep5 from 'assets/help/pt/home-step-05.png'
import HelpHomeStep6 from 'assets/help/pt/home-step-06.png'
import HelpHomeStep7 from 'assets/help/pt/home-step-07.png'
import HelpHomeStep8 from 'assets/help/pt/home-step-08.png'
import { exit } from 'process'

interface IRouteParams {
  id: string
}

interface ISupplier {
  storeId: string,
  storeName: string,
  countryName: string,
  openDate: string,
  openedYears: string,
  feedbackRating: string,
  feedbackRatingCount: string,
  freight: string,
  maxPrice: string,
  maxPriceFound: string,
}

interface IPropertyValue {
  value: string,
  skuPropertyImagePath: string,
  propertySizeChartInfo: string[]
}

interface IProp {
  attrName: string,
  attrValue: string
}

interface IProduct {
  productId: string,
  description: string,
  currencyCode: string,
  supplier: ISupplier,
  images: string[],
  properties: string[],
  propertyValues: IPropertyValue[][],
  props: IProp[]
}

interface ISummary {
  summary: string
  status: string
  total_count: number
}

const Home: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [productSearchsA, setProductSearchsA] = useState([])
  const [stores, setStores] = useState([])
  const [panelHeight, setPanelHeight] = useState('')
  const [productData, setProductData] = useState<IProduct>()
  const [open, setOpen] = useState(false)
  const [reload, setReload] = useState(false)
  const [counterSearch, setCounterSearch] = useState(0)
  const [counterCapture, setCounterCapture] = useState(0)
  const [counterSent, setCounterSent] = useState(0)
  const [counterPlan, setCounterPlan] = useState(0)
  const [counterErrors, setCounterErrors] = useState(0)
  const [counterText, setCounterText] = useState('')
  const [timeLeft, setTimeLeft] = useState(null)
  const [productRetryUrl, setProductRetryUrl] = useState('')
  const [isLoading, setIsLoading] = useState(0)
  const [gridView, setGridView] = useState(true)
  const [searchSummaries, setSearchSummaries] = useState([])
  const [shopifySummaries, setShopifySummaries] = useState([])
  const [disabledSearchs, setDisabledSearchs] = useState(false)

  const { helpOpen, setHelpOpen } = useApplication()
  const { helpText, setHelpText } = useApplication()
  const { currentStore, setCurrentStore } = useApplication()

  const { signOut } = useAuth();
  
  const buttonRef = useRef(null)
  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const statusDescription = (status: string, source: string) => {
    if (status) {
      status = status.replace('error_extracting', 'Problema na extração')
      status = status.replace('error_page_unavailable', 'Página não está mais disponível')
      status = status.replace('error_updating_search', 'Ainda tentando atualizar a busca')
      status = status.replace('error_variants_qty', 'Quantidade de variantes difere')
      status = status.replace('error_variants_limit', 'Variantes acima do limite de 100')
      status = status.replace('error_variants_color_not_found', 'Cor da variante não localizada')
      status = status.replace('error_variants_updating_image', 'Erro ao atualizar imagem da variante')
      status = status.replace('error_internal', 'Estrutura de dados do produto inválida')
      status = status.replace('ok', source === 'capture' ? 'Capturados com sucesso' : 'Enviados para a loja' )
    } else {
      status = source === 'capture' ? 'Aguardando processamento' : 'Aguardando envio para a loja'
    }

    return status
  }

  
  // get product file on storage

  const getProductFile = async (id: String) => {
    await api
      .get(`/product-searchs/download-file/${id}`)
      .then(response => {
        const { data } = response.data

        return data
      })
      .then((fileContent) => {
        const jsonArray = JSON.parse(fileContent) as IProduct
        setProductData(jsonArray)
      })
      .catch(error => {
        console.log(error)
        return error
      })
  }

  const validationSchema = yup.object().shape({
    productUrl: yup.string()
      .required('Campo obrigatório')
      .test('contains-aliexpress', 'A URL informada deve ser uma URL de produto do "aliexpress"', (value) => {
        return value && value.includes('aliexpress.com/item')
      })
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue
  } = useForm<IProductSearchDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      productUrl: '',
    }
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(1)
    }, 5000)

    return () => clearInterval(interval) 
  }, [])


  useEffect(() => {
    if (timeLeft === 0) {
      setReload(true)
      setTimeLeft(null)
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);


  // initial load

  useEffect(() => {
    async function loadData() {

      setIsLoading(1)

      // list Produt Searchs

      await api
        .get('/product-searchs/list-requested')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((productSearchsResult) => {
          setProductSearchsA(productSearchsResult)
          
          setIsLoading(0)
        })
        .catch(error => {
          console.log(error)
          // signOut()
          // history.push('/')
          // window.location.reload();
          return error
        })


      // get dashboard data

      await api
        .get('/product-searchs/dashboard-data')
        .then(response => {
          const { data } = response.data

          if (data[0]) {
            return data[0]
          } else {
            const tempData = {
              counter_search: 0,
              counter_capture: 0,
              counter_sent: 0, 
              plan_balance: 0 
            }

            return tempData
          }
        })
        .then((dashboardData) => {
          setCounterSearch(parseInt(dashboardData.counter_search))
          setCounterCapture(parseInt(dashboardData.counter_capture))
          setCounterSent(parseInt(dashboardData.counter_sent))
          setCounterPlan(parseInt(dashboardData.plan_balance))
        })
        .catch(error => {
          console.log(error)
          // signOut()
          // history.push('/')
          // window.location.reload();
          return error
        })


      // get dashboard summaries

      await api
        .get('/product-searchs/dashboard-summaries')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((dashboardSummaries: ISummary[]) => {
          const searchSummary = dashboardSummaries.filter(item => item.summary.trim() === 'search')
          const shopifySummary = dashboardSummaries.filter(item => item.summary.trim() === 'shopify')

          setSearchSummaries(searchSummary)
          setShopifySummaries(shopifySummary)
        })
        .catch(error => {
          console.log(error)
          // signOut()
          // history.push('/')
          // window.location.reload();
          return error
        })

      setReload(false)
    }

    loadData()
  }, [reload, currentStore])


  // data save

  const onSubmit = useCallback(async (data: IProductSearchDTO) => {
    let url = ''
    
    const subscriptionStatus = await api.get('/subscriptions/status-check')
    const { data: subscriptionStatusData } = subscriptionStatus.data

    if (subscriptionStatusData.errorMessage !== '') {
      setMainError(subscriptionStatusData.errorMessage)
    } else {
      if (productRetryUrl !== '') {
        url = productRetryUrl
        setProductRetryUrl('')
      } else {
        url = data.productUrl
      }

      const lines = url.split(' ')
      const tempProductUrls = lines.filter(line => line.includes('aliexpress'))

      const productUrls = tempProductUrls.filter(function(item, pos, self) {
        return self.indexOf(item) === pos;
      })

      setDisabledSearchs(true)

      let currentCount = 1
      let totalCount = productUrls.length.toString()
      for await (let productUrl of productUrls) {
        setCounterText(`Localizando: ${currentCount.toString()}/${totalCount}`)
        const payLoad: IProductSearchDTO = {
          productUrl: productUrl,
        }

        try {
          const productSearchResponse = await api.post('/product-searchs', payLoad)

          const { data } = productSearchResponse.data
          
          const productPayload: IProductSearchDTO = { 
            id: data.id, 
            productUrl: productUrl 
          }

          await api.post('/product-searchs/download', productPayload)

          history.push('/home')
          setReload(true)
          //window.location.reload()
          reset()
          firstInputElement.current.focus()
        } catch (error: any) {
          console.log(error.response.data);
          setMainError(error.response.data.data.name);
        }

        currentCount++
      }

      setDisabledSearchs(false)
    }

    setCounterText('')
    setTimeLeft(6)
  }, [productRetryUrl])  


  // on error

  useEffect(() => {
    if (errors.productUrl) {
      setPanelHeight('calc(100vh - 425px)')
    } else {
      setPanelHeight('calc(100vh - 390px)')
    }
  }, [errors.productUrl])


  // on field change

  const handleChange = (formField: any) => {
    setMainError('')
  }


  // modal form

  const productLinkRetry = (productLink: string) => {
    setValue('productUrl', productLink)
    buttonRef.current.click()
  }
  
  const handleStartNewSearch = async () => {
    await api.get('/product-searchs/truncate')
    await api.get('/found-products/truncate')

    history.push('/')

    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (mainError === 'ServerError') {
      setMainError('Cadastre ao menos uma loja. Para buscas de produtos, não são necessárias as informações da Shopify (domínio e token de acesso ao OneManDrop).')
    }
  }, [mainError])

  const testeHelp = () => {
    return (
      <Box>
        <Typography variant="h5">Como começar a usar o OneManDrop</Typography>
        <p><strong>Passo 1:</strong> Copie e cole o link do AliExpress na busca de produtos. Basta pressionar a tecla "ENTER", que a busca inicia em seguida.</p>
        <img src={HelpHomeStep1} alt="Passo 1"/>
        <p><strong>Passo 2:</strong> Veja como o OneManDrop criou a página do produto, acessando a opção "Novos produtos", clicando no ícone circundado pelo quadrado vermelho.</p>
        <img src={HelpHomeStep2} alt="Passo 2"/>
        <p><strong>Passo 3:</strong> Após visualizar a página do produto, clique no botão "Retornar".</p>
        <img src={HelpHomeStep3} alt="Passo 3"/>
        <p><strong>Passo 4:</strong> Antes que possa enviar seus produtos para as lojas, você precisar cadastrar o OneManDrop na Shopify e complementar os dados da loja no OneManDrop. Selecione a opção "Lojas" e clique no ícone de edição.</p>
        <img src={HelpHomeStep4} alt="Passo 4"/>
        <p><strong>Passo 5:</strong> Clique no botão "Guia de Integração Shopify", siga as instruções da página. Ao final dos procedimentos você estará pronto para enviar os produtos para as lojas.</p>
        <img src={HelpHomeStep5} alt="Passo 5"/>
        <p><strong>Passo 6:</strong> Para enviar os produtos para a loja, selecione a opção "Novos produtos" e clique o botão "Enviar Produtos para a Loja".</p>
        <img src={HelpHomeStep6} alt="Passo 6"/>
        <p><strong>Passo 7:</strong> Selecione os produtos que deseja enviar para a loja e clique no botão "Enviar".</p>
        <img src={HelpHomeStep7} alt="Passo 7"/>
        <p><strong>Passo 8:</strong> Retorne para "Home" e acompanhe os envios e saldos no dashboard.</p>
        <img src={HelpHomeStep8} alt="Passo 8"/>
      </Box>
    )
  }

  const togglePanel = () => {
    setHelpOpen(!helpOpen)
    setHelpText(testeHelp())
  }

  return (
    <Box className={classes.papersContainer}>

      <Box className={classes.sequence}>
        <Paper elevation={3} className={classes.paperCounters}>
          <Box className={classes.counterTitle}>Busca de Produtos</Box>
          <Box className={classes.counterNumber}>{counterSearch}</Box>
          <Tooltip title="">
            <InfoIcon className={classes.panelIconHidden} />
          </Tooltip>
        </Paper>

        <Paper elevation={3} className={classes.paperCounters}>
          <Box className={classes.counterTitle}>Captura de Dados</Box>
          <Box className={classes.counterNumber}>{counterCapture}</Box>
          <Tooltip 
            title={<>
              {searchSummaries.map((item: ISummary, index: number) => (
                <Typography key={index} className={classes.summaries}>
                  <div className={classes.summaryCounter}>{item.total_count}:</div> {statusDescription(item.status, 'capture')}
                </Typography>
              ))}
            </>}
            classes={{ tooltip: classes.customWidth }}
          >
            <InfoIcon className={searchSummaries.length === 0 ? classes.panelIconHidden : classes.panelIcon } />
          </Tooltip>
        </Paper>

        <Paper elevation={3} className={classes.paperCounters}>
          <Box className={classes.counterTitle}>Enviados para Loja</Box>
          <Box className={classes.counterNumber}>{counterSent}</Box>
          <Tooltip 
            title={<>
              {shopifySummaries.map((item: ISummary, index: number) => (
                <Typography key={index} className={classes.summaries}>
                  <div className={classes.summaryCounter}>{item.total_count}:</div> {statusDescription(item.status, 'store')}
                </Typography>
              ))}
            </>}
            classes={{ tooltip: classes.customWidth }}
          >
            <InfoIcon className={shopifySummaries.length === 0 ? classes.panelIconHidden : classes.panelIcon } />
          </Tooltip>
        </Paper>

        <Paper elevation={3} className={classes.paperCounters}>
          <Box className={classes.counterTitle}>Saldo do Plano</Box>
          <Box className={classes.counterNumber}>{counterPlan || 0}</Box>
          <Tooltip title="Clique para o detalhamento!">
            <InfoIcon className={classes.panelIcon} onClick={() => history.push('/plan-transactions')}/>
          </Tooltip>
        </Paper>
      </Box>

      <Paper elevation={3} className={classes.paper}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          data-testid="form"
        >
          <FormHeader
            title="Captura de Dados de Produtos"
            icon={ListIcon}
            showModalFormSecondaryButton={true}
            modalFormSecondaryButtonLabel="Iniciar novo ciclo de captura de dados de produtos"
            handleSecondaryModal={handleOpen}
            showSaveButton={false}
            saveButtonLabel="Buscar"
            helpText='Copie e cole os links dos produtos que deseja coletar os dados. Após copiar e colar, pressione a tecla "ENTER" ou clique no botão "Buscar Produto", a busca será iniciada imediatamente.'
            showHelpButton={true}
            helpButtonLabel="Clique aqui para saber como começar!"
            handleHelpButton={togglePanel}
          />

          <FormAlert setMainError={setMainError} mainError={mainError} />

          <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{marginLeft: -8}}>
              <TextField
                id="productUrl"
                label="Copie e cole aqui o link do produto do AliExpress"
                error={!!errors.productUrl}
                helperText={errors?.productUrl?.message}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                autoFocus
                inputRef={firstInputElement}
                InputLabelProps={{
                  shrink: true
                }}
                {...register("productUrl",
                  { onChange: (e) => handleChange(e) }
                )}
                style={{ marginBottom: 15, paddingLeft: '0 !important' }}
                disabled={disabledSearchs}
              />
              <Box className={counterText !== '' ? classes.counter : classes.emptyCounter}>{counterText}</Box>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <Button ref={buttonRef} type="submit" variant="contained" color="primary" style={{marginTop: 8, width: '100%'}} disabled={disabledSearchs} >Buscar Produto</Button>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <Box className={classes.displayIcons}>
                <ViewListIcon  className={classes.listIcon} onClick={() => setGridView(false)} />
                <AppsIcon  className={classes.appsIcon} onClick={() => setGridView(true)} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
              style={{ 
                padding: 0, 
                border: '1px solid #EAEAEA',
                borderTopRightRadius: 4
              }}
            >
              {
                !gridView ?
                <>
                  <div style={{ backgroundColor: '#F0F0F0' }}>
                    <div className={ isLoading === 1 ? classes.linearProgressOn : classes.linearProgressOff }>
                      {/* <LinearProgress /> */}
                    </div>
                  </div>

                  <Grid container columnSpacing={{ xs: 1 }} 
                    style={{ 
                      background: '#f0f0f0', 
                      position: 'sticky', 
                      top: 0, 
                      fontWeight: 'bold',
                      marginLeft: 0
                    }}
                  >
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div></div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div className={classes.gridHeader}>Imagem</div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div className={classes.gridHeader}>Criado em</div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className={classes.gridHeader} style={{ marginLeft: -3 }}>Descrição do produto</div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <div className={classes.gridHeader} style={{ marginLeft: -6 }}>Status</div>
                    </Grid>
                  </Grid>

                  <Box className={classes.productList} style={{ height: `${panelHeight}`, overflow: 'overlay' }}>
                    <Grid container>
                      {productSearchsA.map((item, index) => (
                        <Grid container columnSpacing={{ xs: 1 }} 
                          style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            borderBottom: '1px solid #EAEAEA',
                            paddingLeft: '10px'
                          }}
                        >
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <div className={classes.iconArea}>
                                <div className={classes.searchProductsIcon}>
                                  <span onClick={() => window.open(item.productUrl)} style={{ cursor: 'pointer', marginRight: 10}}>
                                    <Tooltip title="Link original"><LinkIcon /></Tooltip>
                                  </span>
                                  <span onClick={() => productLinkRetry(item.productUrl)} style={{ cursor: 'pointer'}}>
                                    <Tooltip title="Localizar esse produto novamente"><RefreshIcon /></Tooltip>
                                  </span>
                                </div>
                            </div>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            {
                              item.image ?
                                <img 
                                  src={item.image + '?not-from-cache-please'} 
                                  className={classes.tableImage} alt="" 
                                />
                              :
                                <span>
                                  <NoPhotographyIcon className={gridView ? classes.tableImageIcon : classes.tableImageIconList} />
                                </span>
                            }

                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.gridRow}>{item.createdAt}</div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className={classes.gridRow}>
                              {
                                item.productDescription === null 
                                ? 'Localizando dados do produto. Aguarde...' 
                                : item.productDescription
                              }
                            </div>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <div className={classes.gridRow}>
                              {
                                item.isProcessed 
                                ? 'Disponível'
                                : (index === 0 
                                    ? 'Localizando' 
                                    : 'Indisponível'
                                  ) 
                              }
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
              </>
              : 
              <>
                <Box className={classes.productCardsContainer} style={{ height: `${panelHeight}`, overflow: 'overlay' }}>
                {productSearchsA.map((item, index) => (
                  <Box className={classes.productCard}>
                    <Box className={classes.productCardImage}>
                      {
                        item.image ?
                          <img 
                            src={item.image + '?not-from-cache-please'} 
                            className={classes.tableGridImage} alt="Produto não encontrado" 
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; 
                              currentTarget.src="images/no-photography-icon.png";
                            }}
                          />
                        :
                          <span>
                            <NoPhotographyIcon className={classes.tableImageIcon} />
                          </span>
                      }
                    </Box>
                    <Box className={classes.productCardInfo}>
                      <Box className={classes.productCardTitle}>
                      {
                        item.productDescription === null 
                        ? 'Localizando dados do produto. Aguarde...' 
                        : item.productDescription
                      }
                      </Box>
                    </Box>

                    <Box className={classes.productCardIcons}>
                      <Tooltip title="Link original"><LinkIcon className={classes.productCardIcon} onClick={() => window.open(item.productUrl, '_blank')} /></Tooltip>
                      <Tooltip title="Localizar esse produto novamente"><RefreshIcon className={classes.productCardIcon} onClick={() => productLinkRetry(item.productUrl)} /></Tooltip>
                    </Box>
                  </Box>
                ))}
                </Box>
              </>
              }
            </Grid>
          </Grid>
        </Box>
    </Paper>

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Iniciar novo ciclo de captura de dados</DialogTitle>

      <DialogContent style={{ width: '500px' }}>
        <DialogContentText id="alert-dialog-description">
          Todas os produtos resultantes das últimas buscas, serão excluídos. Os produtos que já foram enviados para a loja não serão afetados. Deseja realmente iniciar um novo ciclo de captura de dados de produtos?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="contained">Não</Button>
        <Button onClick={handleStartNewSearch} variant="contained">Sim</Button>
      </DialogActions>
    </Dialog>

  </Box>
  )
}

export default Home
