import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper, InputBase, TablePagination, LinearProgress, Box, Tooltip, IconButton } from '@mui/material'
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import AppsIcon from '@mui/icons-material/Apps'
import ViewListIcon from '@mui/icons-material/ViewList'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import ReportOffIcon from '@mui/icons-material/ReportOff'
import { useStyles } from './styles'
import { ThirteenMp } from '@mui/icons-material'

type Props = {
  headCells: ITableHeadCellDTO[],
  rows: any[],
  totalRows: number,
  handleSearch: React.Dispatch<React.SetStateAction<string>>,
  isLoading: number,
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => Promise<void>,
  rowsPerPage: number,
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => void,
  page: number,
  deleteIcon?: string,
  editIcon?: string,
  editRoute: string,
  handleDelete: () => void,
  handleRecordToDelete: React.Dispatch<React.SetStateAction<string>>,
  orderByDirection: boolean,
  setOrderByDirection: React.Dispatch<React.SetStateAction<boolean>>
  columnOrder: ('ASC' | 'DESC')[],
  setColumnOrder: React.Dispatch<React.SetStateAction<('ASC' | 'DESC')[]>>,
  gridViewOption?: boolean,
  gridViewOptionDefault?: boolean
  sortDisabled?: boolean
}


const statusDescription = (status: string, source: string) => {
  if (status) {
    status = status.replace('error_extracting', 'Problema na extração')
    status = status.replace('error_page_unavailable', 'Página não está mais disponível')
    status = status.replace('error_updating_search', 'Erro ao atualizar busca')
    status = status.replace('error_variants_qty', 'Quantidade de variantes difere')
    status = status.replace('error_variants_limit', 'Variantes acima do limite de 100')
    status = status.replace('error_variants_color_not_found', 'Cor da variante não localizada')
    status = status.replace('error_variants_updating_image', 'Erro ao atualizar imagem da variante')
    status = status.replace('error_internal', 'Estrutura de dados do produto inválida')
    status = status.replace('ok', source === 'capture' ? 'Capturados com sucesso' : 'Enviados para a loja' )
  } else {
    status = source === 'capture' ? 'Aguardando processamento' : 'Aguardando envio para a loja'
  }

  return status
}


const dateWithoutTimezone = (date: Date) => {
  if (date.toDateString().substring(0, 10) === 'Wed Dec 31' || date === null) {
    return ''
  }

  const tzoffset = date.getTimezoneOffset() * 60000
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1)

  return withoutTimezone
}


const formatBackground = ( row: any, backgroundColor?: any ) => {
  if (backgroundColor) {
    //return '#FFD6D6'

    return backgroundColor.colors[row[backgroundColor.column]]
  }
}


const timeConverter = (UNIX_timestamp: string) => {
  let a = null

  if (UNIX_timestamp.length === 10) {
    a = new Date(parseInt(UNIX_timestamp) * 1000)
  } else {
    a = new Date(parseInt(UNIX_timestamp))
  }

  const year = a.getFullYear()
  const month = (a.getMonth() + 1).toString().padStart(2, '0')
  const day = a.getDate().toString().padStart(2, '0')
  const hour = a.getHours().toString().padStart(2, '0')
  const min = a.getMinutes().toString().padStart(2, '0')
  const sec = a.getSeconds().toString().padStart(2, '0')

  const time = day + '/' + month + '/' + year + ' ' + hour + ':' + min + ':' + sec 
  
  return time
}


const datePresenter = (date: string, format: string) => {
  if (date === '' || date === null) {
    return ''
  }

  let output = date


  switch (format) {
    case 'br':
      output = date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4) + ' ' + date.substring(11, 16)
      break

    default:
      output = date
  }

  return output
}


const getCommaSeparatedTwoDecimalsNumber = (currency: string) => {
  const fixedNumber = parseFloat(currency).toFixed(2).toString()

  return fixedNumber
}


const formatString = (fieldContent: string, format: string, conversion: { [key: string]: string }) => {
  let output = fieldContent

  if ((typeof fieldContent == 'boolean') && (fieldContent === true)) {
    output = 'X'
  }

  switch (format) {
    case 'cnpj':
      output = output.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      break

    case 'date':
      output = datePresenter(dateWithoutTimezone(new Date(output)).substring(0, 10).replace('T', ' '), 'br')
      break

    case 'datetime':
      output = datePresenter(dateWithoutTimezone(new Date(output)).substring(0, 16).replace('T', ' '), 'br')
      break
      
    case 'unixTimestamp':
      output = timeConverter(output)
      break

    case 'currency':
      output = getCommaSeparatedTwoDecimalsNumber(output).replaceAll('.', '#').replaceAll(',', '.').replaceAll('#', ',')  	
      break
  }

  if (output in conversion) {
    output = conversion[output]
  }

  return output
}


const CustomTable: React.FC<Props> = ({ 
  headCells, 
  rows, 
  totalRows,
  handleSearch, 
  isLoading,
  handleChangeRowsPerPage,
  rowsPerPage,
  handleChangePage,
  page,
  deleteIcon,
  editIcon,
  editRoute,
  handleDelete,
  handleRecordToDelete,
  orderByDirection,
  setOrderByDirection,
  columnOrder,
  setColumnOrder,
  gridViewOption,
  gridViewOptionDefault,
  sortDisabled
}: Props) => {
  const [open, setOpen] = useState(false)
  const [gridView, setGridView] = useState(gridViewOptionDefault)

  const classes = useStyles()
  const history = useHistory()
  const defaultWidth = (headCells.length > 1 ? 100 / headCells.length : 100)

  const handleClickOpen = (record: string) => {
    setOpen(true)
    handleRecordToDelete(record)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDelete = () => {
    setOpen(false)
    handleDelete()
  }

  const handleSortChange = (index: number) => { 
    columnOrder[index] = columnOrder[index] === 'ASC' ? 'DESC' : 'ASC' 

    setColumnOrder(columnOrder)
    setOrderByDirection(!orderByDirection)
  }

  useEffect(() => {
    const sortArray: Array<('ASC' | 'DESC')> = headCells.map(() => 'ASC')

    setColumnOrder(sortArray)
  }, [headCells])

  return (
    <>
      <div className={classes.searchContainer}>
        <div className={gridViewOption ? classes.search : classes.searchFull}>
          <InputBase
            placeholder={'Informe o termo de busca aqui...'}
            className={classes.inputInput}
            onChange={ (e) => { handleSearch(e.target.value) } }
          />
        </div>

        { 
        gridViewOption?
          <Box className={classes.displayIcons}>
            <ViewListIcon  className={classes.listIcon} onClick={() => setGridView(false)} />
            <AppsIcon  className={classes.appsIcon} onClick={() => setGridView(true)} />
          </Box>
        : null 
        }
      </div>

      <div className={ isLoading === 1 ? classes.linearProgressOn : classes.linearProgressOff }>
        <LinearProgress />
      </div>

      <Paper className={classes.tablePaper}>
      {
        !gridView || !gridViewOption?
        <>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>

              <TableHead>
                <TableRow key={0}>
                  <TableCell className={editIcon !== 'none' ? classes.rowTableIcon : classes.tableIconOff}>&nbsp;</TableCell>
                  <TableCell className={deleteIcon !== 'none' ? classes.rowTableIcon : classes.tableIconOff}>&nbsp;</TableCell>

                  {headCells.map((headCell, index) => (
                    <TableCell 
                      align={headCell.format === 'currency' || headCell.format === 'number'  ? "right" : "left"} 
                      style={{ 
                        width: `${typeof(headCell.width) !== 'undefined' ? (headCell.width * 100 / 11) : defaultWidth}%`, 
                        fontWeight: 'bold',
                        color: '#6278E7 !important'
                      }}
                    >
                      {sortDisabled !== true 
                      ? 
                      <TableSortLabel
                        active={true}
                        direction={columnOrder[index] === 'ASC' ? 'asc' : 'desc'}
                        onClick={() => handleSortChange(index)}
                        style={{ 
                          fontWeight: 'bold',
                          color: '#6278E7 !important',
                          paddingTop: 7,
                          paddingBottom: 7,
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                      : 
                      <>{headCell.label}</>
                      }
                    </TableCell>
                  ))}

                </TableRow>
              </TableHead>

              <TableBody style={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }}>
                {rows.map((row) => (
                <TableRow hover key={row.id} >
                  <TableCell className={editIcon !== 'none' ? classes.rowTableIcon : classes.tableIconOff}>
                    {editIcon !== 'visibility' 
                      ? <EditIcon className={classes.tableIcon} onClick={() => history.push(`${editRoute}/${row.id}`)} />
                      : <VisibilityIcon className={classes.tableIcon} onClick={() => history.push(`${editRoute}/${row.id}`)} />
                    }
                  </TableCell>
                  <TableCell className={deleteIcon !== 'none' ? classes.rowTableIcon : classes.tableIconOff}>
                    {deleteIcon !== 'none' 
                      ? <DeleteIcon className={classes.tableIcon} onClick={() => handleClickOpen(row.id) } />
                      : null
                    }
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell align={headCell.format === 'currency' || headCell.format === 'number' ? "right" : "left"} style={{ minWidth: 100 }}>
                      <Box style={{ backgroundColor: formatBackground(row, headCell.backgroundColor), padding: headCell.backgroundColor ? 5 : 0, borderRadius: 3 }}>
                      {
                        headCell.format !== 'image' ? 
                          headCell.id.includes('.') 
                          ? formatString(row[headCell.id.split('.')[0]][headCell.id.split('.')[1]], headCell.format ?? '', headCell.convert ?? {})
                          : formatString(row[headCell.id], headCell.format ?? '', headCell.convert ?? {}) === 'X' ? <CheckIcon className={classes.tableIcon} /> : formatString(row[headCell.id], headCell.format ?? '', headCell.convert ?? {})
                        : 
                          <img src={row[headCell.id] + '?not-from-cache-please'} className={classes.tableImage} alt="" />
                      }
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </>
        :
        <>
          <TableContainer className={classes.tableContainer}>
          {rows.map((item, index) => (
            <Box className={item.deliveryToStoreStatus !== 'ok' && item.deliveryToStoreJson !== null ? classes.productCardRed : classes.productCard }>
              <Box className={classes.productCardImage}>
                {
                  item.image ?
                    <img src={item.image + '?not-from-cache-please'} className={classes.tableGridImage} alt="produto" />
                  :
                    <span>
                      <NoPhotographyIcon className={classes.tableImageIcon} />
                    </span>
                }
              </Box>
              <Box className={classes.productCardInfo}>
                <Box className={classes.productCardTitle}>{item.title}</Box>
              </Box>
              <Box className={classes.productCardIcons}>
                {item.deliveryToStoreJson !== null && item.deliveryToStoreStatus !== 'ok'
                  ? <Tooltip title={<span>Erro ao enviar para loja. {statusDescription(item.deliveryToStoreStatus, '')}</span>}><ReportOffIcon className={classes.tableIconRed} /></Tooltip>
                  : null
                }
                {item.deliveryToStoreStatus === 'ok'
                  ? <Tooltip title="Produto enviado com sucesso para a loja"><CheckIcon className={classes.tableIconGreen} /></Tooltip>
                  : null
                }
                {editIcon !== 'visibility' 
                  ? <Tooltip title="Editar"><EditIcon className={classes.tableIcon} onClick={() => history.push(`${editRoute}/${item.id}`)} /></Tooltip>
                  : <Tooltip title="Visualizar"><VisibilityIcon className={classes.tableIcon} onClick={() => history.push(`${editRoute}/${item.id}`)} /></Tooltip>
                }
                <Tooltip title="Link original"><LinkIcon className={classes.tableIcon} onClick={() => window.open(item.productUrl, '_blank')} /></Tooltip>
                <Tooltip title={<div style={{padding: 10}}><p><b>Título:</b> {item.title}</p><p><b>Original:</b> {item.productDescription}</p>{(item.property1Titles.split(';').length * item.property2Titles.split(';').length) > 100 ? <p><b><span style={{color: '#FCB0B2'}}>ATENÇÃO</span>: </b>Shopify só aceita até 100 variações do produto. Esse produto tem {(item.property1Titles.split(';').length * item.property2Titles.split(';').length)} variações e não será criado na loja. Confira a informação <a href="https://help.shopify.com/pt-BR/en/manual/products/variants/add-variants#part-fe59d51c540b2cb2" target="_blank" style={{color: 'white'}}>aqui</a>!</p> : null}</div>}>
                  <InfoIcon className={(item.property1Titles.split(';').length * item.property2Titles.split(';').length) > 100 ? classes.tableIconRed : classes.tableIcon} />
                </Tooltip>
              </Box>
            </Box>
          ))}
          </TableContainer>
        </>
      }
        <TablePagination
          rowsPerPageOptions={[100, 500, 1000]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) => `Página ${page + 1}, ${from}-${to} de ${count}`}
          sx={{
            '.MuiSvgIcon-root': {
              position: 'relative'
            }
          }}
        />
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Exclusão de Registro</DialogTitle>

        <DialogContent style={{ width: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente excluir o registro?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">Não</Button>
          <Button onClick={handleCloseDelete} variant="contained">Sim</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { CustomTable }
