import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withStyles } from '@mui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, FormLabel, Typography, Skeleton } from '@mui/material'
import { FormGroup, FormControl, Stack, Chip, Divider, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import LinkIcon from '@mui/icons-material/Link'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IFoundProductDTO } from 'data/dtos/automation/i-found-product-dto'
import { IStoreDTO } from 'data/dtos/store/i-store-dto'
import StarRateIcon from '@mui/icons-material/StarRate'


interface IRouteParams {
  id: string
}

interface IFeedback {
  stars: number
}

const numberFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const renderStars = (quantity: number, classes: any) => {
    return (   
      <>                      
        <StarRateIcon className={quantity >= 1 ? classes.starOn : classes.starOff} />
        <StarRateIcon className={quantity >= 2 ? classes.starOn : classes.starOff} />
        <StarRateIcon className={quantity >= 3 ? classes.starOn : classes.starOff} />
        <StarRateIcon className={quantity >= 4 ? classes.starOn : classes.starOff} />
        <StarRateIcon className={quantity >= 5 ? classes.starOn : classes.starOff} />
      </> 
    )
}

const renderImages = (images: [], classes: any) => {
  if (images.length > 0) {
    return ( 
      images.map(image => (
        <img src={image} alt="imagem" className={classes.productImage} />
      ))  
    )
  }
}

//
// adjustHtml
//
const adjustHtml = (store: IStoreDTO, html: string) => {
  if (store === null) {
    return html
  }

  let targetLink = ''
  let output = html

  const imageLinks = [
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-amarelo.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-verde.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-azul.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-cinza.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-rosa.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-lilaz.png',
    'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-roxo.png'
  ]

  let image = ''
  switch (store.productChartSize) {
    case 'from_flash_drop_yellow':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-amarelo.png'
      break    
    
    case 'from_flash_drop_green':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-verde.png'
      break  
    
    case 'from_flash_drop_blue':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-azul.png'
      break  
    
    case 'from_flash_drop_grey':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-cinza.png'
      break  
      
    case 'from_flash_drop_red':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-rosa.png'
      break    
    
    case 'from_flash_drop_lilac':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-lilaz.png'
      break    
    
    case 'from_flash_drop_purple':
      image = 'https://flashdrop.nyc3.cdn.digitaloceanspaces.com/medidas-completo-roxo.png'
      break     
  }

  imageLinks.forEach(imageLink => {
    if (html.includes(imageLink)) {
      targetLink = imageLink
    }
  })

  if (targetLink !== '') {
    output = output.replaceAll(targetLink, image)
  }

  return output
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer')
}

const FoundProductForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [productId, setProductId] = useState('')
  const [productTitle, setProductTitle] = useState('')
  const [property1Name, setProperty1Name] = useState('')
  const [property1Values, setProperty1Values] = useState([])
  const [property2Name, setProperty2Name] = useState('')
  const [property2Values, setProperty2Values] = useState([])
  const [productHtmlToRender, setProductHtmlToRender] = useState('')
  const [originalLink, setOriginalLink] = useState('')
  const [productImages, setProductImages] = useState([])
  const [productVariants, setProductVariants] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentStore, setCurrentStore] = useState(null)
  const [productFeedbacks, setProductFeedbacks] = useState([])
  const [highPrices, setHighPrices] = useState(false)
  const [storeCountry, setStoreCountry] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    productId: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
    control
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      productId: '',
      productDescription: '',
      maxPrice: 0,
      freight: 0,
      baseCurrencyCode: '',
      currencyCode: '',
      currencyRate: 0,
      tradeQuantity: '',
      totalReviews: 0,
      productDescriptionUrl: '',
      property1Name: '',
      property1Titles: '',
      property1Photos: '',
      property2Name: '',
      property2Titles: '',
      property2SizeChart: '',
      images: '',
      storeId: '',
      storeName: '',
      countryName: '',
      openDate: '',
      openYears: '',
      feedbackRating: '',
      feedbackRatingCount: '',
      prices: '',
      props: '',
      discarded: false,
      productSearchId: '',
      productTitle: '',
      productHtml: '',
      productTitleOptionId: '',
      productDescriptionOptionId: ''
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
      const user = localStorage.getItem('@onemandrop:user')
      const tempCurrentStore = JSON.parse(user).currentStore

      const store = await api.get(`/stores/${tempCurrentStore}`)
      
      setCurrentStore(store.data.data)
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      setLoading(true)

      // form data

      await api
        .get(`/found-products/${id}`)
        .then(response => {
          const { data } = response.data

          const productJson = JSON.parse(data.exportJson)

          let bodyHtml = ''
          let productTitle = ''
          let productProperty1Name = ''
          let productProperty1Values = []
          let productProperty2Name = ''
          let productProperty2Values = []
          let productVariants = []

          if (productJson !== null) {
            //bodyHtml = adjustHtml(currentStore, productJson.body_html)
            bodyHtml = productJson.body_html
            productTitle = productJson.title

            if (productJson.options.length > 0) {
              productProperty1Name = productJson.options[0].name
              productProperty1Values = productJson.options[0].values
            }

            if (productJson.options.length > 1) {
              productProperty2Name = productJson.options[1].name
              productProperty2Values = productJson.options[1].values
            }

            productVariants = productJson.variants

            //setProductHtmlToRender(adjustHtml(currentStore, productJson.body_html))
            setProductHtmlToRender(productJson.body_html)
          } else {
            productTitle = data.productDescription
            setProductHtmlToRender('')
          }

          const hasHighPrice = productVariants.some((producVariant: { price: number }): boolean => {
            if (producVariant.price >= 999) {
              return true
            }
          })

          setHighPrices(hasHighPrice)
          setStoreCountry(data.countryName)

          const foundProductResult = {
            productId: data.productId,
            productDescription: data.productDescription,
            maxPrice: data.maxPrice,
            freight: data.freight,
            baseCurrencyCode: data.baseCurrencyCode,
            currencyCode: data.currencyCode,
            currencyRate: data.currencyRate,
            tradeQuantity: data.tradeQuantity,
            totalReviews: data.totalReviews,
            productDescriptionUrl: data.productDescriptionUrl,
            property1Name: data.property1Name,
            property1Titles: data.property1Titles,
            property1Photos: data.property1Photos,
            property2Name: data.property2Name,
            property2Titles: data.property2Titles,
            property2SizeChart: data.property2SizeChart,
            images: data.images,
            storeId: data.storeId,
            storeName: data.storeName,
            countryName: data.countryName,
            openDate: data.openDate,
            openYears: data.openYears,
            feedbackRating: data.feedbackRating,
            feedbackRatingCount: data.feedbackRatingCount,
            prices: data.prices,
            props: data.props,
            discarded: data.discarded,
            chatgptTitleOptions: data.chatgptTitleOptions,
            chatgptDescriptionOptions: data.chatgptDescriptionOptions,
            chatgptCharacteristicsOptions: data.chatgptCharacteristicsOptions,
            exportJson: data.exportJson,
            feedbacksJson: data.feedbacksJson,
            productSearchId: data.productSearchId,
            productTitle: productTitle,
            productHtml: bodyHtml,
            productProperty1Name: productProperty1Name,
            productProperty1Values: productProperty1Values,
            productProperty2Name: productProperty2Name,
            productProperty2Values: productProperty2Values,
            productVariants: productVariants
          }

          return foundProductResult
        })
        .then((foundProductResult: any) => {
          const tempProductImages = foundProductResult.images.split(';')
          const tempVariantImages = foundProductResult.property1Photos.split(';')
          tempProductImages.push(...tempVariantImages)

          // console.log('>>> foundProductResult:', foundProductResult.images)
          // console.log('>>> tempProductImages:', tempProductImages)

          //const tempProductVariants = addProductVariantImage(foundProductResult.productVariants, tempVariantImages)
          const tempProductVariants = foundProductResult.productVariants

          let feedbacks = []

          feedbacks = JSON.parse(foundProductResult.feedbacksJson)

          // if ((foundProductResult.feedbacksJson !== '') && (foundProductResult.feedbacksJson !== null)) {
          //   feedbacks = JSON.parse(foundProductResult.feedbacksJson)
          //   feedbacks.sort((a: any, b: any) => b.stars - a.stars)
          // }

          const originalLink = foundProductResult.productSearchId.productUrl

          setProductId(foundProductResult.productId)
          setProductImages(tempProductImages)
          setProductTitle(foundProductResult.productTitle)
          setProductVariants(tempProductVariants)
          setProperty1Name(foundProductResult.productProperty1Name)
          setProperty1Values(foundProductResult.productProperty1Values)
          setProperty2Name(foundProductResult.property2Name)
          setProperty2Values(foundProductResult.productProperty2Values)
          setProductFeedbacks(feedbacks)
          setOriginalLink(originalLink)

          reset(foundProductResult)

          setLoading(false)

          return () => {}
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id && currentStore) {
      loadData()
    }
  }, [currentStore, params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IFoundProductDTO) => {
    const payLoad: IFoundProductDTO = {
      productId: data.productId,
      productDescription: data.productDescription,
      maxPrice: data.maxPrice,
      freight: data.freight,
      baseCurrencyCode: data.baseCurrencyCode,
      currencyCode: data.currencyCode,
      currencyRate: data.currencyRate,
      tradeQuantity: data.tradeQuantity,
      totalReviews: data.totalReviews,
      productDescriptionUrl: data.productDescriptionUrl,
      property1Name: data.property1Name,
      property1Titles: data.property1Titles,
      property1Photos: data.property1Photos,
      property2Name: data.property2Name,
      property2Titles: data.property2Titles,
      property2SizeChart: data.property2SizeChart,
      images: data.images,
      storeId: data.storeId,
      storeName: data.storeName,
      countryName: data.countryName,
      openDate: data.openDate,
      openYears: data.openYears,
      feedbackRating: data.feedbackRating,
      feedbackRatingCount: data.feedbackRatingCount,
      prices: data.prices,
      props: data.props,
      discarded: data.discarded,
      chatgptTitleOptions: data.chatgptTitleOptions,
      chatgptDescriptionOptions: data.chatgptDescriptionOptions,
      chatgptCharacteristicsOptions: data.chatgptCharacteristicsOptions,
      exportJson: data.exportJson,
      productSearchId: data.productSearchId,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/new-products`, payLoad)
        .then(history.push('/new-products'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/new-products', payLoad)
        .then(history.push('/new-products/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }


  //
  // calculateAliExpressDiscount
  //
  const calculateAliExpressDiscount = (price: number) => {
    const discountPriceLimit1 = currentStore.discountPriceLimit1
    const discountPercentageLimit1 = currentStore.discountPercentageLimit1
    const discountPriceLimit2 = currentStore.discountPriceLimit2
    const discountPercentageLimit2 = currentStore.discountPercentageLimit2
    const discountPriceLimit3 = currentStore.discountPriceLimit3
    const discountPercentageLimit3 = currentStore.discountPercentageLimit3
    const discountPercentageLimit4 = currentStore.discountPercentageLimit4

    let discountPercentage = 0

    if (price <= discountPriceLimit1) {
      discountPercentage = discountPercentageLimit1
    } else if (price <= discountPriceLimit2) {
      discountPercentage = discountPercentageLimit2
    } else if (price <= discountPriceLimit3) {
      discountPercentage = discountPercentageLimit3
    } else {
      discountPercentage = discountPercentageLimit4
    }

    if (discountPercentage == 0) {
      return price
    } else {
      return price * ((100 - discountPercentage) / 100)
    }
  }


  //
  // calculateAliExpressTaxes
  //
  const calculateAliExpressTaxes = (price: number) => {
    if (storeCountry.toLowerCase() !== 'brazil') {
      const ptax = 5.20370
      const taxesUpTo50DolarsPercentage = 18.0
      const taxesHigherThan50DolarsPercentage = 92.0
      const dolarPrice = price / ptax

      if (dolarPrice <= 50) {
        return price * (taxesUpTo50DolarsPercentage / 100)
      } else {
        return price * (taxesHigherThan50DolarsPercentage / 100)
      }
    } else {
      return 0
    }
  }


  const calculateDiscount = (price: number, discountPercentage: number) => {
    return numberFormatter.format(calculateAliExpressDiscount(price))
  }

  const calculateFreight = (freight: number) => {
    return numberFormatter.format(freight)
  }

  const calculateTaxes = (price: number, freight: number) => {
    return numberFormatter.format(calculateAliExpressTaxes(calculateAliExpressDiscount(price) + freight))
  }

  const calculateCost = (price: number, freight: number) => {
    return numberFormatter.format(calculateAliExpressDiscount(price) + freight + calculateAliExpressTaxes(price))
  }

  const calculatePrice = (price: number, freight: number) => {
    const cost = calculateAliExpressDiscount(price) + freight + calculateAliExpressTaxes(price)

    let markup = 1
    if (currentStore.productPriceMarkup > 0) {
      markup = currentStore.productPriceMarkup
    }

    let priceWithMarkup = cost * markup
    if (currentStore.productPriceDecimals === 0) {
      priceWithMarkup = Math.round(priceWithMarkup)
    } else {
      priceWithMarkup = Math.round(priceWithMarkup) + parseFloat((currentStore.productPriceDecimals % 1) ? (currentStore.productPriceDecimals % 1).toString().replace(',', '.') : '0.00')
    }

    return numberFormatter.format(priceWithMarkup)
  }


  const showVariation = (option1: string, option2: string) => {
    let output = '' 

    if ((option1 === '' || option1 === undefined) && (option2 === '' || option2 === undefined)) {
      output = 'Peça única'
    } else {
      if (option2 === '' || option2 === undefined) {
        output = option1
      } else {
        if (option2.toLowerCase() !== 'china') {
          output = option1 + ' / ' + option2
        } else {
          if (option1.toLowerCase() !== 'china') {
            output = option1
          } else {
            output = option2
          }
        }
      }
    }

    return output
  }


  const downloadLandingPage = async () => {
    const { id } = params

    await api.get(`/products/download-landing-page/${id}`, 
      { 
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/zip'
        } 
      }
    ).then(response => {
      // console.log('>>> response:', response)
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type })
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `html_page_${productId}.zip`)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
  }

  
  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Novos Produtos"
          icon={ListIcon}
          backRoute="/new-products"
          helpText="Aqui são informados dados sobre novos produtos."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{width: '800px',display: 'flex', justifyContent: 'center'}}>
            <Box className={classes.productPageContainer}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className={classes.iconsContainer}>
                  <div onClick={() => downloadLandingPage()} className={classes.originalLink}>
                    <Tooltip title="Download da página">
                      <CloudDownloadIcon />
                    </Tooltip>
                  </div>

                  <div onClick={() => openInNewTab(originalLink)} className={classes.originalLink}>
                    <Tooltip title="Link original">
                      <LinkIcon />
                    </Tooltip>
                  </div>
                </Box>

                <FormControl className={classes.fieldSet} >
                  <FormLabel className={classes.fieldSetLabel} style={{ maxWidth: '120px'}}>
                    <div style={{ paddingLeft: 10 }}>Título do Produto</div>
                  </FormLabel>
                  <FormGroup row style={{paddingLeft: 20, paddingRight: 20}}>
                    { loading ? (
                      <Skeleton animation="wave" variant="rectangular" width={'100%'} height={25} />
                    ) : (
                      <div>{productTitle}</div>
                    )}
                  </FormGroup>
                </FormControl>

                <FormControl className={classes.fieldSet} >
                  <FormLabel className={classes.fieldSetLabel} style={{ maxWidth: '50px'}}>
                    <div style={{ paddingLeft: 10 }}>Html</div>
                  </FormLabel>
                  <FormGroup row style={{paddingLeft: 20, paddingRight: 20}}>
                    { loading ? (
                      <Skeleton animation="wave" variant="rectangular" width={'100%'} height={150} />
                    ) : (
                      <div dangerouslySetInnerHTML={{__html: productHtmlToRender + currentStore.productComplementHtml}} />
                    )}
                  </FormGroup>
                </FormControl>

                <FormControl className={classes.fieldSet} style={{ marginTop: '20px !important' }}>
                  <FormLabel className={classes.fieldSetLabel} style={{ maxWidth: '50px'}}>
                    <div style={{ paddingLeft: 10 }}>Mídia</div>
                  </FormLabel>
                  <Grid container spacing={0} style={{padding: '15px 20px 20px 20px'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      { loading ? (
                        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
                      ) : (
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.mainMidiaContainer} >
                              <img src={productImages[0]} className={classes.mainMidia} alt="midia" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Grid container spacing={1}>
                                {productImages.map((productImage, index) => ( index > 0 && index < 5 ?
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                                  <Box className={classes.midiaContainer}>
                                    <img src={productImage} alt="midia" className={classes.midia} />
                                  </Box>
                                </Grid>
                                : null
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={1}>
                              {productImages.map((productImage, index) => ( index >= 5 ?
                              productImage ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                                <Box className={classes.midiaContainer}>
                                  <img src={productImage} alt="midia" className={classes.midia} />
                                </Box>
                              </Grid>
                              : null
                              : null
                              ))}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>

                <FormControl className={classes.fieldSet} >
                  <FormLabel className={classes.fieldSetLabel} style={{ maxWidth: '75px'}}>
                    <div style={{ paddingLeft: 10 }}>Variantes</div>
                  </FormLabel>
                  <Box>
                    <Grid container spacing={0} style={{paddingLeft: 20, paddingRight: 20}}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                          <Typography style={{ fontSize: '100%', margin: '10px 0 5px 0' }}>{property1Name}</Typography>
                          { loading ? (
                            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={60} />
                          ) : (
                            <>
                              <Stack direction="row" flexWrap="wrap" className={classes.chipContainer}>
                              {property1Values.map((property1Value, index) => (
                                <Chip label={property1Value} size="small" className={classes.chip} key={index} />
                              ))}
                              </Stack>
                            </>
                          )}
                        </FormGroup>
                      </Grid>
                    </Grid>

                    {property2Name !== '' ?
                    <>
                      <Divider />

                      <FormGroup style={{ marginBottom: 10, paddingLeft: 20, paddingRight: 20 }}>
                        <Typography style={{ fontSize: '100%', margin: '10px 0 5px 0' }}>{property2Name}</Typography>
                          { loading ? (
                            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={60} />
                          ) : (
                            <>
                              <Stack direction="row" flexWrap="wrap" className={classes.chipContainer} >
                              {property2Values.map((property2Value, index) => (
                                <Chip label={property2Value} size="small" className={classes.chip} key={index} />
                              ))}
                              </Stack>
                            </>
                          )}
                      </FormGroup>
                    </>
                    : null }

                    <Divider />
                    
                    <Grid container spacing={0}>
                      <Grid container spacing={0} className={highPrices ? classes.thumbnailLineHeaderHighPrices : classes.thumbnailLineHeader}>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ paddingLeft: 5 }}>Imagem</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>Variante</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>Preço</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>Líquido</Grid>
                        <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{ textAlign: 'right' }}>Frete</Grid>
                        <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{ textAlign: 'right' }}>Taxas</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>Custo</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>Final (R$)</Grid>
                      </Grid>
                    {productVariants.map((producVariant, key) => (
                      <Grid container spacing={0} className={highPrices ? classes.thumbnailLineHighPrices : classes.thumbnailLine} key={key}>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                          {
                            producVariant.thumbnail ?
                              <img src={producVariant.thumbnail + '?not-from-cache-please'} className={classes.thumbnail} alt="Variação" />
                            :
                              <span>
                                <NoPhotographyIcon className={classes.thumbnail} />
                              </span>
                          }
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>{ showVariation(producVariant.option1, producVariant.option2) }</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>{ numberFormatter.format(producVariant.price) }</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={1} style={{ textAlign: 'right' }}>{ calculateDiscount(producVariant.price, currentStore.productTaxesPercentage) }</Grid>
                        <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{ textAlign: 'right' }}>{ calculateFreight(producVariant.freight) }</Grid>
                        <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{ textAlign: 'right' }}>{ calculateTaxes(producVariant.price, producVariant.freight) }</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>{ calculateCost(producVariant.price, producVariant.freight) }</Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>{ calculatePrice(producVariant.price, producVariant.freight) }</Grid>
                      </Grid>
                    ))}
                    </Grid>
                  </Box>
                </FormControl>

                <Box>
                  <Typography variant="caption" display="block" gutterBottom style={{marginTop: '15px', marginBottom: '15px',color: 'black'}}>
                    <strong>IMPORTANTE: </strong>Os valores dos preços são capturados a partir da página do produto no site AliExpress. O preço que tomamos como base é o preço sem descontos. Tentamos conseguir o valor mais próximo possível do exibido, porém ele pode variar do que você vê no site, dependendo de diversos fatores incluindo: taxas de conversão, se você está logado no site AliExpress ou não, cookies anteriores etc, portanto, sempre verifique os valores originais antes de assumir os valores para sua página. Não nos responsabilizamos por possíveis perdas advindas do uso dos valores dessa página. 
                  </Typography>
                </Box>
{/*
                <FormControl className={classes.fieldSet} >
                  <FormLabel className={classes.fieldSetLabel} style={{ maxWidth: '80px'}}>
                    <div style={{ paddingLeft: 10 }}>Avaliações</div>
                  </FormLabel>
                  <Box className={classes.reviewsContainer}>
                    {productFeedbacks.map((feedback, index) => (
                      reviewsFilter(feedback) ?
                      <Box className={classes.reviewContainer} key={index}>
                        <Box className={classes.starsContainer}>
                          {renderStars(feedback.stars, classes)}
                        </Box>
                        <p className={classes.reviewsTextContainer}>Usuário: {feedback.user_name}</p>
                        <p className={classes.reviewsTextContainer}>Em: {feedback.date_time}</p>
                        <p className={classes.reviewsTextContainer}>{feedback.feedback_in_portuguese}</p>
                        { renderImages(feedback.images, classes)}
                      </Box>
                      : null
                    ))}
                  </Box>
                </FormControl>
*/}
              </Grid>
            </Box>
          </Grid>
          
        </Grid>
      </Box>
    </Paper>
  )
}

export default FoundProductForm
