import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  container: {
    minHeight: 0,
    maxHeight: 'calc(100vh - 320px)',
  },

  paper: {
    padding: '20px',
    minHeight: 'calc(100vh - 85px)',
    maxHeight: 'calc(100vh - 85px)',
    height: 'calc(100vh - 85px)',
    width: '100%',
    marginBottom: '5px'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  formContainer: {
    margin: '12px 20px 10px 0px',
    width: '100%',
    maxHeight: 'calc(100vh - 190px)',
    overflow: 'hidden'
  },

  formContainerWithError: {
    margin: '12px 20px 10px 0px',
    width: '100%',
    maxHeight: 'calc(100vh - 252px)',
    overflow: 'hidden'
  },

  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  iconHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 1,
    minWidth: 1,
    maxWidth: 1,
  },

  tableIcon: {
  	margin: '-5px',
  	padding: '-5px',
  	lineHeight: 0,
  	fontSize: 20,
  	cursor: 'pointer',
  	color: theme.palette.primary.main,
  },

  actionButton: {
    marginLeft: '10px !important',
  },
  
  submitButton: {
    marginTop: 20,
    justifyContent: 'flex-end',
  },

  checkBox: {
    marginTop: 4,
  },

  fieldSet: {
    width: '98%',
    marginTop: '6px !important',
    marginBottom: '10px !important',
    padding: '20px 0px 8px 0px !important',
    border: '1px solid #ddd !important',
    borderRadius: 5,
    fontSize: '90% !important',

    '& h2': {
      fontSize: '125% !important'
    }
  },

  fieldSetLabel: {
    marginTop: -30,
    marginLeft: 9,
    backgroundColor: '#FFF !important',
    maxWidth: 0,
    fontSize: '90% !important'
  },

  mainMidiaContainer: {
    width: '289px',
    height: '289px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    margin: '8px 0 0 0 !important'
  },

  mainMidia: {
    objectFit: 'cover',
    objectPosition: 'top', 
    width: '345px',
    height: '345px',
    borderRadius: 7,
    border : '1px solid #fafafa'
  },

  midiaContainer: {
    width: '170px',
    height: '170px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    margin: '0px 0px 0px 0px'
  },

  midia: {
    objectFit: 'cover',
    objectPosition: 'top', 
    width: '170px',
    height: '170px',
    borderRadius: 7,
    border : '1px solid #fafafa'
  },

  thumbnail: {
    width: 40,
    height: 40,
    borderRadius: 5,
    border: '1px solid #ddd',
    margin: '10px !important',
    marginTop: '17px !important'
  },

  thumbnailLine: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    margin: 0,
    borderBottom: '1px solid #ddd',
    padding: '0px 20px 0px 20px',
    fontSize: '87%',

    '&:last-child': { 
      borderBottom: 'none'
    }
  },

  thumbnailLineHighPrices: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    margin: 0,
    borderBottom: '1px solid #ddd',
    padding: '0px 20px 0px 20px',
    fontSize: '77%',

    '&:last-child': { 
      borderBottom: 'none'
    }
  },

  thumbnailLineHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    margin: 0,
    borderBottom: '1px solid #ddd',
    padding: '10px 20px 10px 20px',
    backgroundColor: '#ebebeb',
    fontWeight: 'bold',
    color: '#6278E7',
    fontSize: '90%'
  },

  thumbnailLineHeaderHighPrices: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
    margin: 0,
    borderBottom: '1px solid #ddd',
    padding: '10px 20px 10px 20px',
    backgroundColor: '#ebebeb',
    fontWeight: 'bold',
    color: '#6278E7',
    fontSize: '80%',
  },

  productPageContainer: {
    minHeight: 'calc(100vh - 205px)',
    maxHeight: 'calc(100vh - 205px)',
    height: 'calc(100vh - 205px)',
    overflow: 'overlay',
    paddingBottom: '15px',
    minWidth: '800px',
    maxWidth: '800px',
    width: '800px',
    border: '1px solid #ddd',
    padding: '20px 10px 20px 25px',
    borderRadius: 7
  },
  
  midiaCheckbox: {
    position: 'absolute',
    top: '40px',
    left: '0px',
  },

  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: 10
  },

  chip: {
    marginRight: 6, 
    marginBottom: 6, 
    paddingLeft: 4, 
    paddingRight: 4,
    paddingTop: 2
  },

  starOn: {
    color: '#FFC62D'
  },

  starOff: {
    color: '#DDDDDD'
  },

  starsContainer: {
    padding: 0,
    margin: 0
  },

  reviewsContainer: {
    padding: 0
  },

  reviewContainer: {
    padding: '15px 15px 20px 25px',
    borderBottom: '1px solid #ddd',
    
    '&:last-child': { 
      borderBottom: 'none'
    }
  },

  reviewsTextContainer: {
    padding: 0,
    margin: 0
  },

  productImage: {
    marginTop: 10,
    height: 'auto',
    width: '200px'
  },

  originalLink: {
    display: 'flex',
    textDecoration: 'underline',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: 10,
    marginRight: 11,
    fontSize: '90%',
    color: '#6278E7',
    cursor: 'pointer'
  },

  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flexDirection: 'row',
    rowHeight: 'auto',
  }
}))

export { useStyles }
