import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { ISupportTicketDTO } from 'data/dtos/support/i-support-ticket-dto'

interface IRouteParams {
  id: string
}

const SupportTicketForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    type: yup.string()
      .required('Campo obrigatório'),
    title: yup.string()
      .required('Campo obrigatório'),
    description: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<ISupportTicketDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      openedAt: null,
      name: '',
      email: '',
      whatsapp: '',
      type: '',
      title: '',
      status: '',
      history: '',
      closedAt: null,
      rating: 0,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/support-tickets/${id}`)
        .then(response => {
          const { data } = response.data

          const supportTicketResult = {
            openedAt: data.openedAt,
            name: data.name,
            email: data.email,
            whatsapp: data.whatsapp,
            type: data.type,
            title: data.title,
            description: data.description,
            status: data.status,
            history: data.history,
            closedAt: data.closedAt,
            rating: data.rating,
          }

          return supportTicketResult
        })
        .then((supportTicketResult: ISupportTicketDTO) => {
          reset(supportTicketResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: ISupportTicketDTO) => {
    const payLoad: ISupportTicketDTO = {
      openedAt: new Date(),
      name: data.name,
      email: data.email,
      whatsapp: data.whatsapp,
      type: data.type,
      title: data.title,
      description: data.description,
      status: data.status,
      history: data.history,
      closedAt: data.closedAt,
      rating: data.rating,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/support-tickets`, payLoad)
        .then(history.push('/support-tickets'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      payLoad.status = 'opened'
      
      await api
        .post('/support-tickets', payLoad)
        .then(history.push('/support-tickets/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Solicitações de Suporte"
          icon={ListIcon}
          backRoute="/support-tickets"
          showSaveButton={params.id ? false : true}
          helpText="Aqui são informados dados sobre os tickets de suporte."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="name"
              label="Nome"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50,
                readOnly: params.id ? true : false
              }}
              {...register("name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="email"
              label="Email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50,
                readOnly: params.id ? true : false
              }}
              {...register("email",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="whatsapp"
              label="WhatsApp"
              error={!!errors.whatsapp}
              helperText={errors?.whatsapp?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20,
                readOnly: params.id ? true : false
              }}
              {...register("whatsapp",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="type"
              name="type"
              label="Tipo"
              error={!!errors.type}
              helperText={errors?.type?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().type}`}
              inputProps={{
                readOnly: params.id ? true : false
              }}
              select
              {...register("type", { onChange: (e) => {
                setValue("type", e.target.value)
                handleChange(e)
              }})}
            >
              <MenuItem key="subscription" value="subscription">Assinatura</MenuItem>
              <MenuItem key="financial" value="financial">Financeiro</MenuItem>
              <MenuItem key="technical" value="technical">Técnico</MenuItem>
              <MenuItem key="complaint" value="complaint">Reclamação</MenuItem>
              <MenuItem key="suggestion" value="suggestion">Sugestão</MenuItem>
              <MenuItem key="other" value="other">Outro</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="title"
              label="Título"
              error={!!errors.title}
              helperText={errors?.title?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50,
                readOnly: params.id ? true : false
              }}
              {...register("title",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="description" style={{ marginTop: '10px' }}>Descrição</InputLabel>
                <TextareaAutosize
                  readOnly={params.id ? true : false}
                  id="description"
                  minRows={10}
                  maxRows={10}
                  {...register("description", {
                    onChange: (e) => {
                      setValue("description", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="description"
            control={control}
          />

          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default SupportTicketForm
