import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { ICollectionDTO } from 'data/dtos/store/i-collection-dto'

interface IRouteParams {
  id: string
}

const CollectionForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [storesA, setStoresA] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    storeId: yup.string()
      .required('Campo obrigatório'),
    collectionName: yup.string()
      .required('Campo obrigatório'),
    collectionHandle: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<ICollectionDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      storeId: '',
      collectionId: '',
      collectionName: '',
      collectionHandle: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Loja

      await api
        .post('/stores/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((storesResult) => {
          setStoresA(storesResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/collections/${id}`)
        .then(response => {
          const { data } = response.data

          const collectionResult = {
            storeId: data.storeId.id,
            collectionId: data.collectionId,
            collectionName: data.collectionName,
            collectionHandle: data.collectionHandle,
          }

          return collectionResult
        })
        .then((collectionResult: ICollectionDTO) => {
          reset(collectionResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: ICollectionDTO) => {
    const payLoad: ICollectionDTO = {
      storeId: data.storeId,
      collectionId: data.collectionId,
      collectionName: data.collectionName,
      collectionHandle: data.collectionHandle,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/collections`, payLoad)
        .then(history.push('/collections'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/collections', payLoad)
        .then(history.push('/collections/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Coleções"
          icon={ListIcon}
          backRoute="/collections"
          showSaveButton={true}
          helpText="Aqui são informados os dados sobre as coleções de produtos da loja."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="storeId"
              label="Loja"
              error={!!errors.storeId}
              helperText={errors?.storeId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().storeId}`}
              select
              autoFocus
              inputRef={firstInputElement}
              {...register("storeId", { onChange: (e) => {
                setValue("storeId", e.target.value)
                handleChange(e)
              }})}
            >
            {storesA.map((store) => (
              <MenuItem
                key={store.id}
                value={store.id}
              >
                {store.storeName}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="collectionId"
              label="Código"
              error={!!errors.collectionId}
              helperText={errors?.collectionId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("collectionId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="collectionName"
              label="Nome"
              error={!!errors.collectionName}
              helperText={errors?.collectionName?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("collectionName",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="collectionHandle"
              label="Código"
              error={!!errors.collectionHandle}
              helperText={errors?.collectionHandle?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("collectionHandle",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default CollectionForm
