import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import { useStyles } from './styles'
import logoImage from 'assets/logo-main.svg'
import LoginFooter from 'components/login-footer'

const PrivacyPolicies: React.FC = () => {
  const history = useHistory()
	const classes = useStyles()

	return (
		<>
		<Box  className={classes.header}>
			<Box className={classes.headerPaperWrapper}>
				<a href="/home"onClick={() => history.push('/home')}><img src={logoImage} alt="logo" className={classes.logoImage} /></a>
			</Box>
		</Box>

		<Box className={classes.container}>
			<Box className={classes.mainWrapper}>
				<Box className={classes.paperWrapper}>
					<h1>Políticas de Privacidade</h1>
					<h4>Em vigor a partir de 01/03/2024. Última alteração em 01/03/2024.</h4>
					<p>Nós da <strong>CLICKMVP SUPORTE TÉCNICO LTDA - CNPJ: 37.236.037/0001-23 (ClickMVP)</strong>, empresa proprietária, desenvolvedora e operadora da plataforma SAAS <strong>OneManDrop</strong>, nos preocupamos com a preservação da privacidade de nossos clientes, usuários, destinatários, candidatos, empregados, assim como quaisquer outros indivíduos que interajam com a nossa plataforma.</p>
					<p>Em um mundo globalizado, reforçamos nosso comprometimento com os mais altos padrões éticos, comprometendo-nos com a proteção dos dados pessoais, em respeito à legislação pertinente.</p>
					<p>Assim sendo, optamos por inserir nesta política determinados conceitos vinculados à privacidade, muito importantes para que você possa analisar adequadamente e tomar a decisão adequada acerca do compartilhamento de seus dados com a nossa empresa.</p>

					<h3>Afinal, o que são dados pessoais?</h3>
					<p>Dados pessoais são informações que podem identificar uma pessoa física ou permitir que ela seja identificável, o que significa dizer que, ainda que determinadas informações não identifiquem a pessoa de imediato, o simples fato de permitir que tais dados sejam cruzados com outros e, a partir daí, possam identificá-la, indicam que as obrigações vinculadas à privacidade deverão ser observadas</p>
					<p>Como se vê, a Lei entende que dados pessoais são aqueles que possam identificar o titular direta ou indiretamente.</p>
					<p>Como exemplos, podemos mencionar o nome, RG, CPF, e-mail, cookies, cujo conceito está identificado neste documento.</p>
					
					<h3>Quais são os dados pessoais que coletamos?</h3>
					<p>Para atender aos nossos clientes, tratamos os seguintes dados pessoais:</p>

					<ul>
						<li>Nome</li>
						<li>e-mail</li>
						<li>Telefone</li>
						<li>CPF</li>
						<li>Endereço</li>
					</ul>

					<p>A ClickMVP proporciona um serviço de hospedagem aos seus clientes, cabendo a eles a gestão dos dados e informações dos titulares.</p>
					
					<h3>Como nós conseguimos os dados pessoais?</h3>
					<p>Nós utilizamos apenas bases de dados legítimas, o que significa que obedecemos às regras da Lei Geral de Privacidade de Dados (“LGPD”). Para prestar nossos serviços, obtemos dados pessoais, diretamente ou indiretamente, das seguintes formas:</p>
					
					<ul>
						<li>Durante o procedimento e contratação dos nossos planos ou serviços;</li>
						<li>Pelo acesso à plataforma como cliente;</li>
						<li>Durante o acesso à plataforma como usuário, seja como aluno de algum produto EAD ou cliente;</li>
						<li>Por meio do cadastro nos sites da ClickMVP; e</li>
						<li>Através da interação com os canais de atendimento da ClickMVP.</li>
					</ul>

					<h3>Como nós utilizamos os dados pessoais?</h3>
					<p>Nós utilizamos os dados apenas para prestar serviços e conforme a necessidade de nossos clientes, nesse sentido, seus dados podem ser utilizados apenas para:</p>

					<ul>
						<li>
							<p><span>Chatbot</span></p>
							<p>Para operacionalizar chatbots e atendimentos automatizados por eles fornecidos, propiciando uma melhor experiência aos usuários e agilizando o atendimento, conforme a conveniência do titular;</p>
						</li>
						<li>
							<p><span>E-mail marketing</span></p>
							<p>Para a administração, estratégia, organização e envio de mensagens de marketing, conforme as preferências estabelecidas pelos usuários, clientes e patrocinadores.</p>
						</li>
						<li>
							<p><span>Plataforma de cursos</span></p>
							<p>Para hospedar e desenvolver cursos e treinamentos ministrados pelos nossos clientes aos usuários da plataforma.</p>
						</li>
						<li>
							<p><span>Automação de processos para dropshipping</span></p>
							<p>Para a configuração de regras e organização de processos para operação de e-commerces que utilizam a modalidade dropshipping, conforme as diretrizes dos nossos clientes.</p>
						</li>
					</ul>

					<h3>Quais as bases legais para o tratamento de dados?</h3>
					<p>Bases legais, de maneira resumida, são as razões pelas quais os dados pessoais são tratados.</p>
					<p>Assim, em toda e qualquer operação que envolva dados pessoais, utilizamos uma das seguintes bases legais para o seu tratamento:</p>
					
					<ul>
						<li>Consentimento livre e específico do titular dos dados pessoais;</li>
						<li>Cumprimento de obrigação normativa, seja ela legal ou regulatória;</li>
						<li>Execução de contrato em prol do titular dos dados pessoais;</li>
						<li>Exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
						<li>Legítimos interesses do controlador ou de terceiros, sempre respeitados os direitos dos titulares; ou</li>
						<li>Proteção do crédito, conforme a legislação pertinente.</li>
					</ul>

					<p>Inclusive, nas hipóteses em que tratamos os dados pessoais com base em seu consentimento ou no legítimo interesse, todo e qualquer titular pode solicitar que o tratamento seja interrompido, conforme procedimento disposto nessa própria política.</p>
								
					<h3>E os dados pessoais dos candidatos?</h3>
					<p>A fim de atrair os melhores talentos, nós da ClickMVP precisamos tratar os dados pessoais das pessoas que se candidatam às vagas divulgadas.</p>
					<p>Nesse procedimento, nós coletamos os seguintes dados</p>
					
					<ul>
						<li>Nome civil</li>
						<li>Nome social</li>
						<li>Telefone</li>
						<li>País de origem</li>
						<li>CPF</li>
						<li>Qualificação como PCD</li>
						<li>Data de nascimento</li>
						<li>Endereço de residência</li>
						<li>Formação acadêmica</li>
						<li>Experiência profissional</li>
						<li>Forma como encontrou a vaga</li>
						<li>Se foi indicado</li>
					</ul>

					<p>Todos esses dados são tratados com base na execução de procedimento preliminar ao contrato, cumprimento de obrigação legal ou, ainda, no legítimo interesse.</p>
					
					<h3>Iniciativas de diversidade e inclusão</h3>
					<p>Nós da ClickMVP nos preocupamos com a diversidade e a inclusão no ambiente corporativo, para tanto, perguntamos aos nossos candidatos sobre o local de origem, identidade de gênero, orientação sexual e etnia.</p>
					<p>Todas essas perguntas são completamente facultativas e os candidatos podem escolher se desejam responder</p>
					
					<h3>Compartilhamento de dados</h3>
					<p>Podemos compartilhar seus dados nas seguintes hipóteses:</p>

					<ul>
						<li>
							<p><span>Prestadores de Serviços.</span></p>
							<p>Usamos prestadores de serviços terceirizados para nos ajudar a administrar certas atividades e serviços em nosso nome, como a triagem de candidatos, envios de e-mails, mensagens de texto (SMS) e voz, fornecimento de anúncios e rastreamento da eficácia das campanhas de marketing.</p>
						</li>
						<li>
							<p><span>Órgãos e entidades governamentais.</span></p>
							<p>Em certas situações, devemos compartilhar certos dados pessoais para cumprir com a legislação aplicável ou para exercício de direitos em processos judiciais ou administrativos.</p>
						</li>
						<li>
							<p><span>Proteção dos sistemas da ClickMVP.</span></p>
							<p>Precisamos compartilhar dados pessoais, ainda, para proteger os nossos patrocinadores, clientes e usuários, como, por exemplo, para tentativas de fraude em nossos servidores ou para manter a funcionalidade nos nossos sistemas de proteção.</p>
						</li>
					</ul>
								
					<h3>Cookies</h3>
					<p>Para fins de otimizar a experiência do usuário, nós utilizamos Cookies em nossos navegadores.</p>

					<div>
						<div>
							<div>
								<p><strong>O que são cookies?</strong></p>
							</div>
							<div>
								<p>Cookies são pequenos arquivos de texto, os quais são coletados durante o uso dos mais diversos navegadores. Esses registram informações sobre comportamento e preferência no uso na internet, sendo utilizados para personalizar sua experiência.</p>
							</div>
						</div>
						<div>
							<div>
								<p><strong>Por que usamos cookies?</strong></p>
							</div>
							<div>
								<p>Utilizamos cookies para melhor adaptar as plataformas aos seus interesses e necessidades, bem como para coletarmos informações sobre a sua utilização, auxiliando a melhorar sua estrutura e utilização.</p>
							</div>
						</div>
						<div>
							<div>
								<p><strong>Quais os tipos de cookies?</strong></p>
							</div>
							<div>
								<p>A ClickMVP utiliza de sessão, primários, necessários e de terceiros.</p>
							</div>
						</div>
					</div>

					<h3>Como mantemos os dados pessoais seguros?</h3>
					<p>Adotamos medidas técnicas, físicas e organizacionais rigorosas para proteger toda e qualquer informação sob nossa guarda, inclusive os dados pessoais que coletamos, atendendo sempre aos mais altos padrões de segurança.</p>
					<p>No entanto, é importante notar que, embora tenhamos tomado medidas rigorosas para proteger os dados pessoais, nenhum site, produto, dispositivo, aplicação online ou transmissão de dados ou sistema informático é completamente seguro.</p>
					
					<h3>Quais são os seus direitos?</h3>
					<p>A Lei Geral de Proteção de Dados coloca uma série de direitos em favor dos titulares dos dados pessoais, inclusive aqueles que esse pode exercer diretamente em face daqueles que realizam as operações de tratamento.</p>
					<p>Dessa forma, o titular poderá solicitar diretamente quaisquer dos direitos a seguir listados:</p>
								
					<ul>
						<li>confirmação da existência de tratamento;</li>
						<li>acesso aos dados pessoais;</li>
						<li>correção de dados incompletos, inexatos ou desatualizados;</li>
						<li>anonimização, bloqueio ou eliminação de dados desnecessários ou excessivos;</li>
						<li>portabilidade dos dados pessoais, observado os segredos comerciais;</li>
						<li>eliminação dos dados pessoais tratados com o consentimento do titular;</li>
						<li>informação sobre o compartilhamento de dados com entidades públicas ou privadas;</li>
						<li>informação sobre a possibilidade de não fornecer consentimento e suas consequências; e</li>
						<li>revogação do consentimento, caso aplicável.</li>
					</ul>
					
					<h3>Fale conosco</h3>
					<p>Para o atendimento a quaisquer dúvidas envolvendo tratamento e privacidade de dados, a ClickMVP disponibiliza um canal direto de contato com o seu Encarregado de Proteção de Dados. Para ter acesso, basta enviar e-mail para <strong>suporte@clickmvp.com.br</strong>.</p>
				</Box>
			</Box>

			<LoginFooter />
  	</Box>
		</>
	)
}

export default PrivacyPolicies
