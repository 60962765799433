import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper } from '@mui/material'
import { FormHeader } from 'components'
import { Box, Button, Typography, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@mui/material'
import api from 'services/api'
import ListIcon from '@mui/icons-material/List'
import logoShopify from 'assets/shopify-icon.svg'
import CheckIcon from '@mui/icons-material/Check'
import { useStyles } from './styles'
import { CollectionsOutlined } from '@mui/icons-material'

const PlanChoicesList: React.FC = () => {
  const [currentPlan, setCurrentPlan] = useState('')
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false)
  const [planChangeConfirmationOpen, setPlanChangeConfirmationOpen] = useState(false)
  const [planChangeOpen, setPlanChangeOpen] = useState(false)
  const [futurePlan, setFuturePlan] = useState('')
  const [cancelReason, setCancelReason] = useState('')
  const [cancelComments, setCancelComments] = useState('')

	const classes = useStyles()

  useEffect(() => {
    async function loadData() {
      const user = localStorage.getItem('@onemandrop:user')
      const email = JSON.parse(user!).email

      const payLoad = {
        email
      }

      await api
        .post('/subscriptions/find-by-email', payLoad)
        .then(async subscription => {
          const { data } = subscription.data
          const { commercialPlanId } = data

          setCurrentPlan(commercialPlanId.name.toLowerCase())
        })
        .catch(error => {
          console.log(error)
        })
    }

    loadData()
  }, [])

  const handleCancelConfirmationClose = () => {
    setCancelConfirmationOpen(false)
  }
  
  const handleCancelConfirmation = async () => {
    const user = localStorage.getItem('@onemandrop:user')

    const email = JSON.parse(user!).email

    const payLoad = {
      email: email,
      cancelReason: cancelReason,
      cancelComments: cancelComments
    }

    await api
      .patch('/subscriptions/cancel-fields', payLoad)
      .catch(error => {
        console.log(error)
      })

    setCancelConfirmationOpen(false)
  }

  const handlePlanChangeConfirmationClose = () => {
    setPlanChangeConfirmationOpen(false)
  }

  const handlePlanChangeConfirmation = async () => {
    const user = localStorage.getItem('@onemandrop:user')

    const userId = JSON.parse(user!).id
    const name = JSON.parse(user!).name
    const email = JSON.parse(user!).email

    const payLoad = {
      userId: userId,
      openedAt: new Date(),
      name: name,
      email: email,
      type: 'subscription',
      title: 'Mudança de plano',
      description: `Mudança para o plano: "${futurePlan}".`,
      status: 'opened'
    }

    await api
      .post('/support-tickets', payLoad)
      .catch(error => {
        console.log(error)
      })

    setPlanChangeConfirmationOpen(false)
    setPlanChangeOpen(true)
  }

  const handlePlanChangeClose = () => {
    setPlanChangeOpen(false)
  }

  const handlePlanButtons = (plan: string, target: string) => {
    if (target === 'cancel') {
      setCancelConfirmationOpen(true)
    } else {
      let futurePlanText = ''

      switch (plan) {
        case 'starter':
          futurePlanText = 'Starter (100 produtos mensais)'
          break

        case 'essential':
          futurePlanText = 'Essential (200 produtos mensais)'
          break

        case 'plus':
          futurePlanText = 'Plus (300 produtos mensais)'
          break
      }

      setFuturePlan(futurePlanText)
      setPlanChangeConfirmationOpen(true)
    }
  }


  return (
    <Paper elevation={3} className={classes.paper}>

      <FormHeader
        title="Planos"
        icon={ListIcon}
        helpText="Aqui você econtra as opções possíveis em relação a sua assinatura. Aqui você pode trocar de plano e pode também cancelar sua assinatura."
      />

      <Box className={classes.container}>
        <Box className={classes.mainWrapper}>
          <Box className={classes.paperWrapper}>

            <Box className={classes.cardsWrapper}>

              <Box className={ currentPlan === 'starter' ? classes.selectedCard : classes.card }>
                <Typography className={classes.cardTitle}>Starter</Typography>

                <Box className={classes.quantityWrapper}>
                  <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                  <Typography className={classes.quantity}>100</Typography>&nbsp;produtos por mês
                </Box>

                <Box className={classes.targetPublic}>
                  <p>Para iniciantes que buscam resultados sólidos nos seus e-commerces.</p>
                </Box>

                <Box className={classes.priceWrapper}>
                  <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>197</Typography><span className={classes.priceComplements}>/mês</span>
                </Box>

                <Box className={classes.renewal}>
                  <p>Renova Mensalmente*</p>
                </Box>

                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, mb: 1 }}
                  style={{backgroundColor: currentPlan === 'starter' ? 'red' : '#30C742', marginTop: 4}}
                  onClick={() => handlePlanButtons('starter', currentPlan === 'starter' ? 'cancel' : 'change') }
                >
                  { currentPlan === 'starter' ? 'CANCELAR PLANO' : 'TROCAR DE PLANO'}
                </Button>

                <Box className={classes.featuresWrapper}>
                  <ul>
                    <li><CheckIcon className={classes.checkIcon} />Criação de 100 produtos na Shopify</li>
                    <li><CheckIcon className={classes.checkIcon} />Número de lojas ilimitado</li>
                    <li><CheckIcon className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                    <li><CheckIcon className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                  </ul>
                </Box>

              </Box>

              <Box className={ currentPlan === 'essential' ? classes.selectedCard : classes.card }>
                <Typography className={classes.cardTitle}>Essential</Typography>

                <Box className={classes.quantityWrapper}>
                  <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                  <Typography className={classes.quantity}>200</Typography>&nbsp;produtos por mês
                </Box>
                
                <Box className={classes.targetPublic}>
                  <p>Para quem quer expandir e aprimorar seus resultados nos seus e-commerces.</p>
                </Box>
                
                <Box className={classes.priceWrapper}>
                  <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>297</Typography><span className={classes.priceComplements}>/mês</span>
                </Box>

                <Box className={classes.renewal}>
                  <p>Renova Mensalmente*</p>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, mb: 1 }}
                  style={{backgroundColor: currentPlan === 'essential' ? 'red' : '#30C742', marginTop: 4}}
                  onClick={() => handlePlanButtons('essential', currentPlan === 'essential' ? 'cancel' : 'change') }
                >
                  { currentPlan === 'essential' ? 'CANCELAR PLANO' : 'TROCAR DE PLANO'}
                </Button>

                <Box className={classes.featuresWrapper}>
                  <ul>
                    <li><CheckIcon  className={classes.checkIcon} />Criação de 200 produtos na Shopify</li>
                    <li><CheckIcon  className={classes.checkIcon} />Número de lojas ilimitado</li>
                    <li><CheckIcon  className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                    <li><CheckIcon  className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                  </ul>
                </Box>

              </Box>

              <Box className={ currentPlan === 'plus' ? classes.selectedCard : classes.card }>
                <Typography className={classes.cardTitle}>Plus</Typography>

                <Box className={classes.quantityWrapper}>
                  <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                  <Typography className={classes.quantity}>300</Typography>&nbsp;produtos por mês
                </Box>
                
                <Box className={classes.targetPublic}>
                  <p>Para empreendedores consolidados que buscam crescimento exponencial em seus e-commerces.</p>
                </Box>
                
                <Box className={classes.priceWrapper}>
                  <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>397</Typography><span className={classes.priceComplements}>/mês</span>
                </Box>

                <Box className={classes.renewal}>
                  <p>Renova Mensalmente*</p>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2, mb: 1 }}
                  style={{backgroundColor: currentPlan === 'plus' ? 'red' : '#30C742', marginTop: 4}}
                  onClick={() => handlePlanButtons('plus', currentPlan === 'plus' ? 'cancel' : 'change') }
                >
                  { currentPlan === 'plus' ? 'CANCELAR PLANO' : 'TROCAR DE PLANO'}
                </Button>

                <Box className={classes.featuresWrapper}>
                  <ul>
                    <li><CheckIcon className={classes.checkIcon} />Criação de 300 produtos na Shopify</li>
                    <li><CheckIcon className={classes.checkIcon} />Número de lojas ilimitado</li>
                    <li><CheckIcon className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                    <li><CheckIcon className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                  </ul>
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={cancelConfirmationOpen}
        onClose={handleCancelConfirmationClose}
      >
        <DialogTitle className={classes.dialogElements}>Sua assinatura será cancelada</DialogTitle>

        <DialogContent style={{ width: '500px', color: 'black !important' }}>
          <DialogContentText>
            <Box className={classes.cancelForm}>
              <FormLabel className={classes.dialogElements}>Nos ajude a melhorar o produto. Gostaríamos de saber o motivo do cancelamento.</FormLabel>
              <FormControl style={{ marginTop: 15}}>
                <RadioGroup className={classes.dialogElements} onChange={(e) => setCancelReason(e.target.value)}>
                  <FormControlLabel value="gaveup" control={<Radio />} label={<Typography className={classes.dialogElements}>Não preciso mais</Typography>} />
                  <FormControlLabel value="better" control={<Radio />} label={<Typography className={classes.dialogElements}>Encontrei um produto melhor</Typography>} />
                  <FormControlLabel value="expensive" control={<Radio />} label={<Typography className={classes.dialogElements}>É muito caro</Typography>} />
                  <FormControlLabel value="complicated" control={<Radio />} label={<Typography className={classes.dialogElements}>É muito complicado</Typography>} />
                  <FormControlLabel value="other" control={<Radio />} label={<Typography className={classes.dialogElements}>Outro motivo</Typography>} />
                </RadioGroup>
              </FormControl>
            </Box>
          </DialogContentText>

          <Typography className={classes.dialogElements} style={{ marginTop: 15}}>Algum comentário adicional?</Typography>
          <TextField
            fullWidth
            id="cancelComment"
            name="cancelComment"
            type="text"
            onChange={(e) => setCancelComments(e.target.value)}
          />
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handleCancelConfirmationClose} variant="outlined">Fechar</Button>
          <Button onClick={handleCancelConfirmation} variant="contained">Cancelar Assinatura!</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={planChangeConfirmationOpen}
      >
        <DialogTitle className={classes.dialogElements}>Confirmação de troca de plano</DialogTitle>

        <DialogContent style={{ width: '500px', color: 'black !important' }}>
          <DialogContentText>
            Confirma mudança do seu plano atual para o plano <strong>"{futurePlan}"</strong>?
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handlePlanChangeConfirmationClose} variant="outlined">Fechar</Button>
          <Button onClick={handlePlanChangeConfirmation} variant="contained">Mudar Plano!</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={planChangeOpen}
        onClose={handlePlanChangeClose}
      >
        <DialogTitle className={classes.dialogElements}>Solicitação de mudança de plano registrada</DialogTitle>

        <DialogContent style={{ width: '500px', color: 'black !important' }}>
          <DialogContentText>
            Sua solicitação de mudança para o plano <strong>"{futurePlan}"</strong> foi registrada. Você receberá um e-mail com um link para que você possa confirmar a mudança de plano.
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{paddingRight: 25, paddingBottom: 20, paddingTop: 20}}>
          <Button onClick={handlePlanChangeClose} variant="contained">Fechar</Button>
        </DialogActions>
      </Dialog>

    </Paper>
  )
}

export default PlanChoicesList
