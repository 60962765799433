import React from 'react'
import { useHistory } from 'react-router-dom'
import { SvgIconComponent } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Typography, Box, Button, Tooltip } from '@mui/material'
import { useStyles } from './styles'

type Props = {
  icon?: SvgIconComponent,
  title: string,
  newRoute?: string,
  backRoute?: string,
  showSaveButton?: boolean,
  saveButtonLabel?: string,
  saveButtonPosition?: string,
  showDownloadButton?: boolean,
  showSecondaryButton?: boolean,
  showUploadButton?: boolean,
  showModalFormButton?: boolean,
  modalFormButtonLabel?: string,
  handleModal?:  React.Dispatch<React.SetStateAction<boolean>>,
  showModalFormSecondaryButton?: boolean,
  modalFormSecondaryButtonLabel?: string,
  downloadButtonLabel?: string,
  secondaryButtonLabel?: string,
  downloadLink?: string,
  secondaryLink?: string,
  handleSecondaryModal?:  React.Dispatch<React.SetStateAction<boolean>>,
  uploadButtonLabel?: string,
  uploadButtonFileTypes?: string,
  helpText?: string
  showHelpButton?: boolean	
  helpButtonLabel?: string
  handleHelpButton?: React.Dispatch<React.SetStateAction<boolean>>
}

const onDownload = (downloadLink: string) => {
  const link = document.createElement("a")
  link.setAttribute('target', '_blank')
  link.download = downloadLink
  link.href = downloadLink
  link.click()
}

const renderNewButton = (history: any , route?: string) => {
  if (typeof route !== 'undefined') {
    return <Button variant="contained" color="primary" onClick={() => history.push(route)}>Novo</Button>
  }
}

const renderBackButton = (history: any, route?: string) => {
  if (typeof route !== 'undefined') {
    return <Button variant="contained" color="primary" onClick={() => history.push(route)} style={{marginLeft: 10}} >Retornar</Button>
  }
}

const renderSaveButton = (showButton?: boolean, saveButtonLabel?: string, saveButtonPosition?: string) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button type="submit" variant="contained" color="primary">{saveButtonLabel ? saveButtonLabel : 'Salvar'}</Button>
    }
  }
}

const renderUploadButton = (showButton?: boolean, uploadButtonLabel?: string, uploadButtonFileTypes?: string) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button component="label" variant="contained" color="primary" style={{marginRight: 10}}>{uploadButtonLabel || 'Upload'}<input type="file" accept={uploadButtonFileTypes || '*.*'} hidden/></Button>
    }
  }
}

const renderModalFormButton = (showButton?: boolean, modalFormButtonLabel?: string, handleModal?: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button component="label" variant="contained" color="primary" style={{marginRight: 0}} onClick={() => handleModal(true)}>{modalFormButtonLabel || 'Modal'}</Button>
    }
  }
}

const renderModalFormSecondaryButton = (showButton?: boolean, modalFormSecondaryButtonLabel?: string, handleSecondaryModal?: React.Dispatch<React.SetStateAction<boolean>>, showSaveButton?: boolean) => {
  if (typeof showButton !== 'undefined') {
    let marginRight = 10

    if (showSaveButton !== true) {
      marginRight = 0
    }
    
    if (showButton) {
      return <Button component="label" variant="outlined" color="primary" style={{marginRight: marginRight }} onClick={() => handleSecondaryModal(true)}>{modalFormSecondaryButtonLabel || 'Modal'}</Button>
    }
  }
}

const renderDownloadButton = (showButton?: boolean, downloadButtonLabel?: string, downloadLink?: string) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button component="label" variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => onDownload(downloadLink)}>{downloadButtonLabel || 'Download'}</Button>
    }
  }
}

const renderSecondaryButton = (showButton?: boolean, secondaryButtonLabel?: string, secondaryLink?: string) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button component="label" variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => { window.open(secondaryLink, '_blank') }}>{secondaryButtonLabel || 'Ação'}</Button>
    }
  }
}

const renderHelpIcon = (classes: any, helpText?: string) => {
  if (typeof helpText !== 'undefined') {
    if (helpText) {
      return (
        <Tooltip title={helpText} placement="right-end" arrow={true} >
          <HelpOutlineIcon className={classes.formTitleHelpIcon} />
        </Tooltip>
      )
    }
  }
}

const renderHelpButton = (showButton?: boolean, helpButtonLabel?: string, handleHelpButton?: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (typeof showButton !== 'undefined') {
    if (showButton) {
      return <Button component="label" variant="outlined" color="primary" onClick={() => handleHelpButton(true)} style={{ marginLeft: 10 }}>{helpButtonLabel || 'Ajuda'}</Button>
    }  
  }
}


const FormHeader: React.FC<Props> = ({
  icon,
  title,
  newRoute,
  backRoute,
  showSaveButton,
  saveButtonLabel,
  saveButtonPosition,
  showDownloadButton,
  showSecondaryButton,
  showUploadButton,
  showModalFormButton,
  modalFormButtonLabel,
  handleModal,
  showModalFormSecondaryButton,
  modalFormSecondaryButtonLabel,
  downloadButtonLabel,
  secondaryButtonLabel,
  downloadLink,
  secondaryLink,
  handleSecondaryModal,
  uploadButtonLabel,
  uploadButtonFileTypes,
  helpText,
  showHelpButton,
  helpButtonLabel,
  handleHelpButton
}: Props) => {
  const FormIcon = icon

  const classes = useStyles()
  const history = useHistory()

  return (
    <Typography variant="h5" className={classes.formTitle}>
      <Box className={classes.formTitleLeft}>
        {title}
        { renderHelpIcon(classes, helpText) }
        { renderHelpButton(showHelpButton, helpButtonLabel, handleHelpButton) }
      </Box>
      <Box className={classes.formButtonsRight}>
        { renderSecondaryButton(showSecondaryButton, secondaryButtonLabel, secondaryLink) }
        { renderDownloadButton(showDownloadButton, downloadButtonLabel, downloadLink) }
        { renderUploadButton(showUploadButton, uploadButtonLabel, uploadButtonFileTypes) }
        { renderNewButton(history, newRoute) }
        { renderModalFormSecondaryButton(showModalFormSecondaryButton, modalFormSecondaryButtonLabel, handleSecondaryModal, showSaveButton) }
        { renderModalFormButton(showModalFormButton, modalFormButtonLabel, handleModal) }
        { renderSaveButton(showSaveButton, saveButtonLabel, saveButtonPosition) }
        { renderBackButton(history, backRoute) }
      </Box>
    </Typography>
  )
}

export { FormHeader }
