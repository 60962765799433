import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IPlanTransactionDTO } from 'data/dtos/payment/i-plan-transaction-dto'

interface IRouteParams {
  id: string
}

const PlanTransactionForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    transactionDateTime: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control
  } = useForm<IPlanTransactionDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      transactionType: '',
      aliexpressProductId: '',
      shopifyProductId: '',
      shopifyProductHandle: '',
      transactionQuantity: 0,
      currentBalance: 0,
      notRecognized: false,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/plan-transactions/${id}`)
        .then(response => {
          const { data } = response.data

          const planTransactionResult = {
            transactionDateTime: data.transactionDateTime,
            transactionType: data.transactionType,
            aliexpressProductId: data.aliexpressProductId,
            shopifyProductId: data.shopifyProductId,
            shopifyProductHandle: data.shopifyProductHandle,
            transactionQuantity: data.transactionQuantity,
            currentBalance: data.currentBalance,
            notRecognized: data.notRecognized,
          }

          return planTransactionResult
        })
        .then((planTransactionResult: IPlanTransactionDTO) => {
          reset(planTransactionResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IPlanTransactionDTO) => {
    const payLoad: IPlanTransactionDTO = {
      transactionDateTime: data.transactionDateTime,
      transactionType: data.transactionType,
      aliexpressProductId: data.aliexpressProductId,
      shopifyProductId: data.shopifyProductId,
      shopifyProductHandle: data.shopifyProductHandle,
      transactionQuantity: data.transactionQuantity,
      currentBalance: data.currentBalance,
      notRecognized: data.notRecognized,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/plan-transactions`, payLoad)
        .then(history.push('/plan-transactions'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/plan-transactions', payLoad)
        .then(history.push('/plan-transactions/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Transações do Plano"
          icon={ListIcon}
          backRoute="/plan-transactions"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre as transações do plano."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="transactionDateTime"
              label="Data"
              error={!!errors.transactionDateTime}
              helperText={errors?.transactionDateTime?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type="date"
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              {...register("transactionDateTime",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="transactionType"
              label="Tipo"
              error={!!errors.transactionType}
              helperText={errors?.transactionType?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("transactionType",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="aliexpressProductId"
              label="ID Aliexpress"
              error={!!errors.aliexpressProductId}
              helperText={errors?.aliexpressProductId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("aliexpressProductId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="shopifyProductId"
              label="ID Shopify"
              error={!!errors.shopifyProductId}
              helperText={errors?.shopifyProductId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("shopifyProductId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="shopifyProductHandle"
              label="Slug"
              error={!!errors.shopifyProductHandle}
              helperText={errors?.shopifyProductHandle?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 200
              }}
              {...register("shopifyProductHandle",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NumericInput
                label="Quantidade"
                precision={0}
                decimalChar=','
                thousandChar='.'
                variant='outlined'
                value={getValues("transactionQuantity")}
                {...register("transactionQuantity", { onChange: (e) => {
                  setValue("transactionQuantity", e.target.value)
                  handleChange(e)
                }})}
              />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NumericInput
                label="Saldo"
                precision={0}
                decimalChar=','
                thousandChar='.'
                variant='outlined'
                value={getValues("currentBalance")}
                {...register("currentBalance", { onChange: (e) => {
                  setValue("currentBalance", e.target.value)
                  handleChange(e)
                }})}
              />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="notRecognized"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Nao Reconhecido"
                  />
                )}
              />
            </FormGroup>
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default PlanTransactionForm
