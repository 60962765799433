import { createContext, useState, ReactNode } from 'react'

type ApplicationContextType = {
	menuOpen: boolean
	setMenuOpen: (menuOpen: boolean) => void
	helpOpen: boolean
	setHelpOpen: (helpOpen: boolean) => void 
	helpText: JSX.Element
	setHelpText: (helpText: JSX.Element) => void 
	currentStore: string
	setCurrentStore: (currentStore: string) => void
}

interface Props {
	children?: ReactNode
}

export const ApplicationContext = createContext({} as ApplicationContextType)

export function ApplicationProvider({ children }: Props) {
	const [menuOpen, setMenuOpen] = useState<boolean>(true)
	const [helpOpen, setHelpOpen] = useState<boolean>(false)
	const [helpText, setHelpText] = useState<JSX.Element>(null)
	const [currentStore, setCurrentStore] = useState<string>('')

	return (
		<ApplicationContext.Provider
			value={{
				menuOpen,
				setMenuOpen,
				helpOpen, 
				setHelpOpen,
				helpText, 
				setHelpText,
				currentStore,
				setCurrentStore
			}}
		>
			{children}
		</ApplicationContext.Provider>
	)
}
