import React, { useState, useEffect } from 'react'
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Box, Avatar, FormControl } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MailIcon from '@mui/icons-material/Mail'
import AccountCircle from '@mui/icons-material/AccountCircle'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@mui/icons-material/MoreVert'
import { useAuth } from 'hooks/auth'
import { useApplication } from 'hooks/use-application'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import logo from 'assets/logo-main.svg'
import api from 'services/api'

import { useStyles } from "./styles"
import { CollectionsOutlined, DisplaySettings } from '@mui/icons-material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))


const capitalizeAll = (mySentence: string) => {
  let output = ''
  const words = mySentence.toLowerCase().split(' ')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    output += words[i] + ' '
  }

  return output.trim()
}


const firstAndLastName = (fullName: string) => {
  const capitalizedName = capitalizeAll(fullName)
  const names = capitalizedName.split(' ')

  if (names.length === 1) {
    return names[0]
  } else {
    return `${names[0]} ${names[names.length - 1]}`
  }
}


const renderStyledBadge = (showBadge?: boolean, classes?: any) => {
  if (typeof showBadge !== 'undefined') {
    if (showBadge) {
      return (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar 
            sx={{ 
              bgcolor: 'white', 
              height: 30, 
              width: 30, 
              marginLeft: '10px',
              cursor: 'pointer' 
            }}>
            <NotificationsNoneIcon className={classes.appIcon} />
          </Avatar>
        </StyledBadge>
      )
    } else {
      return (
        <Avatar 
          sx={{ 
            bgcolor: 'white', 
            height: 30, 
            width: 30, 
            marginLeft: '10px',
            cursor: 'pointer' 
          }}>
          <NotificationsNoneIcon className={classes.appIcon} />
        </Avatar>
      )
    }
  }
}

const AppHeader: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const menuId = 'account-menu'
  const mobileMenuId = 'account-menu-mobile'
  const classes = useStyles()
  const { signOut, user } = useAuth();
  const { menuOpen, setMenuOpen } = useApplication()
  const [stores, setStores] = useState([])

  const { currentStore, setCurrentStore } = useApplication()

  useEffect(() => {
    async function loadData() {
      await api
        .post('/stores/list-all')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((storesResult) => {
          setStores(storesResult)
        })
        .catch(error => {
          console.log(error)

          return error
        })

        const user = localStorage.getItem('@onemandrop:user')

        if (user !== null) {
          setCurrentStore(JSON.parse(user).currentStore)
        } else {
          setCurrentStore('')
        }
    }

    loadData()
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    async function loadData() {    
      const payLoad = {
        currentStore: event.target.value as string
      }

      await api
        .patch('/users/current-store', payLoad)
        .then(response => {})
        .catch(error => {
          console.log(error)
          return error
        })

      const userString = localStorage.getItem('@onemandrop:user')
      const user = JSON.parse(userString)

      user.currentStore = event.target.value as string
      
      localStorage.setItem('@onemandrop:user', JSON.stringify(user))
      setCurrentStore(event.target.value)
    }

    loadData()
  }

  const history = useHistory()

  const handleDrawerOpen = () => {
    setMenuOpen(!menuOpen);
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleProfile = () => {
    history.push('/profile')
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        sx: {
          marginTop: '3px',
          '& .MuiMenuItem-root': {
            padding: '3px 60px 5px 15px' ,
          },
        },
      }}
    >
      <MenuItem onClick={handleProfile} style={{ display: 'none' }}>Perfil</MenuItem>
      <MenuItem onClick={signOut}>Sair</MenuItem>
    </Menu>
  )

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {
            user.avatar_url
              ? <img src={user.avatar_url} className={classes.profileImage}/>
              : <AccountCircle />
          }
        </IconButton>

        <p>Profile</p>
      </MenuItem>
    </Menu>
  )

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>    
        <Box className={classes.logoArea}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={classes.hamburguerMenuContainer}
        >
          {menuOpen === true ? <MenuIcon className={classes.hamburguerMenu}/> : <MenuOpenIcon className={classes.hamburguerMenu}/>}
        </IconButton>
          <img src={logo} alt="OneManDrop" className={classes.logo} />
        </Box>

        <div className={classes.grow} />

        <div className={classes.userName}>
          { user.name ? <span>{firstAndLastName(user.name)}</span> : '' }
        </div>

        <div className={classes.sectionDesktop}>
          <Select
            id="demo-simple-select"
            value={currentStore}
            onChange={handleChange}
            style={{ margin: '0px 10px 0px 0px', height: 30, width: 200, backgroundColor: '#FFF', paddingTop: 11 }}
            MenuProps={{
              PaperProps: {
                sx: {
                  marginTop: '-12px',
                  '& .MuiMenuItem-root': {
                    padding: '3px 60px 5px 15px',
                  },
                },
              },
            }}
          >
          {stores.map((store) => (
            <MenuItem
              key={store.id}
              value={store.id}
            >
              {store.storeName}
            </MenuItem>
          ))}
          </Select>

          { renderStyledBadge(false, classes) }

          <Avatar 
            sx={{ 
              bgcolor: 'white', 
              height: 30, 
              width: 30, 
              marginLeft: '10px',
              cursor: 'pointer' 
            }}
            onClick={handleProfileMenuOpen}
          >
            {
              user.avatar_url
                ? <img src={user.avatar_url} height={30} width={30}/>
                : <AccountCircle />
            }
          </Avatar>
        </div>

        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon className={classes.appIcon} />
          </IconButton>
        </div>
      </Toolbar>

      {renderMenu}
      {renderMobileMenu}

    </AppBar>
  )
}

export default AppHeader
