import { makeStyles } from '@mui/styles'

import backgroundImage from 'assets/login-background.png'
import background from 'assets/background.png'

const useStyles = makeStyles(() => ({
	ul: {
		marginBlockStart: '0 !important',
		marginBlockEnd: '0 !important',
		margin: '0 !important'
	},

  Media: {
    height: '100%',
    width: '100%'
  },

	container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
		backgroundImage: `url(${background})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	mainWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
		minHeight: 'calc(100vh - 50px)'
	},

	paperWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'start',
		backgroundColor: 'white',
		width: '960px',
		padding: '90px 40px 40px 40px',
		color: '#333333',

		'& h1': {
			marginBottom: '0px',
			fontSize: '28px'
		},

		'& p': {
			marginTop: '0px',
			marginBottom: '10px'
		},

		'& ul': {
			marginTop: '5px',
			marginBottom: '10px'
		},
	},

	logoImage: {
		width: '140px'
	},

	formBox: {
		width: '290px'
	},

	formTitle: {
		lineHeight: 1,
		marginTop: 0
	},

	linearProgress: {
		marginTop: '20px'
	},

	alert: {
		marginTop: '20px', 
		padding: '1px 16px 1px 16px'
	},

	footer: {
  	marginTop: 'auto',
  	backgroundColor: '#E7EAED',
  	padding: '10px 10px 15px 10px'
	},

	signupLink: {
		textAlign: 'right'
	},

	header: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		top: 0,
		left: 0,
		backgroundColor: '#6278E7',
		width: '100%',
		height: '80px'
	},

	headerPaperWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'start',
		width: '960px',
		padding: '10px',
		color: 'white'
	}
}))

export { useStyles }
