import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  container: {
    maxHeight: 'calc(100vh - 320px)',
  },

  paper: {
    padding: '20px',
    minHeight: 'calc(100vh - 85px)',
    maxHeight: 'calc(100vh - 85px)',
    height: 'calc(100vh - 85px)',
    width: '100%',
    marginBottom: '5px'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  formTitleLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 800
  },

  formTitleLeftIcon: {
    marginRight: 5
  },

  formContainer: {
    margin: '12px 20px 10px 0px',
    width: '100%',
    maxHeight: 'calc(100vh - 190px)',
    overflow: 'overlay',
    paddingRight: '7px'
  },

  tableImage: {
    width: 60,
    height: 60,
    border: '1px solid #6278E7',
    borderRadius: 4
  },

  gridHeader: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem'
  },

  gridRow: {
    fontSize: '0.875rem',
    fontWeight: 400
  },

  gridRowCheck: {
    marginTop: 10
  },

  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    marginTop: 10,
    marginBottom: 15
  },

  selectedText: {
    color: theme.palette.primary.main,
    width: '100%', 
    paddingLeft: 15
  }
}))

export { useStyles }
