import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel } from '@mui/material'
import { FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IBuyerEventDTO } from 'data/dtos/payment/i-buyer-event-dto'

interface IRouteParams {
  id: string
}

const BuyerEventForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    date: yup.string()
      .required('Campo obrigatório'),
    type: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control
  } = useForm<IBuyerEventDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: '',
      type: '',
      version: '',
      paymentGatewayId: '',
      json: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/buyer-events/${id}`)
        .then(response => {
          const { data } = response.data

          const buyerEventResult = {
            date: data.date,
            type: data.type,
            version: data.version,
            paymentGatewayId: data.paymentGatewayId,
            json: data.json,
          }

          return buyerEventResult
        })
        .then((buyerEventResult: IBuyerEventDTO) => {
          reset(buyerEventResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IBuyerEventDTO) => {
    const payLoad: IBuyerEventDTO = {
      date: data.date,
      type: data.type,
      version: data.version,
      paymentGatewayId: data.paymentGatewayId,
      json: data.json,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/buyer-events`, payLoad)
        .then(history.push('/buyer-events'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/buyer-events', payLoad)
        .then(history.push('/buyer-events/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Eventos do Gateway de Pagamento"
          icon={ListIcon}
          backRoute="/buyer-events"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre os eventos do gateway de pagamento."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="date"
              label="Data e Hora"
              error={!!errors.date}
              helperText={errors?.date?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("date",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="type"
              label="Tipo do Evento"
              error={!!errors.type}
              helperText={errors?.type?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("type",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="version"
              label="Versão"
              error={!!errors.version}
              helperText={errors?.version?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("version",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="paymentGatewayId"
              label="Id no Gateway de Pagamento"
              error={!!errors.paymentGatewayId}
              helperText={errors?.paymentGatewayId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 40
              }}
              {...register("paymentGatewayId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="json"
              label="Json do Evento"
              error={!!errors.json}
              helperText={errors?.json?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("json",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default BuyerEventForm
