import React, { useState, useEffect } from 'react'
import { Paper } from '@mui/material'
import { CustomTable, FormHeader } from 'components'
import ListIcon from '@mui/icons-material/List'
import { useStyles } from './styles'
import api from 'services/api'
import { ITableHeadCellDTO } from 'data/dtos/components/i-table-head-cell-dto'
import { IPlanTransactionDTO } from 'data/dtos/payment/i-plan-transaction-dto'
import { useAlreadyMounted } from 'utils/use-already-mounted';

const headCells: ITableHeadCellDTO[] = [
  {
    id: 'transactionDateTime',
    label: 'Data',
    width: 2,
    format: 'datetime'
  },
  {
    id: 'transactionType',
    label: 'Tipo',
    width: 1,
    convert: {
      credit: 'Recarga',
      debt: 'Envio'
    }
  },
  {
    id: 'storeName',
    label: 'Loja',
    width: 2
  },
  {
    id: 'shopifyProductHandle',
    label: 'Link na Loja',
    width: 5
  },
  {
    id: 'transactionQuantity',
    label: 'Quantidade',
    width: 1,
    format: 'number',
    backgroundColor: {
      column: 'transactionType', 
      colors: {
        credit: '#EAFFEA',
        debt: '#FEF2F2'
      }
    }
  }
]

const PlanTransactionList: React.FC = () => {
  const [loading, setLoading] = useState(0)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(1000)
  const [orderByDirection, setOrderByDirection] = useState(true)
  const [rowsCount, setRowsCount] = useState(0)
  const [planTransactionsList, setPlanTransactionsList] = useState<IPlanTransactionDTO[]>([])
  const [recordToDelete, setRecordToDelete] = useState<string | null>('')
  const [columnOrder, setColumnOrder] = useState<('ASC' | 'DESC')[]>(['DESC', 'ASC', 'ASC', 'ASC', 'ASC', 'ASC'])

  const classes = useStyles()
  const alreadyMounted = useAlreadyMounted();

  const loadPlanTransactions = async () => {
    setLoading(1)

    await api
      .post('/plan-transactions/list', {search, page, rowsPerPage, columnOrder})
      .then(async listResponse => {
        const { data } = listResponse.data
        setPlanTransactionsList(data)
        setColumnOrder(['DESC', 'ASC', 'ASC', 'ASC', 'ASC', 'ASC'])

        await api
          .post('/plan-transactions/count', {search})
          .then(countResponse => {
            const { count } = countResponse.data.data
            setRowsCount(count)
          })
          .then(() => setLoading(0))
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
      })
  }


  const handleDelete = async () => {
    await api
      .delete(`/plan-transactions/${recordToDelete}`)
      .then(async () => {
        await loadPlanTransactions()
      })
      .catch(error => {
        console.log(error.response.data)
      })
  }


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
    setPage(newPage);
  }


  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!alreadyMounted) {
        loadPlanTransactions()
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [search])


  useEffect(() => {
    loadPlanTransactions()
  }, [orderByDirection, rowsPerPage, page])


  return (
    <Paper elevation={3} className={classes.paper}>

      <FormHeader
        title="Movimentações do Plano"
        icon={ListIcon}
        helpText="Aqui são informados os registros das recargas do plano e dos envios bem sucedidos de produtos para as lojas."
      />

      <CustomTable
        headCells={headCells}
        rows={planTransactionsList}
        totalRows={rowsCount}
        handleSearch={setSearch}
        isLoading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        page={page}
        editRoute="/plan-transactions/edit"
        handleDelete={handleDelete}
        handleRecordToDelete={setRecordToDelete}
        columnOrder={['DESC', 'ASC', 'ASC', 'ASC', 'ASC', 'ASC']}
        setColumnOrder={setColumnOrder}
        orderByDirection={orderByDirection}
        setOrderByDirection={setOrderByDirection}
        editIcon='none'
        deleteIcon='none'
        sortDisabled={true}
      />

    </Paper>
  )
}

export default PlanTransactionList
