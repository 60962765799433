import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { ICommercialPlanDTO } from 'data/dtos/payment/i-commercial-plan-dto'

interface IRouteParams {
  id: string
}

const CommercialPlanForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [productsA, setProductsA] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    productId: yup.string()
      .required('Campo obrigatório'),
    name: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<ICommercialPlanDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      productId: '',
      name: '',
      paymentGatewayId: '',
      quantity: 0,
      isInactive: false,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Produto

      await api
        .post('/products/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((productsResult) => {
          setProductsA(productsResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/commercial-plans/${id}`)
        .then(response => {
          const { data } = response.data

          const commercialPlanResult = {
            productId: data.productId.id,
            name: data.name,
            paymentGatewayId: data.paymentGatewayId,
            quantity: data.quantity,
            isInactive: data.isInactive,
          }

          return commercialPlanResult
        })
        .then((commercialPlanResult: ICommercialPlanDTO) => {
          reset(commercialPlanResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: ICommercialPlanDTO) => {
    const payLoad: ICommercialPlanDTO = {
      productId: data.productId,
      name: data.name,
      paymentGatewayId: data.paymentGatewayId,
      quantity: data.quantity,
      isInactive: data.isInactive,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/commercial-plans`, payLoad)
        .then(history.push('/commercial-plans'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/commercial-plans', payLoad)
        .then(history.push('/commercial-plans/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Planos Comerciais"
          icon={ListIcon}
          backRoute="/commercial-plans"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre os planos comerciais."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="productId"
              label="Produto"
              error={!!errors.productId}
              helperText={errors?.productId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().productId}`}
              select
              autoFocus
              inputRef={firstInputElement}
              {...register("productId", { onChange: (e) => {
                setValue("productId", e.target.value)
                handleChange(e)
              }})}
            >
            {productsA.map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
              >
                {product.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="name"
              label="Nome"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="paymentGatewayId"
              label="Id no Gateway de Pagamento"
              error={!!errors.paymentGatewayId}
              helperText={errors?.paymentGatewayId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 30
              }}
              {...register("paymentGatewayId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="quantity"
              label="Quantidade"
              error={!!errors.quantity}
              helperText={errors?.quantity?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 40
              }}
              {...register("quantity",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="isInactive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Inativo"
                  />
                )}
              />
            </FormGroup>
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default CommercialPlanForm
