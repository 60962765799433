import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { useStyles } from './styles'
import logoImage from 'assets/logo-main.svg'
import logoShopify from 'assets/shopify-icon.svg'
import LoginFooter from 'components/login-footer'
import CheckIcon from '@mui/icons-material/Check';

const PrivacyPolicies: React.FC = () => {
  const history = useHistory()
	const classes = useStyles()
  const planStarterLink = 'https://pay.hotmart.com/B91715548S?off=dip8sv70'
  const planEssentialLink = 'https://pay.hotmart.com/B91715548S?off=zik0uvpq'
  const planPlusLink = 'https://pay.hotmart.com/B91715548S?off=rakmgv1t'

	return (
		<>
		<Box  className={classes.header}>
			<Box className={classes.headerPaperWrapper}>
				<a href="/home"onClick={() => history.push('/home')}><img src={logoImage} alt="logo" className={classes.logoImage} /></a>
			</Box>
		</Box>

		<Box className={classes.container}>
			<Box className={classes.mainWrapper}>
				<Box className={classes.paperWrapper}>

          <Box className={classes.slogan}>
            <Box className={classes.sloganWrapper}>
              <p>Transforme <span style={{color: '#DD245B'}}>links</span> do AliExpress</p>
              <p><span style={{color: '#DD245B'}}>em produtos</span> na sua loja Shopify em segundos!</p>
            </Box>
          </Box>

          <Box className={classes.cardsWrapper}>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Starter</Typography>

              <Box className={classes.quantityWrapper}>
                <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                <Typography className={classes.quantity}>100</Typography>&nbsp;produtos por mês
              </Box>

              <Box className={classes.targetPublic}>
                <p>Para iniciantes que buscam resultados sólidos nos seus e-commerces.</p>
              </Box>

              <Box className={classes.priceWrapper}>
                <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>197</Typography><span className={classes.priceComplements}>/mês</span>
              </Box>

              <Box className={classes.renewal}>
                <p>Renova Mensalmente*</p>
              </Box>

              <Button
                type="button"
                variant="contained"
                size="large"
                sx={{ mt: 2, mb: 1 }}
                style={{backgroundColor: '#30C742', marginTop: 4}}
                onClick={() => { window.location.href = planStarterLink; } }
              >
                ESCOLHER PLANO
              </Button>

              <Box className={classes.featuresWrapper}>
                <ul>
                  <li><CheckIcon  className={classes.checkIcon} />Criação de 100 produtos na Shopify</li>
                  <li><CheckIcon  className={classes.checkIcon} />Número de lojas ilimitado</li>
                  <li><CheckIcon  className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                  <li><CheckIcon  className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                </ul>
              </Box>

            </Box>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Essential</Typography>

              <Box className={classes.quantityWrapper}>
                <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                <Typography className={classes.quantity}>200</Typography>&nbsp;produtos por mês
              </Box>
              
              <Box className={classes.targetPublic}>
                <p>Para quem quer expandir e aprimorar seus resultados nos seus e-commerces.</p>
              </Box>
              
              <Box className={classes.priceWrapper}>
                <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>297</Typography><span className={classes.priceComplements}>/mês</span>
              </Box>

              <Box className={classes.renewal}>
                <p>Renova Mensalmente*</p>
              </Box>

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ mt: 2, mb: 1 }}
                style={{backgroundColor: '#30C742', marginTop: 4}}
                onClick={() => { window.location.href = planEssentialLink; } }
              >
                ESCOLHER PLANO
              </Button>

              <Box className={classes.featuresWrapper}>
                <ul>
                  <li><CheckIcon  className={classes.checkIcon} />Criação de 200 produtos na Shopify</li>
                  <li><CheckIcon  className={classes.checkIcon} />Número de lojas ilimitado</li>
                  <li><CheckIcon  className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                  <li><CheckIcon  className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                </ul>
              </Box>

            </Box>

            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Plus</Typography>

              <Box className={classes.quantityWrapper}>
                <img src={logoShopify} alt="shopify logo" className={classes.shopifyLogo} />
                <Typography className={classes.quantity}>300</Typography>&nbsp;produtos por mês
              </Box>
              
              <Box className={classes.targetPublic}>
                <p>Para empreendedores consolidados que buscam crescimento exponencial em seus e-commerces.</p>
              </Box>
              
              <Box className={classes.priceWrapper}>
                <span className={classes.priceComplements}>R$</span><Typography className={classes.price}>397</Typography><span className={classes.priceComplements}>/mês</span>
              </Box>

              <Box className={classes.renewal}>
                <p>Renova Mensalmente*</p>
              </Box>

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ mt: 2, mb: 1 }}
                style={{backgroundColor: '#30C742', marginTop: 4}}
                onClick={() => { window.location.href = planPlusLink; } }
              >
                ESCOLHER PLANO
              </Button>

              <Box className={classes.featuresWrapper}>
                <ul>
                  <li><CheckIcon  className={classes.checkIcon} />Criação de 300 produtos na Shopify</li>
                  <li><CheckIcon  className={classes.checkIcon} />Número de lojas ilimitado</li>
                  <li><CheckIcon  className={classes.checkIcon} />Muitos usuários podem trabalhar na mesma loja ao mesmo tempo</li>
                  <li><CheckIcon  className={classes.checkIcon} />Saldo do plano cumulativo mês a mês</li>
                </ul>
              </Box>

            </Box>

          </Box>
				</Box>
			</Box>

			<LoginFooter />
  	</Box>
		</>
	)
}

export default PrivacyPolicies
