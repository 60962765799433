import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, FormControl, TextareaAutosize } from '@mui/material'
import NumericInput from 'material-ui-numeric-input'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IFoundProductDTO } from 'data/dtos/automation/i-found-product-dto'

interface IRouteParams {
  id: string
}

const FoundProductForm: React.FC = () => {
  const [mainError, setMainError] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    productId: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
    control
  } = useForm<IFoundProductDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      productId: '',
      productDescription: '',
      productFullDescription: '',
      productFullDescriptionImages: '',
      productFullDescriptionImagesQty: 0,
      taxonomyCode: '',
      taxonomy: '',
      maxPrice: 0,
      freight: 0,
      baseCurrencyCode: '',
      currencyCode: '',
      currencyRate: 0,
      tradeQuantity: '',
      totalReviews: 0,
      productDescriptionUrl: '',
      property1Name: '',
      property1Titles: '',
      property1Photos: '',
      property2Name: '',
      property2Titles: '',
      property2SizeChart: '',
      images: '',
      storeId: '',
      storeName: '',
      countryName: '',
      openDate: '',
      openYears: '',
      feedbackRating: '',
      feedbackRatingCount: '',
      prices: '',
      skus: '',
      props: '',
      exportJson: '',
      feedbacksJson: '',
      discarded: false,
      productSearchId: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/found-products/${id}`)
        .then(response => {
          const { data } = response.data

          const foundProductResult = {
            productId: data.productId,
            productDescription: data.productDescription,
            productFullDescription: data.productFullDescription,
            productFullDescriptionImages: data.productFullDescriptionImages,
            productFullDescriptionImagesQty: data.productFullDescriptionImagesQty,
            taxonomyCode: data.taxonomyCode,
            taxonomy: data.taxonomy,
            maxPrice: data.maxPrice,
            freight: data.freight,
            baseCurrencyCode: data.baseCurrencyCode,
            currencyCode: data.currencyCode,
            currencyRate: data.currencyRate,
            tradeQuantity: data.tradeQuantity,
            totalReviews: data.totalReviews,
            productDescriptionUrl: data.productDescriptionUrl,
            property1Name: data.property1Name,
            property1Titles: data.property1Titles,
            property1Photos: data.property1Photos,
            property2Name: data.property2Name,
            property2Titles: data.property2Titles,
            property2SizeChart: data.property2SizeChart,
            images: data.images,
            storeId: data.storeId,
            storeName: data.storeName,
            countryName: data.countryName,
            openDate: data.openDate,
            openYears: data.openYears,
            feedbackRating: data.feedbackRating,
            feedbackRatingCount: data.feedbackRatingCount,
            prices: data.prices,
            skus:  data.skus,
            props: data.props,
            discarded: data.discarded,
            chatgptTitleOptions: data.chatgptTitleOptions,
            chatgptDescriptionOptions: data.chatgptDescriptionOptions,
            chatgptCharacteristicsOptions: data.chatgptCharacteristicsOptions,
            exportJson: data.exportJson,
            feedbacksJson: data.feedbacksJson,
            productSearchId: data.productSearchId
          }

          return foundProductResult
        })
        .then((foundProductResult: IFoundProductDTO) => {
          reset(foundProductResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IFoundProductDTO) => {
    const payLoad: IFoundProductDTO = {
      productId: data.productId,
      productDescription: data.productDescription,
      taxonomyCode: data.taxonomyCode,
      taxonomy: data.taxonomy,
      maxPrice: data.maxPrice,
      freight: data.freight,
      baseCurrencyCode: data.baseCurrencyCode,
      currencyCode: data.currencyCode,
      currencyRate: data.currencyRate,
      tradeQuantity: data.tradeQuantity,
      totalReviews: data.totalReviews,
      productDescriptionUrl: data.productDescriptionUrl,
      property1Name: data.property1Name,
      property1Titles: data.property1Titles,
      property1Photos: data.property1Photos,
      property2Name: data.property2Name,
      property2Titles: data.property2Titles,
      property2SizeChart: data.property2SizeChart,
      images: data.images,
      storeId: data.storeId,
      storeName: data.storeName,
      countryName: data.countryName,
      openDate: data.openDate,
      openYears: data.openYears,
      feedbackRating: data.feedbackRating,
      feedbackRatingCount: data.feedbackRatingCount,
      prices: data.prices,
      skus: data.skus,
      props: data.props,
      discarded: data.discarded,
      chatgptTitleOptions: data.chatgptTitleOptions,
      chatgptDescriptionOptions: data.chatgptDescriptionOptions,
      chatgptCharacteristicsOptions: data.chatgptCharacteristicsOptions,
      exportJson: data.exportJson,
      feedbacksJson: data.feedbacksJson,
      productSearchId: data.productSearchId,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/found-products`, payLoad)
        .then(history.push('/found-products'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/found-products', payLoad)
        .then(history.push('/found-products/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Produtos Achados"
          icon={ListIcon}
          backRoute="/found-products"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre resultados de buscas de novos produtos."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <TextField
              id="productId"
              label="Código do produto"
              error={!!errors.productId}
              helperText={errors?.productId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("productId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <TextField
              id="productDescription"
              label="Descrição do produto"
              error={!!errors.productDescription}
              helperText={errors?.productDescription?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 255
              }}
              {...register("productDescription",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="productFullDescription" style={{ marginTop: '10px' }}>Descrição Completa</InputLabel>
                <TextareaAutosize
                  id="productFullDescription"
                  minRows={10}
                  maxRows={10}
                  value={getValues("productFullDescription")}
                  {...register("productFullDescription", {
                    onChange: (e) => {
                      setValue("productFullDescription", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="productFullDescription"
            control={control}
          />
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="productFullDescriptionImages" style={{ marginTop: '10px' }}>Imagens da Descrição Completa</InputLabel>
                <TextareaAutosize
                  id="productFullDescriptionImages"
                  minRows={10}
                  maxRows={10}
                  value={getValues("productFullDescriptionImages")}
                  {...register("productFullDescriptionImages", {
                    onChange: (e) => {
                      setValue("productFullDescriptionImages", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="productFullDescriptionImages"
            control={control}
          />
          </Grid>



          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <TextField
              id="taxonomyCode"
              label="Código da Taxonomia"
              error={!!errors.taxonomyCode}
              helperText={errors?.taxonomyCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("taxonomyCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <TextField
              id="taxonomy"
              label="Taxonomia"
              error={!!errors.taxonomy}
              helperText={errors?.taxonomy?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 255
              }}
              {...register("taxonomy",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NumericInput
              label="Maior preço"
              precision={2}
              decimalChar=','
              thousandChar='.'
              variant='outlined'
              value={getValues("maxPrice")}
              {...register("maxPrice", { onChange: (e) => {
                setValue("maxPrice", e.target.value)
                handleChange(e)
              }})}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NumericInput
              label="Frete"
              precision={2}
              decimalChar=','
              thousandChar='.'
              variant='outlined'
              value={getValues("freight")}
              {...register("freight", { onChange: (e) => {
                setValue("freight", e.target.value)
                handleChange(e)
              }})}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="baseCurrencyCode"
              label="Moeda de origem"
              error={!!errors.baseCurrencyCode}
              helperText={errors?.baseCurrencyCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("baseCurrencyCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="currencyCode"
              label="Moeda de destino"
              error={!!errors.currencyCode}
              helperText={errors?.currencyCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("currencyCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NumericInput
              label="Taxa de conversão"
              precision={2}
              decimalChar=','
              thousandChar='.'
              variant='outlined'
              value={getValues("currencyRate")}
              {...register("currencyRate", { onChange: (e) => {
                setValue("currencyRate", e.target.value)
                handleChange(e)
              }})}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="tradeQuantity"
              label="Quantidade vendida"
              error={!!errors.tradeQuantity}
              helperText={errors?.tradeQuantity?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("tradeQuantity",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <NumericInput
                label="Avaliações do produto"
                precision={0}
                decimalChar=','
                thousandChar='.'
                variant='outlined'
                value={getValues("totalReviews")}
                {...register("totalReviews", { onChange: (e) => {
                  setValue("totalReviews", e.target.value)
                  handleChange(e)
                }})}
              />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="productDescriptionUrl"
              label="Url da descrição"
              error={!!errors.productDescriptionUrl}
              helperText={errors?.productDescriptionUrl?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2048
              }}
              {...register("productDescriptionUrl",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="property1Name"
              label="Propriedade com foto (nome)"
              error={!!errors.property1Name}
              helperText={errors?.property1Name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 200
              }}
              {...register("property1Name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="property1Titles"
              label="Propriedade com foto (títulos)"
              error={!!errors.property1Titles}
              helperText={errors?.property1Titles?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2048
              }}
              {...register("property1Titles",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="property1Photos"
              label="Propriedade com foto (fotos)"
              error={!!errors.property1Photos}
              helperText={errors?.property1Photos?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("property1Photos",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="property2Name"
              label="Propriedade com medidas (nome)"
              error={!!errors.property2Name}
              helperText={errors?.property2Name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 200
              }}
              {...register("property2Name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={126} md={6} lg={6} xl={6}>
            <TextField
              id="property2Titles"
              label="Propriedade com medidas (títulos)"
              error={!!errors.property2Titles}
              helperText={errors?.property2Titles?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2048
              }}
              {...register("property2Titles",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="property2SizeChart"
              label="Propriedade com medidas (tabela)"
              error={!!errors.property2SizeChart}
              helperText={errors?.property2SizeChart?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("property2SizeChart",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="images"
              label="Imagens"
              error={!!errors.images}
              helperText={errors?.images?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("images",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="storeId"
              label="Código do fornecedor"
              error={!!errors.storeId}
              helperText={errors?.storeId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("storeId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="storeName"
              label="Nome do fornecedor"
              error={!!errors.storeName}
              helperText={errors?.storeName?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 100
              }}
              {...register("storeName",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="countryName"
              label="País"
              error={!!errors.countryName}
              helperText={errors?.countryName?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("countryName",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="openDate"
              label="Data de abertura"
              error={!!errors.openDate}
              helperText={errors?.openDate?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 10
              }}
              {...register("openDate",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="openYears"
              label="Anos de funcionamento"
              error={!!errors.openYears}
              helperText={errors?.openYears?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2
              }}
              {...register("openYears",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="feedbackRating"
              label="Percentual de feedbacks"
              error={!!errors.feedbackRating}
              helperText={errors?.feedbackRating?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2
              }}
              {...register("feedbackRating",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="feedbackRatingCount"
              label="Quantidade de feedbacks"
              error={!!errors.feedbackRatingCount}
              helperText={errors?.feedbackRatingCount?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2
              }}
              {...register("feedbackRatingCount",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="prices"
              label="Preços"
              error={!!errors.prices}
              helperText={errors?.prices?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("prices",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="skus"
              label="SKUs"
              error={!!errors.skus}
              helperText={errors?.skus?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("skus",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="props"
              label="Características"
              error={!!errors.props}
              helperText={errors?.props?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 4096
              }}
              {...register("props",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="chatgptTitleOptions" style={{ marginTop: '10px' }}>Opçoes de Títulos</InputLabel>
                <TextareaAutosize
                  id="chatgptTitleOptions"
                  minRows={10}
                  maxRows={10}
                  value={getValues("chatgptTitleOptions")}
                  {...register("chatgptTitleOptions", {
                    onChange: (e) => {
                      setValue("chatgptTitleOptions", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="chatgptTitleOptions"
            control={control}
          />

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="chatgptDescriptionOptions" style={{ marginTop: '10px' }}>Opçoes de Descrições</InputLabel>
                <TextareaAutosize
                  id="chatgptDescriptionOptions"
                  minRows={10}
                  maxRows={10}
                  value={getValues("chatgptDescriptionOptions")}
                  {...register("chatgptDescriptionOptions", {
                    onChange: (e) => {
                      setValue("chatgptDescriptionOptions", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="chatgptDescriptionOptions"
            control={control}
          />

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="chatgptCharacteristicsOptions" style={{ marginTop: '10px' }}>Opçoes de Características</InputLabel>
                <TextareaAutosize
                  id="chatgptCharacteristicsOptions"
                  minRows={10}
                  maxRows={10}
                  value={getValues("chatgptCharacteristicsOptions")}
                  {...register("chatgptCharacteristicsOptions", {
                    onChange: (e) => {
                      setValue("chatgptCharacteristicsOptions", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="chatgptCharacteristicsOptions"
            control={control}
          />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="exportJson" style={{ marginTop: '10px' }}>JSON para Exportação</InputLabel>
                <TextareaAutosize
                  id="exportJson"
                  minRows={10}
                  maxRows={10}
                  value={getValues("exportJson")}
                  {...register("exportJson", {
                    onChange: (e) => {
                      setValue("exportJson", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="exportJson"
            control={control}
          />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="feedbacksJson" style={{ marginTop: '10px' }}>JSON com Avaliações</InputLabel>
                <TextareaAutosize
                  id="feedbacksJson"
                  minRows={10}
                  maxRows={10}
                  value={getValues("feedbacksJson")}
                  {...register("feedbacksJson", {
                    onChange: (e) => {
                      setValue("feedbacksJson", e.target.value);
                      handleChange(e);
                    },
                  })}
                  {...field}
                />
              </FormControl>
            )}
            name="feedbacksJson"
            control={control}
          />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="productSearchId"
              label="Busca de Produto"
              error={!!errors.productSearchId}
              helperText={errors?.productSearchId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 36
              }}
              {...register("productSearchId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="discarded"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Descartado"
                  />
                )}
              />
            </FormGroup>
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default FoundProductForm
