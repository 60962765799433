import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { Margin } from '@mui/icons-material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },

  container: {
    minHeight: 0,
    maxHeight: 'calc(100vh - 320px)',
  },

  papersContainer: {
    minHeight: 'calc(100vh - 85px)',
    maxHeight: 'calc(100vh - 85px)',
    width: '100%'
  },

  paperCounters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    padding: 5,
    height: 80,
    marginBottom: 15,
    width: '100%'
  },

  sequence: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 15
  },

  counterTitle: {
    marginTop: 5,
    marginBottom: 0,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },

  counterNumber: {
    fontSize: '160%',
    fontWeight: 'bold'
  },

  counterNumberError: {
    fontSize: '160%',
    fontWeight: 'bold',
    color: 'red'
  },

  paper: {
    padding: '20px',
    minHeight: 'calc(100vh - 180px)',
    maxHeight: 'calc(100vh - 180px)',
    height: 'calc(100vh - 220px)',
    width: '100%',
    marginBottom: '5px'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  formContainer: {
    display: 'flex',
    alignItems: 'start',
    margin: '12px 20px 10px 0px',
    width: '100%',
    overflow: 'overlay',
    overflowX: 'hidden'
  },

  formContainerWithError: {
    margin: '12px 20px 10px 0px',
    width: '100%',
    maxHeight: 'calc(100vh - 340px)',
    overflow: 'overlay',
    overflowX: 'hidden'
  },

  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  iconHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 1,
    minWidth: 1,
    maxWidth: 1,
  },

  tableIcon: {
  	margin: '-5px',
  	padding: '-5px',
  	lineHeight: 0,
  	fontSize: 20,
  	cursor: 'pointer',
  	color: theme.palette.primary.main,
  },

  actionButton: {
    marginLeft: '10px !important',
  },
  
  submitButton: {
    marginTop: 20,
    justifyContent: 'flex-end',
  },

  checkBox: {
    marginTop: 4,
  },

  card: {
    padding: '20px'
  },

  iconArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  searchProductsIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 5px 0px 5px',
    width: '100%'
  },

  detailHeader: {
    background: '#f0f0f0', 
    padding: '0px 5px 2px 14px', 
    top: 0, 
    fontWeight: 'bold'
  },

  detailContainer: {
    paddingLeft: '20px'
  },

  detailLine: {
    display: 'flex', 
    alignItems: 'center',
    borderBottom: '1px solid #EAEAEA',
    padding: '9px 7px 4px 23px'
  },

  detailLineImages: {
    padding: '0 5px 0 0'
  },

  productImage: {
    width: '100%', 
    height: 'auto',
    paddingRight: '7px',
  },

  gridHeader: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    padding: '7px 0 7px 0'
  },

  gridRow: {
    fontSize: '0.875rem',
    padding: '7px 0 7px 0'
  },

  gridRowRight: {
    fontSize: '0.875rem',
    padding: '7px 0 7px 0',
    textAlign: 'right'
  },

  productList: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
  },

  tableImage: {
    width: 60,
    height: 60,
    border: '1px solid #6278E7',
    borderRadius: 4,
    marginTop: 5
  },

  tableImageIcon: {
    width: '140px',
    height: '140px',
    border: '1px solid #6278E7',
    borderRadius: 4,
    marginTop: 5,
    padding: 10
  },

  tableImageIconList: {
    width: '60px',
    height: '60px',
    border: '1px solid #6278E7',
    borderRadius: 4,
    marginTop: 5,
    padding: 10
  },

  counter: {
    fontSize: '70%', 
    margin: '-15px 0 5px 5px'
  },

  emptyCounter: {
    margin: '7px 0 5px 5px'
  },
  
  linearProgressOn: {
    marginRight: '15px',
    width: '100%'
  },

  linearProgressOff: {
    visibility: 'hidden',
  },

  displayIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '50px'
  },

  appsIcon: {
    marginTop: 2,
    fontSize: '40px',
    cursor: 'pointer'
  },

  listIcon: {
    marginTop: 2,
    fontSize: '45px',
    cursor: 'pointer'
  },

  productCardsContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    overflow: 'overlay',
    overflowX: 'hidden',
    width: '100%',
    flexWrap: 'wrap',
    minHeight: 'calc(100vh - 350px) !important',
    height: 'calc(100vh - 350px) !important'
  },

  productCard: {
    padding: '10px',
    margin: '5px',
    minWidth: '156px',
    maxWidth: '156px',
    width: '156px',
    height: '260px',
    borderRadius: 4,
    border: '1px solid #EAEAEA',
    backgroundColor: '#F1F4F7',
    cursor: 'pointer'
  },

  productCardImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '140px',
  },

  tableGridImage: {
    objectFit: 'cover',
    objectPosition: 'top', 
    width: '140px',
    height: '140px',
    borderRadius: 7
  },

  tableGridImageError: {
    objectFit: 'cover',
    objectPosition: 'top', 
    width: '120px',
    height: '120px',
    borderRadius: 7
  },

  productCardInfo: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    padding: '10px 0 0 0',
    width: '100%',
    minHeight: '80px',
    height: '80px',
    lineHeight: '12px'
  },

  productCardTitle: {
    color: theme.palette.primary.main,
    overflow: 'hidden',
    width: '100%',
    fontSize: '12px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5
  },

  productCardIcons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'row',
  },

  productCardIcon: {
    fontSize: 20,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginTop: '3px',
    marginLeft: '7px'
  },

  panelIcon: {
  	padding: '-5px',
  	lineHeight: 0,
  	fontSize: 20,
  	cursor: 'pointer',
  	color: theme.palette.primary.main,
    marginTop: '-15px',
    marginRight: '0px',
    marginLeft: 'auto'
  },

  panelIconHidden: {
    padding: '-5px',
  	lineHeight: 0,
  	fontSize: 20,
  	cursor: 'pointer',
  	color: theme.palette.primary.main,
    marginTop: '-15px',
    marginRight: '0px',
    marginLeft: 'auto',
    visibility: 'hidden'
  },

  summaries: {
    width: '350px',
    color: '#fff'
  },

  summaryCounter: {
    width: '40px', 
    textAlign: 'right', 
    display: 'inline-block',
    fontWeight: 'bold'
  },
  customWidth: {
    maxWidth: '650px'
  }
}))

export { useStyles }
