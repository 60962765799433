import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem } from '@mui/material'
import { FormControl } from '@mui/material'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import NumericInput from 'material-ui-numeric-input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IShoppingCartDTO } from 'data/dtos/payment/i-shopping-cart-dto'

interface IRouteParams {
  id: string
}

const ShoppingCartForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [productsA, setProductsA] = useState([])

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    date: yup.string()
      .required('Campo obrigatório'),
    name: yup.string()
      .required('Campo obrigatório'),
    countryCode: yup.string()
      .required('Campo obrigatório'),
    productId: yup.string()
      .required('Campo obrigatório'),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IShoppingCartDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      date: '',
      name: '',
      email: '',
      checkoutPhone: '',
      countryCode: '',
      productId: '',
      paymentGatewayId: '',
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Produto

      await api
        .post('/products/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((productsResult) => {
          setProductsA(productsResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/shopping-carts/${id}`)
        .then(response => {
          const { data } = response.data

          const shoppingCartResult = {
            date: data.date,
            name: data.name,
            email: data.email,
            checkoutPhone: data.checkoutPhone,
            countryCode: data.countryCode,
            productId: data.productId.id,
            paymentGatewayId: data.paymentGatewayId,
          }

          return shoppingCartResult
        })
        .then((shoppingCartResult: IShoppingCartDTO) => {
          reset(shoppingCartResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IShoppingCartDTO) => {
    const payLoad: IShoppingCartDTO = {
      date: data.date,
      name: data.name,
      email: data.email,
      checkoutPhone: data.checkoutPhone,
      countryCode: data.countryCode,
      productId: data.productId,
      paymentGatewayId: data.paymentGatewayId,
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/shopping-carts`, payLoad)
        .then(history.push('/shopping-carts'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/shopping-carts', payLoad)
        .then(history.push('/shopping-carts/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Abandono de Carrinho"
          icon={ListIcon}
          backRoute="/shopping-carts"
          showSaveButton={true}
          helpText="Aqui são informados dados sobre os abandonos de carrinhos."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="date"
              label="Data e Hora"
              error={!!errors.date}
              helperText={errors?.date?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 20
              }}
              {...register("date",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="name"
              label="Nome do Comprador"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("name",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="email"
              label="Email do Assinante"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("email",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="checkoutPhone"
              label="Telefone"
              error={!!errors.checkoutPhone}
              helperText={errors?.checkoutPhone?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 50
              }}
              {...register("checkoutPhone",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="countryCode"
              label="Código ISO do País"
              error={!!errors.countryCode}
              helperText={errors?.countryCode?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 2
              }}
              {...register("countryCode",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="productId"
              label="Produto"
              error={!!errors.productId}
              helperText={errors?.productId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().productId}`}
              select
              {...register("productId", { onChange: (e) => {
                setValue("productId", e.target.value)
                handleChange(e)
              }})}
            >
            {productsA.map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
              >
                {product.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="paymentGatewayId"
              label="Id no Gateway de Pagamento"
              error={!!errors.paymentGatewayId}
              helperText={errors?.paymentGatewayId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 40
              }}
              {...register("paymentGatewayId",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

        </Grid>
      </Box>
    </Paper>
  )
}

export default ShoppingCartForm
