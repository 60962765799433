import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { alpha } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '20px',
    minHeight: 'calc(100vh - 120px)',
    maxHeight: 'calc(100vh - 120px)',
    height: 'calc(100vh - 120px)',
    width: '100%'
  },

  formTitle: {
    display: 'flex',
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.main
  },

  search: {
    position: 'relative',
    border: '1px solid #E0E0E0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginBottom: '15px',
    minWidth: '90%',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },

  searchFull: {
    position: 'relative',
    border: '1px solid #E0E0E0',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginBottom: '15px',
    minWidth: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },

  tableContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'row',
    height: 'calc(100vh - 310px)',
    width: '100%',
    flexWrap: 'wrap',
  },

  inputInput: {
    padding: '5px 15px 5px 15px',
    minWidth: '100%',
    width: '100%'
  },

  linearProgressOn: {
    marginRight: '15px',
    width: '100%'
  },

  linearProgressOff: {
    visibility: 'hidden',
  },

  tablePaper: {
    width: '100%',
    overflow: 'hidden'
  },

  rowTableIcon: {
    color: theme.palette.primary.main,
    minWidth: '45px',
    width: '45px',
    paddingTop: 7,
    paddingBottom: 7
  },

  tableIcon: {
    fontSize: 20,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginTop: '3px',
    marginLeft: '7px'
  },

  tableIconGreen: {
    fontSize: 20,
    cursor: 'pointer',
    color: 'green',
    marginTop: '3px',
    marginLeft: '7px'
  },

  tableIconOff: {   
    display: 'none'
  },

  tableIconRed: {
    fontSize: 20,
    cursor: 'pointer',
    color: 'red',
    marginTop: '3px',
    marginLeft: '7px'
  },

  tableImage: {
    width: 60,
    height: 60,
    border: '1px solid #6278E7',
    borderRadius: 4
  },

  tableRow: {
    borderBottom: '1px solid black !important'
  },

  pagination: {
    marginTop: 'calc(100vh - 312px)',
  },

  displayIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '10%',
    height: '50px'
  },

  appsIcon: {
    marginTop: 2,
    fontSize: '40px',
    cursor: 'pointer'
  },

  listIcon: {
    marginTop: 2,
    fontSize: '45px',
    cursor: 'pointer'
  },

  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
  },

  productCardsContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    overflow: 'overlay',
    overflowX: 'hidden',
    width: '100%',
    flexWrap: 'wrap',
    minHeight: 'calc(100vh - 350px) !important',
    height: 'calc(100vh - 350px) !important'
  },

  productCard: {
    padding: '10px',
    margin: '5px',
    minWidth: '156px',
    maxWidth: '156px',
    width: '156px',
    height: '260px',
    borderRadius: 4,
    border: '1px solid #EAEAEA',
    backgroundColor: '#F1F4F7',
    cursor: 'pointer'
  },

  productCardRed: {
    padding: '10px',
    margin: '5px',
    minWidth: 'calc(14% - 7px)',
    width: 'calc(14% - 7px)',
    height: '260px',
    borderRadius: 4,
    border: '1px solid red',
    backgroundColor: '#FEE8EA',
    cursor: 'pointer'
  },

  productCardImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tableGridImage: {
    objectFit: 'cover',
    objectPosition: 'top', 
    width: '140px',
    height: '140px',
    borderRadius: 7
  },

  productCardInfo: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    padding: '10px 0 0 0',
    width: '100%',
    height: '80px',
    lineHeight: '12px'
  },

  productCardTitle: {
    color: theme.palette.primary.main,
    overflow: 'hidden',
    width: '100%',
    fontSize: '12px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 5
  },

  tableImageIcon: {
    width: 60,
    height: 60,
    border: '1px solid #6278E7',
    borderRadius: 4,
    marginTop: 5,
    padding: 10
  },

  productCardIcons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'row',
  }
}))

export { useStyles }
