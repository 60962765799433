import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Paper, Grid, TextField, InputLabel, MenuItem, InputAdornment, IconButton, Typography } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, FormControl, TextareaAutosize, Tooltip } from '@mui/material'
import NumericInput from 'material-ui-numeric-input';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHeader, FormAlert } from 'components'
import ListIcon from '@mui/icons-material/List'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import * as yup from 'yup'
import { useStyles } from './styles'
import api from 'services/api'
import { IStoreDTO } from 'data/dtos/store/i-store-dto'

interface IRouteParams {
  id: string
}

const StoreForm: React.FC = () => {
  const [mainError, setMainError] = useState('')
  const [storeAutomationSoftwaresA, setStoreAutomationSoftwaresA] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [productPriceDecimalsHelpText, setProductPriceDecimalsHelpText] = useState('')
  const [productMarkupHelpText, setProductMarkupHelpText] = useState('')
  const [discountHelpText, setDiscountHelpText] = useState('')
  const [productChartSizeHelpText, setProductChartSizeHelpText] = useState('')
  const [productComplementHtmlHelpText, setProductComplementHtmlHelpText] = useState('')
  const [productUseAiHelpText, setProductUseAiHelpText] = useState('')

  const params = useParams<IRouteParams>()
  const firstInputElement = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const validationSchema = yup.object().shape({
    storeName: yup.string()
      .required('Campo obrigatório'),
  })

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm<IStoreDTO>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      storeName: '',
      url: '',
      storeAutomationSoftwareId: '',
      shopifyDomain: '',
      accessToken: '',
      apiKey: '',
      apiSecret: '',
      productComplementHtml: `<h2>Informações adicionais:</h2>

<ul>
<li>Garantia de qualidade</li>
<li>Primeira troca grátis em até 7 dias</li>
<li>Frete grátis para todo Brasil</li>
<li>Nossas entregas acontecem de segunda a sábado</li>
</ul>`,
      productChartSize: 'no_image',
      productPriceMarkup: 0,
      productPriceDecimals: 0,
      productTaxesPercentage: 0,
      productFreightIfAbsent: 0,
      reviewsMaximumQuantity: 0,
      reviews1Star: false,
      reviews2Stars: false,
      reviews3Stars: false,
      reviews4Stars: false,
      reviews5Stars: false,
      reviewsOnlyWithPhotos: false,
      reviewsGenerate: false,
      useAi: '',
      discountPriceLimit1: 0,
      discountPercentageLimit1: 0,
      discountPriceLimit2: 0,
      discountPercentageLimit2: 0,
      discountPriceLimit3: 0,
      discountPercentageLimit3: 0,
      discountPriceLimit4: 0,
      discountPercentageLimit4: 0,
      disabled: false,
    }
  })

  // initial load

  useEffect(() => {
    async function loadData() {

      // select Software de Automação

      await api
        .post('/store-automation-softwares/select')
        .then(response => {
          const { data } = response.data

          return data
        })
        .then((storeAutomationSoftwaresResult) => {
          setStoreAutomationSoftwaresA(storeAutomationSoftwaresResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })

        setProductMarkupHelpText('Fator de multiplicação que será aplicado sobre o custo do produto. Ex: um produto com um custo de 101,30 e um markup de 2,00 o preço final será 202,60')
        setProductPriceDecimalsHelpText('Como será exibida a parte decimal dos preços. Ex: o preço 201,30 pode ser exibido como: 201,30 ou 201,99 ou 201,97 ou 201,00')
        setDiscountHelpText('O OneManDrop extrai o preço bruto, sem descontos, pois dependendo se você está logado ou não, se você fez compras anteriores ou não, o desconto varia muito. Aqui você pode definir os descontos que serão aplicados a partir de faixas do preço bruto. Ex: preço bruto até 50,00 é aplicado um desconto de 50%. Se o preço bruto for maior que 50,00 e menor ou igual a 100,00, é aplicado um desconto de 60% e assim por diante. Pode definir até 4 faixas de preço')
        setProductChartSizeHelpText('Caso o produto no AliExpress tenha uma tabela de tamanhos e medidas, aqui você define se haverá ou não um link para uma imagem padronizada de tamanhos e medidas, com cores que se assemelhem ao estilo de seu tema na Shopify')	
        setProductComplementHtmlHelpText('Informações adicionais que serão exibidas no final do card de um produto. Esse campo deve ser preenchido com tags HTML')
        setProductUseAiHelpText('Os títulos e descrições dos produtos podem ser os textos como estão na página do AliExpress de onde foram extraídos, ou podem ser gerados com inteligência artificial: curtos e sem imagens, ou longos com imagens')
    }

    loadData()
  }, [])


  // main data load

  useEffect(() => {
    async function loadData() {
      const { id } = params

      // form data

      await api
        .get(`/stores/${id}`)
        .then(response => {
          const { data } = response.data

          const storeResult = {
            storeName: data.storeName,
            url: data.url,
            storeAutomationSoftwareId: data.storeAutomationSoftwareId.id,
            shopifyDomain: data.shopifyDomain,
            accessToken: data.accessToken,
            apiKey: data.apiKey,
            apiSecret: data.apiSecret,
            productComplementHtml: data.productComplementHtml,
            productChartSize: data.productChartSize,
            productPriceMarkup: data.productPriceMarkup,
            productPriceDecimals: data.productPriceDecimals,
            productTaxesPercentage: data.productTaxesPercentage,
            productFreightIfAbsent: data.productFreightIfAbsent,
            reviewsMaximumQuantity: data.reviewsMaximumQuantity,
            reviews1Star: data.reviews1Star,
            reviews2Stars: data.reviews2Stars,
            reviews3Stars: data.reviews3Stars,
            reviews4Stars: data.reviews4Stars,
            reviews5Stars: data.reviews5Stars,
            reviewsOnlyWithPhotos: data.reviewsOnlyWithPhotos,
            reviewsGenerate: data.reviewsGenerate,
            useAi: data.useAi,
            discountPriceLimit1: data.discountPriceLimit1,
            discountPercentageLimit1: data.discountPercentageLimit1,
            discountPriceLimit2: data.discountPriceLimit2,
            discountPercentageLimit2: data.discountPercentageLimit2,
            discountPriceLimit3: data.discountPriceLimit3,
            discountPercentageLimit3: data.discountPercentageLimit3,
            discountPriceLimit4: data.discountPriceLimit4,
            discountPercentageLimit4: data.discountPercentageLimit4,
            disabled: data.disabled,
          }

          return storeResult
        })
        .then((storeResult: IStoreDTO) => {
          reset(storeResult)
        })
        .catch(error => {
          console.log(error)
          return error
        })
    }

    if (params.id) {
      loadData()
    }
  }, [params, params.id])


  // data save

  const onSubmit = useCallback(async (data: IStoreDTO) => {
    const payLoad: IStoreDTO = {
      storeName: data.storeName,
      url: data.url,
      storeAutomationSoftwareId: data.storeAutomationSoftwareId,
      shopifyDomain: data.shopifyDomain,
      accessToken: data.accessToken,
      apiKey: data.apiKey,
      apiSecret: data.apiSecret,
      productComplementHtml: data.productComplementHtml,
      productChartSize: data.productChartSize,
      productPriceMarkup: data.productPriceMarkup,
      productPriceDecimals: data.productPriceDecimals,
      productTaxesPercentage: data.productTaxesPercentage,
      productFreightIfAbsent: data.productFreightIfAbsent,
      reviewsMaximumQuantity: data.reviewsMaximumQuantity,
      reviews1Star: data.reviews1Star,
      reviews2Stars: data.reviews2Stars,
      reviews3Stars: data.reviews3Stars,
      reviews4Stars: data.reviews4Stars,
      reviews5Stars: data.reviews5Stars,
      reviewsOnlyWithPhotos: data.reviewsOnlyWithPhotos,
      reviewsGenerate: data.reviewsGenerate,
      useAi: data.useAi,
      discountPriceLimit1: data.discountPriceLimit1,
      discountPercentageLimit1: data.discountPercentageLimit1,
      discountPriceLimit2: data.discountPriceLimit2,
      discountPercentageLimit2: data.discountPercentageLimit2,
      discountPriceLimit3: data.discountPriceLimit3,
      discountPercentageLimit3: data.discountPercentageLimit3,
      discountPriceLimit4: data.discountPriceLimit4,
      discountPercentageLimit4: data.discountPercentageLimit4,
      disabled: data.disabled
    }

    if (params.id) {
      const { id } = params

      payLoad.id = id

      await api
        .put(`/stores`, payLoad)
        .then(history.push('/stores'))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    } else {
      await api
        .post('/stores', payLoad)
        .then(history.push('/stores/new'))
        .then(() => reset())
        .then(() => setTimeout(() => { firstInputElement.current.focus() }, 0))
        .catch(error => {
          console.log(error.response.data)
          setMainError(error.response.data.data.name)
          return error.response.data.data
        })
    }
  }, [])


  const handleChange = (formField: any) => {
    setMainError('')
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="form"
      >
        <FormHeader
          title="Lojas"
          icon={ListIcon}
          backRoute="/stores"
          showSaveButton={true}
          showSecondaryButton={true}
          secondaryButtonLabel="Guia de Integração Shopify"
          secondaryLink="https://docs.onemandrop.com/pt/shopify-guia-instalacao-onemandrop.html"
          helpText="Aqui são informados os dados das lojas gerenciadas."
        />

        <FormAlert setMainError={setMainError} mainError={mainError} />

        <Grid container spacing={1} className={mainError === '' ? classes.formContainer : classes.formContainerWithError}>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="storeName"
              label="Nome"
              error={!!errors.storeName}
              helperText={errors?.storeName?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={firstInputElement}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 130
              }}
              {...register("storeName",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="url"
              label="Url"
              error={!!errors.url}
              helperText={errors?.url?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 130
              }}
              {...register("url",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="storeAutomationSoftwareId"
              label="Software de Automação"
              error={!!errors.storeAutomationSoftwareId}
              helperText={errors?.storeAutomationSoftwareId?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().storeAutomationSoftwareId}`}
              select
              {...register("storeAutomationSoftwareId", { onChange: (e) => {
                setValue("storeAutomationSoftwareId", e.target.value)
                handleChange(e)
              }})}
            >
            {storeAutomationSoftwaresA.map((storeAutomationSoftware) => (
              <MenuItem
                key={storeAutomationSoftware.id}
                value={storeAutomationSoftware.id}
              >
                {storeAutomationSoftware.name}
              </MenuItem>
            ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <TextField
              id="shopifyDomain"
              label="Domínio Shopify"
              error={!!errors.shopifyDomain}
              helperText={errors?.shopifyDomain?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 200
              }}
              {...register("shopifyDomain",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              id="accessToken"
              label="Token de acesso da API Admin"
              error={!!errors.accessToken}
              helperText={errors?.accessToken?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              type={showPassword ? "text" : "password"}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ 
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...register("accessToken",
                { onChange: (e) => handleChange(e) }
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label={
                    <Box className={classes.fieldLabel} >
                      <Typography>Markup</Typography>
                      <Tooltip title={productMarkupHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  }
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("productPriceMarkup")}
                  {...register("productPriceMarkup", { onChange: (e) => {
                    setValue("productPriceMarkup", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="productPriceMarkup"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="productPriceDecimals"
              label={
                <Box className={classes.fieldLabel} >
                  <Typography>Decimais no Preço</Typography>
                  <Tooltip title={productPriceDecimalsHelpText} placement="right-end" arrow={true} >
                    <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                  </Tooltip>
                </Box>
              }
              error={!!errors.productPriceDecimals}
              helperText={errors?.productPriceDecimals?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().productPriceDecimals}`}
              select
              {...register("productPriceDecimals", { onChange: (e) => {
                setValue("productPriceDecimals", e.target.value)
                handleChange(e)
              }})}
            >
              <MenuItem key="1" value="0.00" selected>Valores finalizados com .00</MenuItem>
              <MenuItem key="2" value="0.99">Valores finalizados com .99</MenuItem>
              <MenuItem key="3" value="0.97">Valores finalizados com .97</MenuItem>   
              <MenuItem key="4" value="null">Valores sem ajustes</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label={
                    <Box className={classes.fieldLabel} >
                      <Typography>Preços AliExpress até</Typography>
                      <Tooltip title={discountHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  }
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPriceLimit1")}
                  {...register("discountPriceLimit1", { onChange: (e) => {
                    setValue("discountPriceLimit1", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPriceLimit1"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label="% Desconto"
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPercentageLimit1")}
                  {...register("discountPercentageLimit1", { onChange: (e) => {
                    setValue("discountPercentageLimit1", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPercentageLimit1"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label={
                    <Box className={classes.fieldLabel} >
                      <Typography>Preços AliExpress até</Typography>
                      <Tooltip title={discountHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  }
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPriceLimit2")}
                  {...register("discountPriceLimit2", { onChange: (e) => {
                    setValue("discountPriceLimit2", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPriceLimit2"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label="% Desconto"
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPercentageLimit2")}
                  {...register("discountPercentageLimit2", { onChange: (e) => {
                    setValue("discountPercentageLimit2", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPercentageLimit2"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label={
                    <Box className={classes.fieldLabel} >
                      <Typography>Preços AliExpress até</Typography>
                      <Tooltip title={discountHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  }
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPriceLimit3")}
                  {...register("discountPriceLimit3", { onChange: (e) => {
                    setValue("discountPriceLimit3", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPriceLimit3"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label="% Desconto"
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPercentageLimit3")}
                  {...register("discountPercentageLimit3", { onChange: (e) => {
                    setValue("discountPercentageLimit3", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPercentageLimit3"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Controller
              render={({ field }) => (
                <NumericInput
                  label={
                    <Box className={classes.fieldLabel} >
                      <Typography>% Desconto Acima</Typography>
                      <Tooltip title={discountHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  }
                  precision={2}
                  decimalChar=','
                  thousandChar='.'
                  variant='outlined'
                  value={getValues("discountPercentageLimit4")}
                  {...register("discountPercentageLimit4", { onChange: (e) => {
                    setValue("discountPercentageLimit4", e.target.value)
                    handleChange(e)
                  }})}
                />
              )}
              name="discountPercentageLimit4"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="productChartSize"
              label={
                <Box className={classes.fieldLabel} >
                  <Typography>Imagem com referências de medidas</Typography>
                  <Tooltip title={productChartSizeHelpText} placement="right-end" arrow={true} >
                    <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                  </Tooltip>
                </Box>
              }
              error={!!errors.productChartSize}
              helperText={errors?.productChartSize?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().productChartSize}`}
              select
              {...register("productChartSize", { onChange: (e) => {
                setValue("productChartSize", e.target.value)
                handleChange(e)
              }})}
            >
              <MenuItem key="0" value="no_image" selected>Sem imagem</MenuItem>
              <MenuItem key="1" value="from_flash_drop_yellow">Imagem com tons de Amarelo</MenuItem>
              <MenuItem key="2" value="from_flash_drop_green">Imagem com tons de Verde</MenuItem>   
              <MenuItem key="3" value="from_flash_drop_blue">Imagem com tons de Azul</MenuItem>
              <MenuItem key="4" value="from_flash_drop_grey">Imagem com tons de Cinza</MenuItem>
              <MenuItem key="5" value="from_flash_drop_red">Imagem com tons de Vermelho</MenuItem>     
              <MenuItem key="6" value="from_flash_drop_lilac">Imagem com tons de Lilaz</MenuItem>    
              <MenuItem key="7" value="from_flash_drop_purple">Imagem com tons de Roxo</MenuItem>    
              <MenuItem key="8" value="from_store">Imagem fornecida pela loja de origem</MenuItem> 

            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="productComplementHtml" style={{ marginTop: '10px' }}>
                    <Box className={classes.fieldLabel} >
                      <Typography>Complemento da descrição do produto</Typography>
                      <Tooltip title={productComplementHtmlHelpText} placement="right-end" arrow={true} >
                        <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                      </Tooltip>
                    </Box>
                  </InputLabel>
                  <TextareaAutosize
                    id="productComplementHtml"
                    minRows={10}
                    maxRows={10}
                    value={getValues("productComplementHtml")}
                    {...register("productComplementHtml", {
                      onChange: (e) => {
                        setValue("productComplementHtml", e.target.value);
                        handleChange(e);
                      },
                    })}
                    {...field}
                  />
                </FormControl>
              )}
              name="productComplementHtml"
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="useAi"
              label={
                <Box className={classes.fieldLabel}>
                  <Typography>Estilo dos textos</Typography>
                  <Tooltip title={productUseAiHelpText} placement="right-end" arrow={true} >
                    <HelpOutlineIcon className={classes.fieldTitleHelpIcon} />
                  </Tooltip>
                </Box>
              }
              error={!!errors.useAi}
              helperText={errors?.useAi?.message}
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              value={`${watch().useAi}`}
              select
              {...register("useAi", { onChange: (e) => {
                setValue("useAi", e.target.value)
                handleChange(e)
              }})}
            >
              <MenuItem key="original" value="original" selected>Texto original da página do produto no AliExpress</MenuItem>
              <MenuItem key="ai_short" value="ai_short">Texto curto sem imagens gerado por IA</MenuItem>
              <MenuItem key="ai_long" value="ai_long">Texto longo com imagens gerado por IA</MenuItem>   
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormGroup className={classes.checkBox}>
              <Controller
                name="disabled"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                      />
                    }
                    label="Inativo"
                  />
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default StoreForm
