import { makeStyles } from '@mui/styles'

import backgroundImage from 'assets/login-background.png'
import background from 'assets/background.png'

const useStyles = makeStyles(() => ({
	ul: {
		marginBlockStart: '0 !important',
		marginBlockEnd: '0 !important',
		margin: '0 !important'
	},

  Media: {
    height: '100%',
    width: '100%'
  },

	container: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
		backgroundImage: `url(${background})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},

	mainWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
		minHeight: 'calc(100vh - 50px)'
	},

	paperWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'white',
		width: '960px',
		padding: '30px',
		color: '#333333',
		borderRadius: '4px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
		marginTop: '80px',

		'& h1': {
			marginBottom: '0px',
			fontSize: '28px'
		},

		'& p': {
			marginTop: '0px',
			marginBottom: '10px'
		},

		'& ul': {
			marginTop: '5px',
			marginBottom: '10px'
		},
	},

	logoImage: {
		width: '140px'
	},

	formBox: {
		width: '290px'
	},

	formTitle: {
		lineHeight: 1,
		marginTop: 0
	},

	linearProgress: {
		marginTop: '20px'
	},

	alert: {
		marginTop: '20px', 
		padding: '1px 16px 1px 16px'
	},

	footer: {
  	marginTop: 'auto',
  	backgroundColor: '#E7EAED',
  	padding: '10px 10px 15px 10px'
	},

	signupLink: {
		textAlign: 'right'
	},

	header: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		top: 0,
		left: 0,
		backgroundColor: '#6278E7',
		width: '100%',
		height: '80px'
	},

	headerPaperWrapper: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
		alignItems: 'start',
		width: '960px',
		padding: '10px',
		color: 'white'
	},

	cardsWrapper: {
		display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
		alignItems: 'center',
		gap: '20px',
	},

	card: {
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
		alignItems: 'center',
		border: '1px solid #F7F7F8',
		width: '280px',
		height: '420px',
		shadow: '2px 2px 4px 0px #6278E7',
		padding: '10px',
		borderRadius: '4px',
    boxShadow: '0px 1px 1px -2px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
	},

	slogan: {
		display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
		alignItems: 'start',
		color: '#1F1F1F',
		fontWeight: 'bold',
		fontSize: '28px',
		width: '100%',
		textAlign: 'center',
		height: '80px',
		
		'& p': {
			lineHeight: '28px',
		}
	},

	cardTitle: {
		color: '#1F1F1F',
		fontWeight: 'bold',
		fontSize: '24px',
	},

	quantity: {
		fontFamily: '"Montserrat", sans-serif',
		fontOpticalSizing: 'auto',
		fontWeight: 800,
		fontStyle: 'normal',
		color: '#2385E2',
		fontSize: '18px',
	}, 

	quantityWrapper: {
		display: 'flex',
    flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'start',
	},

	shopifyLogo: {
		width: '18px',
		marginRight: '5px',
		marginTop: '1px',
		filter: 'grayscale(1)'
	},

	sloganWrapper: {
		display: 'flex',
    flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
				
		'& p': {
			lineHeight: '20px',
		}
	},

	targetPublic: {
		display: 'flex',
    flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		textAlign: 'center',
		marginTop: '5px',
		marginBottom: '-5px',
				
		'& p': {
			lineHeight: '12px',
		}
	},

	priceWrapper: {
		display: 'flex',
    flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '0px',
	},

	price: {
		fontFamily: '"Montserrat", sans-serif',
		fontOpticalSizing: 'auto',
		fontWeight: 700,
		fontStyle: 'normal',
		color: '#2385E2',
		fontSize: '40px',
	}, 

	priceComplements: {
		fontSize: '12px',
		marginLeft: '0px',
		marginRight: '3px',
		marginTop: '9px',
	},

	featuresWrapper: {
		marginTop: '10px',
		paddingTop: '5px',
		width: '240px',
		borderTop: '1px solid #1F1F1F4D',
				
		'& ul': {
			fontSize: '12px',
			listStyle: 'none',
			margin: 0,
			padding: 0,
			textIndent: '-24px',
			marginLeft: '24px'
		},

		'& ul li': {
			margin: 0,
			padding: 0,
			lineHeight: '13px',
		},
	},

	checkIcon: {
		paddingTop: '7px',
		color: '#30C742'
	},

	renewal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		textAlign: 'center',
		marginTop: '-10px',
		marginBottom: '0px',
			
			'& p': {
				lineHeight: '12px',
			}
		}
	}
))

export { useStyles }
