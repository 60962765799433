import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const drawerWidth = 260
const contentLeftOpen = drawerWidth
const contentLeftClose = 55

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
    '*:hover::-webkit-scrollbar': {
      width: '0.6em'
    },
  },

  mainContentWrapper: {
    display: 'flex',
    grow: 1,
    position: 'fixed',
    top: '50px',
    maxHeight: 'calc(100vh - 50px)',
    overflow: 'overlay',
    padding: '20px 20px 0px 20px'
  },

  mainContentWrapperOpen: {
    left: contentLeftOpen,
    width: `calc(100% - ${contentLeftOpen}px)`,
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    paddingBottom: '5px'
  },

  mainContentWrapperClose: {
    left: contentLeftClose,
    width: `calc(100% - ${contentLeftClose}px)`,
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    paddingBottom: '5px'
  },
  
  helpPanel: {
    width: '900px',
    height: 'calc(100vh - 370px) !important',
    position: 'fixed',
    top: '325px',
    right: '-950px',
    backgroundColor: '#F1F4F7',
    transition: 'right 0.3s ease',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    padding: '5px 10px 0 10px',
    index: 1000
  },
  
  helpPanelOpen: {
    width: '900px',
    height: 'calc(100vh - 370px) !important',
    position: 'fixed',
    top: '325px',
    right: '0px',
    backgroundColor: '#F1F4F7',
    transition: 'right 0.3s ease',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    padding: '5px 10px 0 10px',
    index: 1000
  },
  
  helpPanelOpenButton: {
    position: 'absolute',
    top: '10px',
    right: '-50px'
  },
  
  helpContainer: {
    backgroundColor: '#fff',
    height: 'calc(100% - 30px) !important',
    padding: '10px 10px 40px 10px',
    overflowY: 'auto',
    color: '#000',

    '& h5': {
      color: '#000'
    },

    '& img': {
      border: '1px solid #EAEAEA',
      cursor: 'pointer',
      maxWidth: '850px'
    }
  },

  helpContainerTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    margin: '0px 0 5px 5px',
  },

  helpTitle: {
    fontWeight: 'bold'
  },

  helpClose: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export { useStyles }
